import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../../lib/srs";
import Order_Grp from "./grp";
import sbo from "../../shop/sbo";
import * as utils from "../../assets/utils";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Order_Kitchen extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.zip = "";
    this.state.adress = "";
    this.state.loco = {};
    this.state.wnds = {};

    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mgo = {};

    //this.state.lat = "57.5079875";
    //this.state.lon = "11.9407502";
    this.state.lat = "57.506280";
    this.state.lon = "12.059960";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dsi_ecom();
  }

  bg_full_style() {
    var css = {};
    css.margin = "0px";
    css.padding = "0px";
    css.height = window.innerHeight - 85;
    css.overflow = "auto";
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    return css;
  }
  mg_cls(btn) {
    var cls = "flex col";
    //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
    //else cls = cls + " btn_free";
    return cls;
  }
  mg_style(btn) {
    var css = {};
    css.margin = "5px 12px";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  imgUrl(fil) {
    var url = "";
    url = "https://story.svepos.se/foodle/";
    url = url + fil;
    return url;
  }

  mg_open(mgo) {
    sbo.cdo.gid = mgo.gid;
    //var url = "";
    //url = url + "/shop/menu/grp";
    //this.props.navigate(url);
    var wnds = {};
    wnds.grpopen = true;
    wnds.gid = mgo.gid;
    wnds.mgo = mgo;
    this.setState({ wnds: wnds });
  };

  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_chan";

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var mga = ret.rca;
        self.setState({ mga: mga });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }

  render() {

    return (
        <div className="web-app">

          <div className="flex-row px-3">
            <div className="txt_22">BELOPP</div>
            <div className="flex"></div>
            <div className="txt_b22">{ utils.toPris(sbo.cart.belopp) }</div>
          </div>
          <div className="divider_orange"></div>

          <section className="flex-col" style={ this.bg_full_style() }>

            <div className="mgv">
              <div className="mgv-grid">
                { this.state.mga.map((mgo, key) =>
                    <div className={ this.mg_cls(mgo) } key={key}
                         onClick={() => this.mg_open(mgo) }
                         style={ this.mg_style(mgo) }
                    >
                      <div className="flex-col">
                        <img src={this.imgUrl(mgo.img)} className={mgo.class} alt="Foodle" />
                        <img src={this.imgUrl(mgo.txtimg)} className="foodle-txt px-5" alt="Foodle" />
                      </div>
                      <div className="flex-row px-5 py-3" >
                        <div>{ mgo.desc }</div>
                      </div>
                      <div className="flex-row px-5 py-3" >
                        <div className="flex"></div>
                        <div className="mg-btn" onClick={() => this.mg_open(mgo) }>TILL KÖK</div>
                        <div className="flex"></div>
                      </div>
                    </div>
                )}
              </div>
            </div>

          </section>

          { this.state.wnds.grpopen ? <Order_Grp wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        </div>
  );
}
}

export default observer(Order_Kitchen);
