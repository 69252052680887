import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {withRouter} from "../../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../../lib/srs";
import sbo from "../../shop/sbo";
import * as utils from "../../assets/utils";
import Order_Betala from "./betala";
import Order_Kvitto from "./kvitto";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Order_Kassa extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.zip = "";
    this.state.adress = "";
    this.state.loco = {};

    this.state.wnds = {};
    this.state.msg = {};

    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mgo = {};

    if(!sbo.cart.belopp) {
      sbo.cart.belopp = 0;
    }
    if(!sbo.cart.rows) {
      sbo.cart.rows = [];
    }

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  bg_full_style() {
    var css = {};
    css.margin = "0px";
    css.padding = "0px";
    css.height = window.innerHeight - 155;
    css.overflow = "auto";
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    //css.background = "#f43b24";
    //css.color = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }
  pro_ixa(row) {
    var a = [];
    if(row.ixo) a = Object.values(row.ixo);
    return a;
  }
  pro_txa(row) {
    var a = [];
    if(row.txo) a = Object.values(row.txo);
    return a;
  }
  pro_sxa(row) {
    var a = [];
    if(row.sxo) a = Object.values(row.sxo);
    return a;
  }
  pro_dxa(row) {
    var a = [];
    if(row.dxo) a = Object.values(row.dxo);
    return a;
  }
  not_min() {
    var brc = false;
    var minbelopp = 1000;
    var belopp = sbo.cart.belopp * 1;
    if(belopp < minbelopp) {
      brc = true;
    }
    return brc;
  }
  row_del(row) {
    sbo.cart.belopp = 0;
    if(!sbo.cart.rows) sbo.cart.rows = [];

    //sbo.cart.rows = sbo.cart.rows.splice(key - 1, 1);
    var belopp = 0;
    var rows = [];
    for(var crow of sbo.cart.rows) {
      if(crow.plu != row.plu) {
        belopp = belopp + (crow.belopp * 1);
        rows.push(crow);
      }
    }
    sbo.cart.rows = rows;
    sbo.cart.belopp = belopp.toString();
    sessionStorage.setItem("sbo", JSON.stringify(sbo));
  }
  cart_reset() {
    var self = this;

    sbo.cart.belopp = "0";
    sbo.cart.rows = [];
    sessionStorage.setItem("sbo", JSON.stringify(sbo));
  }
  cart_pay() {
    var wnds = {};
    wnds.betala = true;
    wnds.cb = this.pay_cb;
    this.setState({ wnds: wnds });
  };
  pay_cb(ctx, rsp) {
    if(rsp.ok == "000") {
      ctx.setState({ wnds: {} });
      ctx.kvitto();
      //sbo.cart.belopp = "0";
      //sbo.cart.rows = [];
      //sessionStorage.setItem("sbo", JSON.stringify(sbo));
      //var url = "/mobs";
      //ctx.props.navigate(url);
      return;
    }
    ctx.setState({ wnds: {} });
  }
  kvitto() {
    var wnds = {};
    wnds.kvitto = true;
    wnds.cb = this.kvitto_cb;
    this.setState({ wnds: wnds });
  };
  kvitto_cb(ctx, rsp) {
    if(rsp.ok == "000") {
      ctx.setState({ wnds: {} });
      //sbo.cart.belopp = "0";
      //sbo.cart.rows = [];
      //sessionStorage.setItem("sbo", JSON.stringify(sbo));
      var url = "/mobs";
      ctx.props.navigate(url);
      return;
    }
    ctx.setState({ wnds: {} });
  }

  render() {

    return (
        <div className="web-app">

          <section className="wnd-fill flex-col" style={ this.bg_full_style() }>

            <div className="flex-col px-3 py-3">
              <div className="flex-row">
                <div className="txt_b18">Varukorg</div>
                <div className="flex"></div>
                <div className="txt_14">Antal varor : { sbo.cart.rows.length }</div>
              </div>
            </div>
            <div className="divider_orange"></div>

              <div className="mpg hide">
                <div className="flex-row">
                  <div className="txt_b16">Leverans uppgifter</div>
                </div>
                <div className="flex-col py-3 box-lev">
                  <div className="flex-row">
                    <div className="txt_14">{ sbo.cdo.street }</div>
                    <div className="flex"></div>
                    <div className="txt_14">Ändra</div>
                  </div>
                  <div className="txt_14">Stad</div>
                </div>
              </div>

              <div className="mpg">
                <div className="flex-row">
                  <div className="txt_b16">Produkter</div>
                </div>
                <div className="flex-col px-2">
                  { sbo.cart.rows.map((crow, ckey) =>
                      <div key={ckey} className="flex-col py-3 cart-item" >
                        <div className="flex-row" >
                          <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                          <div className="txt_16">{ utils.toLang(crow.text) }</div>
                          <div className="flex"></div>
                          <div className="txt_16">{ utils.toPris(crow.price) } kr</div>
                        </div>
                        { this.pro_ixa(crow).map((row, key) =>
                            <div className="flex-row" >
                              <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                              <div className="flex"></div>
                              <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                              <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                            </div>
                        )}
                        { this.pro_txa(crow).map((row, key) =>
                            <div className="flex-row" >
                              <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                              <div className="flex"></div>
                              <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                              <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                            </div>
                        )}
                        { this.pro_sxa(crow).map((row, key) =>
                            <div className="flex-row" >
                              <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                              <div className="flex"></div>
                              <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                              <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                            </div>
                        )}
                        { this.pro_dxa(crow).map((row, key) =>
                            <div className="flex-row" >
                              <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                              <div className="flex"></div>
                              <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                              <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                            </div>
                        )}
                        <div className="flex-row" >
                          <div className="txt_16">Summa</div>
                          <div className="flex"></div>
                          <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                        </div>
                        <div className="flex-row" >
                          <div className="flex"></div>
                          <div className="txt_16 px-1" onClick={() => this.row_del(crow) }>Ta bort</div>
                          <FontAwesomeIcon icon="trash-can" size="1x" onClick={() => this.row_del(crow) } />
                        </div>
                      </div>
                  )}
                </div>
              </div>

          </section>

          <div className="flex-col px-3 py-3">
            <div className="flex-row">
              <div className="txt_b18">Summa</div>
              <div className="flex"></div>
              <div className="txt_b18">{ utils.toPris( sbo.cart.belopp ) } kr</div>
            </div>
            {this.not_min() ?
                <div className="flex-row">
                  <div className="mfull-btn" onClick={() => this.wnd_close()}>MINI BELOPP EJ UPPNÅTT</div>
                </div>
                :
                <div className="flex-row">
                  <div className="mfull-btn" onClick={() => this.cart_reset()}>RENSA</div>
                  <div className="mfull-btn" onClick={() => this.cart_pay()}>BETALA</div>
                </div>
            }
          </div>

          { this.state.wnds.betala ? <Order_Betala wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
          { this.state.wnds.kvitto ? <Order_Kvitto wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        </div>
  );
}
}

export default observer(withRouter(Order_Kassa));
