import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import './css/kds.css';

import Tiden from "../lib/ui/tiden";

import Kds_Home from "./home";
import Kds_Pickup from "./pickup";

class Kds_Menu extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

  render() {

    return (
        <Routes>
            <Route path="pickup" element={ <Kds_Pickup store={this.store} />} />
            <Route path="*" element={ <Kds_Home store={this.store} />} />
        </Routes>
  );
}
}
export default observer(Kds_Menu);
