import React, { useState, useEffect, useContext } from 'react';

import * as srs from "../../lib/srs";
import Fr_Input from "../../lib/ui/fr_input";
import Fr_Select from "../../lib/ui/fr_select";
import * as utils from "../../assets/utils";

import sbo from '../sbo.js'

import FoodleCheck from "../img/checkmark.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Foodle_Pay extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];

    this.state.wnds = {};
    this.state.msg = {};
    this.state.payok = 0;

    this.state.pro = {};
    this.state.pbo = {};
    if(props.wnds.pbo) {
        //this.state.pbo = props.wnds.pbo;
    }
      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }

    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];
    this.state.debcre = [
      {id: '0', text: 'KREDIT'},
      {id: '1', text: 'DEBET'}
    ];
    this.state.tia = [
      {id: '0', text: 'Extra tomatsås', price: '1000'},
      {id: '1', text: 'Extra Majo', price: '1000'}
    ];
    this.state.tba = [
      {id: '0', text: 'Dippsås Vitlök', price: '1000'},
      {id: '1', text: 'Dippsås Majo', price: '1000'}
    ];
    this.state.soa = [
      {id: '0', text: 'Lökringar', price: '1900'},
      {id: '1', text: 'Pommes', price: '2500'}
    ];
    this.state.dra = [
      {id: '0', text: 'MER Appelsin', price: '1500'},
      {id: '1', text: 'Coca Cola', price: '2000'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
    //if(!this.props.wnds.pbo) this.pbo_init();
  }

    pbo_init() {
        var pbo = {};
        pbo.text = "";
        pbo.price = "";
        //alert(JSON.stringify(rso));
        this.setState({ pbo: pbo });
        return;
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    content_style() {
        var css = {};
        if(this.state.payok) css.background = "#080";
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    body_style() {
        var css = {};
        if(this.state.payok) css.display = "none";
        return css;
    }
    info_style() {
        var css = {};
        if(!this.state.payok) css.display = "none";
        return css;
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  val_save(id, val) {
      var eko = this.state.eko;
      eko[id] = val;
        this.setState({ eko: eko });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    cart_reset() {
        var self = this;

        sbo.cart.belopp = "0";
        sbo.cart.rows = [];
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    cart_paid() {
        var self = this;

        sbo.cart.belopp = "0";
        sbo.cart.rows = [];
        sessionStorage.setItem("sbo", JSON.stringify(sbo));

        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    }
    cart_pay() {
        var self = this;

        this.cart_sell();
    }

    cart_to_srv(sid) {
        var self = this;

        var rows = sbo.cart.rows;
        var belopp = sbo.cart.belopp;
        if(!rows || (rows.length < 1)) {
            alert("INGET ATT SÄLJA");
            return;
        }

        if(true) {
            self.tst_pay();
            return;
        }

        var prm = {};
        prm.sid = sid;
        prm.req = "vcm.ps.ps_web_sell";
        prm.avd = "01";
        prm.tablenr = "9001";
        prm.cashier = "00";
        prm.betkod = "card";
        prm.bettxt = "CARD PAYMENT";
        prm.belopp = belopp;
        prm.cart = sbo.cart;
        prm.rows = rows;

        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    self.setState({ payok: 1 });
                    setTimeout(function() {
                        sbo.cart.belopp = "0";
                        sbo.cart.rows = [];
                        if(self.props.wnds.cb) {
                            var rsp = {};
                            rsp.ok = "000";
                            self.props.wnds.cb(self.props.ctx, rsp);
                        }
                        else self.props.ctx.setState({ wnds: {} });
                    }, 2000);
                }
                else {
                    self.props.ctx.setState({ wnds: {} });
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    tst_pay() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = sbo.cdo.unit.sid;
        prm.req = "vcm.ps.ps_ecom_sell";
        prm.sbo = sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
                self.cart_paid();
            }
            //self.setState({ mpa: ret.rca });
            //self.syncTb("mnaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    imgUrl(fil) {
        var url = "";
        url = "https://story.svepos.se/foodle/";
        url = url + fil;
        return url;
    }
    pay_cc() {
        var self = this;
        self.cart_to_srv(sbo.cdo.unit.sid);
    }
    pay_swish() {
        var self = this;
        self.cart_to_srv(sbo.cdo.unit.sid);
    }
    pay_vip() {
        var self = this;
        self.cart_to_srv(sbo.cdo.unit.sid);
    }
    pay_pres() {
        var self = this;
        self.cart_to_srv(sbo.cdo.unit.sid);
    }
    pay_abort() {
        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "999";
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex" style={ this.content_style() }>
            <div className="mx-close-btn" onClick={() => this.wnd_close() }>X</div>

            <div className="flex-cl py-3">
            <div className="flex-row px-5">
                <div className="txt_b18">BETALNING</div>
                <div className="flex"></div>
            </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row px-5 py-3">
                <div className="txt_b18">ATT BETALA</div>
                <div className="flex"></div>
                <div className="txt_b18">{ utils.toPris( sbo.cart.belopp ) } kr</div>
            </div>

            <div className="mx-body px-5 flex-center" style={ this.info_style() }>
                <div className="txt_22 text-white">BETALNING KLAR</div>
            </div>

            <div className="mx-body flex px-5" style={ this.body_style() }>

                <div className="mpg">
                    <div className="flex-row">
                        <div className="txt_b16">Leverans uppgifter</div>
                    </div>
                    <div className="box-lev flex">
                        <div className="flex-row">
                            <div className="txt_14">{ sbo.cdo.adress }</div>
                            <div className="flex"></div>
                            <div className="txt_14" onClick={() => this.change_adress() }>Ändra</div>
                        </div>
                        <div className="flex-row">
                            <div className="txt_14">{ sbo.cdo.zip } { sbo.cdo.city }</div>
                            <div className="flex"></div>
                            <div className="txt_14"></div>
                        </div>
                    </div>
                </div>

                <div className="mpg box-adyen flex py-5" onClick={() => this.pay_cc() }>
                    <div className="flex-col flex-center">
                        <div className="txt_b16">ADYEN BETAL KOPPLING</div>
                        <div className="txt_b16">TRYCK FÖR ATT BETALA</div>
                    </div>
                </div>

            <div className="flex-col px-5 py-5 hide" >
                <div className="flex-row">
                    <div className="txt_b16">Test betalningar</div>
                </div>
                <div className="flex-row">
                <div className="btn btn-hw-pay" onClick={() => this.pay_cc() }>KORT</div>
                <div className="btn btn-hw-pay" onClick={() => this.pay_swish() }>SWISH</div>
                </div>
                <div className="flex-row">
                <div className="btn btn-hw-pay" onClick={() => this.pay_vip() }>LOJALITET</div>
                <div className="btn btn-hw-pay" onClick={() => this.pay_pres() }>PRESENTKORT</div>
            </div>
            </div>

            </div>
        </div>
        <div className="mx_foot flex-col px-5 py-2">
            <div className="flex-row">
                <div className="mfull-btn" onClick={() => this.pay_abort() }>AVBRYT</div>
            </div>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Foodle_Pay;