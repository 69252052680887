import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../lib/srs";
import sbo from './sbo.js'
import * as utils from "../assets/utils";

import {withRouter} from "../lib/react/withRouter";
import FoodleLogo from "../img/foodle-logo.svg";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Order extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.loco = {};
      this.state.zip = "";
      this.state.adress = "";
      this.state.discount = "";
      this.state.wnds = {};

      this.state.info = "";
      this.state.error = "";
      this.state.errormsg = "";

      this.state.fnamn = "";
      this.state.enamn = "";
      this.state.tele = "";
      this.state.email = "";
      this.state.msg = "";

      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }
      if(!sbo.cart.pro) {
          sbo.cart.pro = {};
      }

      if(sbo.cdo) {
          this.state.fnamn = sbo.cdo.fnamn;
          this.state.enamn = sbo.cdo.enamn;
          this.state.tele = sbo.cdo.tele;
          this.state.email = sbo.cdo.email;
          this.state.msg = sbo.cdo.msg;
      }
      //if(!sbo.cdo) sbo.cdo = {};
      this.state.sbo = {};
      if(sbo.cdo) this.state.sbo.cdo = sbo.cdo;
      if(sbo.cart) this.state.sbo.cart = sbo.cart;
      if(false) {
          sbo.cdo = {};
          sbo.cart = {};
          sbo.cart.belopp = "0";
          sbo.cart.rows = [];
          sbo.cart.pro = {};
          sessionStorage.setItem("sbo", JSON.stringify(sbo));
      }

      var qs = window.location.search;
      qs = qs.replace("?", "");
      var qa = qs.split("&");
      this.state.qso = {};
      for(var item of qa) {
          var va = item.split("=");
          this.state.qso[va[0]] = va[1];
      }
      var okey = this.state.qso.okey;
      //okey = okey.replace(/\s/g, '');
      var orderkey = atob(okey);
      var oa = orderkey.split(";");
      this.state.okey = okey;
      this.state.sid = oa[0];
      this.state.orderid = oa[1];

      if(this.state.qso.sid) this.state.sid = this.state.qso.sid;
      if(this.state.qso.orderid) this.state.orderid = this.state.qso.orderid;

      this.state.order = {};
      this.state.order.order00 = {};
      this.state.order.order01 = {};
      this.state.order.order02 = {};
      this.state.order.order03 = {};

      //alert(JSON.stringify(this.state.sid));
      //alert(JSON.stringify(this.state.orderid));
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.ecom_result();
  }

    ecom_result() {
        var self = this;

        //var sid = this.state.sbo.cdo.unit.sid;
        //var orderid = this.state.sbo.cart.orderid;
        var okey = this.state.okey;
        var sid = this.state.sid;
        var orderid = this.state.orderid;
        //alert(JSON.stringify(this.state.qso));

        var txt = "ORDER KONTROLLERAS...";
        if(orderid && orderid.length) txt = "ORDER: " + orderid.ztrim() + " KONTROLLERAS...";

        self.setState({ info: txt });
        var prm = {};
        prm.sid = sid;
        prm.req = "vcm.ecom.ecom_result";
        prm.orderid = orderid;
        prm.okey = okey;

        //alert(JSON.stringify(prm));
        //return;
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(ret.ok != "000") {
                self.setState({ error: "Order ej godkänd, Kontrollera och försök igen!" });
                return;
            }

            //alert(JSON.stringify(ret.order));
            var order = ret.order;
            var xbo = ret.sbo;
            //alert(JSON.stringify(xbo));
            if(order.order00.paid == "1") {
                self.setState({ info: "", order: order, sbo: xbo });

                var cartvalue = (sbo.cart.belopp * 1) / 100;
                var carttax = (sbo.cart.tax * 1) / 100;
                var cartship = (sbo.cart.levbelopp * 1) / 100;

                var gte = {};
                gte.transaction_id = sbo.cdo.unit.id + "_" + sbo.cart.orderid;
                gte.affiliation = "foodle.se";
                gte.value = cartvalue;
                gte.tax = carttax;
                gte.shipping = cartship;
                gte.currency = "SEK";
                gte.coupon = "";
                gte.items = [];

                for(var row of sbo.cart.rows) {
                    var price = (row.pris * 1) / 100;
                    var quantity = (row.antal * 1);

                    var o = {};
                    o.item_id = row.plu;
                    o.item_name = row.text;
                    o.affiliation = "";
                    o.coupon = "";
                    o.currency = "SEK";
                    o.discount = 0;
                    o.item_brand = "";
                    o.affiliation = "";
                    o.affiliation = "";
                    o.price = price;
                    o.quantity = quantity;
                    gte.items.push(o);
                }

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({ event : "purchase", ecommerce: gte });

/*
                var gte = {};
                gte.purchase = {};
                gte.purchase.actionField = {};
                gte.purchase.actionField.id = sbo.cdo.unit.id + "_" + sbo.cart.orderid;
                gte.purchase.actionField.affiliation = "foodle.se";
                gte.purchase.actionField.value = sbo.cart.belopp.toString().cdipris();
                gte.purchase.actionField.tax = sbo.cart.tax;
                gte.purchase.actionField.shipping = sbo.cart.levbelopp.toString().cdipris();
                gte.purchase.actionField.currency = "SEK";
                gte.purchase.actionField.coupon = "";
                gte.purchase.products = [];
                for(var row of sbo.cart.rows) {
                    var o = {};
                    o.id = row.plu;
                    o.name = row.text;
                    o.price = row.pris.cdipris();
                    o.brand = "";
                    o.category = "";
                    o.variant = "";
                    o.quantity = row.antal;
                    o.coupon = "";
                    gte.purchase.products.push(o);
                }

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({ event : "purchase", ecommerce: gte });
*/

                if(true) {
                    sbo.cdo = {};
                    sbo.cart = {};
                    sbo.cart.belopp = "0";
                    sbo.cart.rows = [];
                    sbo.cart.pro = {};
                    sessionStorage.setItem("sbo", JSON.stringify(sbo));
                }
            }
            else self.setState({ error: "Order obetald, Kontrollera och försök igen!", order: order });
            //alert(JSON.stringify(ret));
        })

    }

    bg_style() {
        var css = {};
        if((this.state.info.length > 0) || (this.state.error.length > 0)) {
            css.display = "none";
        }
        css.background = "#fee260";
        return css;
    }
    bg_error_style() {
        var css = {};
        if(this.state.error.length < 1) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#d50a19";
        css.color = "#fff";
        return css;
    }
    bg_info_style() {
        var css = {};
        if(this.state.info.length < 1) {
            css.display = "none";
        }
        if(this.state.error.length > 0) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#3e79bb";
        css.color = "#fff";
        return css;
    }

    imgUrl(fil) {
        var url = "";
        url = "https://story.svepos.se/foodle/";
        url = url + fil;
        return url;
    }
    pro_ina(row) {
        var a = [];
        //alert(JSON.stringify(row.pro.ino));
        if(row.pro.ino) a = Object.values(row.pro.ino);
        return a;
    }
    pro_ixa(row) {
        var a = [];
        if(row.pro.ixo) a = Object.values(row.pro.ixo);
        return a;
    }
    pro_txa(row) {
        var a = [];
        if(row.pro.txo) a = Object.values(row.pro.txo);
        return a;
    }
    pro_sxa(row) {
        var a = [];
        if(row.pro.sxo) a = Object.values(row.pro.sxo);
        return a;
    }
    pro_dxa(row) {
        var a = [];
        if(row.pro.dxo) a = Object.values(row.pro.dxo);
        return a;
    }

    cart_mail() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = this.state.sbo.cdo.unit.sid;
        prm.req = "vcm.ecom.confirm_mail";
        prm.sbo = this.state.sbo;

        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
            }
            else {
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    cart_sms() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = this.state.sbo.cdo.unit.sid;
        prm.req = "vcm.ecom.confirm_sms";
        prm.sbo = this.state.sbo;

        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok == "000") {
                }
                else {
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    back() {
        if(true) {
            sbo.cdo = {};
            sbo.cart = {};
            sbo.cart.belopp = "0";
            sbo.cart.rows = [];
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
            var url = "/shop/";
            this.props.navigate(url);
            return;
        }
    }

  render() {

    return (
    <div className="web-app">

        <div className="wp flex-row px-5 py-3">
            <div className="flex">
            <div className="txt_18 cursor-pointer" onClick={() => this.back() }><FontAwesomeIcon icon="arrow-left" size="1x" /> Tillbaka</div>
            </div>
            <div className="txt_22">ORDER</div>
            <div className="flex"></div>
        </div>

        <div className="flex-col flex-center" style={ this.bg_error_style() }>

            <div className="flex-col py-5 px-5">
                <div className="txt_22">{ utils.toLang(this.state.error) }</div>
                <div className="txt_22">{ utils.toLang(this.state.errormsg) }</div>
            </div>
            <div className="flex-row">
                <div className="mpay-btn" onClick={() => this.back() }>STÄNG</div>
            </div>

        </div>
        <div className="flex-col flex-center" style={ this.bg_info_style() }>

            <div className="flex-col py-5 px-5">
                <div className="txt_22">{ utils.toLang(this.state.info) }</div>
            </div>

        </div>

        <div className="" style={ this.bg_style() }>
            <div className="dlt-body">
                <div className="flex-col py-5 px-5">

                    <div className="kvitto-logo"><img src={FoodleLogo} className="cursor-pointer" alt="Foodle" /></div>
                    <div className="txt_22">TACK FÖR DIN ORDER!</div>
                    <div className="flex-row py-3">
                        <div className="txt_22">ORDERID: { utils.toNum(this.state.order.order00.notanr) }</div>
                    </div>

                    <div className="mpg">
                        <div className="flex-row py-3">
                            <div className="flex"></div>
                        </div>
                        <div className="flex-row">
                            <div className="txt_18">{ utils.toLang(this.state.order.order01.fnamn) } { utils.toLang(this.state.order.order01.enamn) }</div>
                        </div>
                        <div className="flex-row">
                            <div className="txt_18">{ this.state.order.order01.epost }</div>
                        </div>
                        <div className="flex-row">
                            <div className="txt_18">{ this.state.order.order01.tele }</div>
                        </div>

                        <div className="flex-row py-3">
                            <div className="flex"></div>
                        </div>

                        <div className="flex-row">
                            <div className="txt_b16">Produkter</div>
                        </div>
                        <div className="flex-col px-2">
                            { this.state.sbo.cart.rows.map((crow, ckey) =>
                                <div key={ckey} className="flex-col py-2 cart-item" >
                                    <div className="flex-row" >
                                        <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                        <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.pris) } kr</div>
                                    </div>
                                    { this.pro_ina(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_16">- { utils.toLang(row.p0text) }</div>
                                            <div className="flex"></div>
                                        </div>
                                    )}
                                    { this.pro_ixa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_txa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_sxa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_dxa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    <div className="flex-row" >
                                        <div className="txt_16">Summa</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex-col px-2">
                            { Object.values(this.state.sbo.cart.pro).map((crow, ckey) =>
                                <div key={ckey} className="flex-col py-3 cart-item" >
                                    <div className="flex-row" >
                                        <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                        <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                        <div className="flex"></div>
                                        <div className="txt_14">{ utils.toNum(crow.nrof) } * </div>
                                        <div className="txt_14">{ utils.toPris(crow.price) } kr</div>
                                    </div>
                                    <div className="flex-row" >
                                        <div className="txt_16">Summa</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    <div className="flex-row">
                        <div className="txt_18">SUBTOTAL</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( this.state.sbo.cart.belopp ) } kr</div>
                    </div>
                    { sbo.cart.levbelopp != 0 ?
                        <div className="flex-row">
                            <div className="txt_14">Leveranskostnad</div>
                            <div className="flex"></div>
                            <div className="txt_b14">{ utils.toPris( this.state.sbo.cart.levbelopp ) } kr</div>
                        </div>
                        : null }


                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    { sbo.cart.discount ?
                        <div className="flex-row">
                            <div className="txt_18">AVGÅR RABATT</div>
                            <div className="flex"></div>
                            <div className="txt_b18">{ utils.toPris( this.state.sbo.cart.discount ) } kr</div>
                        </div>
                        : null }
                    <div className="flex-row">
                        <div className="txt_18">BETALT</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( this.state.sbo.cart.topay ) } kr</div>
                    </div>
                </div>
                <div className="flex-col py-5 px-5">

                    <div className="flex-row">
                        <div className="txt_b16">Leverans uppgifter</div>
                    </div>
                    { (sbo.cart.levtyp == "deliver") ?
                        <div className="flex-col py-3 box-lev">
                            <div className="txt_14">LEVERANS</div>
                            <div className="flex-row">
                                <div className="txt_14">{ utils.toLang(this.state.sbo.cdo.adress) }</div>
                                <div className="flex"></div>
                                <div className="txt_14">Ändra</div>
                            </div>
                            <div className="flex-row">
                                <div className="txt_14">{ utils.toLang(this.state.sbo.cdo.zip) } { utils.toLang(this.state.sbo.cdo.city) }</div>
                                <div className="flex"></div>
                                <div className="txt_14"></div>
                            </div>
                        </div>
                    : null }
                    { (sbo.cart.levtyp == "pickup") ?
                        <div className="lev-box flex-col">
                            <div className="txt_14">AVHÄMTNING</div>
                            <div className="fbold">{ utils.toLang(this.state.sbo.cdo.unit.city) }</div>
                            <div className="">{ utils.toLang(this.state.sbo.cdo.unit.adress) }</div>
                        </div>
                    : null }


                </div>

                <div className="flex-row px-5">
                    <div className="web-link" onClick={() => this.cart_sms() }>SMS Bekräftelse</div>
                    <div className="flex"></div>
                    <div className="web-link" onClick={() => this.cart_mail() }>Mail Bekräftelse</div>
                </div>
                <div className="flex-row py-3">
                    <div className="flex"></div>
                </div>
                <div className="flex-row flex-center">
                    <div className="mpay-btn w300" onClick={() => this.cart_close() }>KLAR</div>
                </div>
                <div className="flex-row py-3">
                    <div className="flex"></div>
                </div>


            </div>
        </div>
    </div>
  );
}
}

export default withRouter(observer(Foodle_Order));
