import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'
import axios from 'axios'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Tiden from "../lib/ui/tiden";
import sbo from "../shop/sbo";
import * as srs from "../lib/srs";
import {withRouter} from "../lib/react/withRouter";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Home extends React.Component {
    //var sdo = useContext(SDO);

  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.zip = "";
    this.state.adress = "";
    this.state.gadress = "";
    this.state.loco = {};

    this.state.mgs_size = "3x";
    this.state.mgxs = 1;
    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mgx = [
      {gid: '001', id: 'burgers', text: 'BURGERS', img: 'tasty-burgers.jpg', txtimg: 'txtburger.jpeg', class: 'mg-noimg', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
      {gid: '002', id: 'pizza', text: 'PIZZA', img: 'bella-pizza.jpg', txtimg: 'txtpizza.png', class: 'mg-noimg', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
      {gid: '003', id: 'pasta', text: 'PASTA', img: 'la-pasta.jpg', txtimg: 'txtpasta.png', class: 'mg-noimg', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' },
      {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sassy-sushi.jpg', txtimg: 'txtsushi.png', class: 'mg-noimg', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
      {gid: '005', id: 'taco', text: 'TACO', img: 'taco-loco.jpg', txtimg: 'txttaco.png', class: 'mg-noimg', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
      {gid: '006', id: 'kebab', text: 'KEBAB', img: 'pasha-kebab.jpg', txtimg: 'txtkebab.png', class: 'mg-noimg', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' },
      {gid: '007', id: 'wok', text: 'WOK', img: 'wicked-wok.jpg', txtimg: 'txtwok.png', class: 'mg-noimg', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
      {gid: '008', id: 'sallad', text: 'SALLAD', img: 'lean-grean.jpg', txtimg: 'txtleangreen.png', class: 'mg-noimg', desc: 'Njut av våra fräscha sallader.' },
      //{gid: '009', id: 'husman', text: 'HUSMAN', img: 'hemlagad-husman.jpg', txtimg: 'txthusman.png', class: 'mg-noimg', desc: 'Husmanskost' }
    ];
    this.state.mgo = {};
    this.state.mgo[1] = [
      {gid: '001', id: 'burgers', text: 'BURGERS', img: 'tasty-burgers.jpg', txtimg: 'txtburger.jpeg', class: 'mg-noimg', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
      {gid: '002', id: 'pizza', text: 'PIZZA', img: 'bella-pizza.jpg', txtimg: 'txtpizza.png', class: 'mg-noimg', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
      {gid: '003', id: 'pasta', text: 'PASTA', img: 'la-pasta.jpg', txtimg: 'txtpasta.png', class: 'mg-noimg', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' }
    ];
    this.state.mgo[2] = [
      {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sassy-sushi.jpg', txtimg: 'txtsushi.png', class: 'mg-noimg', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
      {gid: '005', id: 'taco', text: 'TACO', img: 'taco-loco.jpg', txtimg: 'txttaco.png', class: 'mg-noimg', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
      {gid: '006', id: 'kebab', text: 'KEBAB', img: 'pasha-kebab.jpg', txtimg: 'txtkebab.png', class: 'mg-noimg', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' }
    ];
    this.state.mgo[3] = [
      {gid: '007', id: 'wok', text: 'WOK', img: 'wicked-wok.jpg', txtimg: 'txtwok.png', class: 'mg-noimg', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
      {gid: '008', id: 'sallad', text: 'SALLAD', img: 'lean-grean.jpg', txtimg: 'txtleangreen.png', class: 'mg-noimg', desc: 'Njut av våra fräscha sallader.' },
      //{gid: '009', id: 'husman', text: 'HUSMAN', img: 'hemlagad-husman.jpg', txtimg: 'txthusman.png', class: 'mg-noimg', desc: 'Husmanskost' }
    ];
    this.state.accdata = [
      {
        id: '1',
        title: 'Hur vet jag om ni levererar till min adress?',
        content: `På startsidan anger du till vilken adress ni vill har ordern levererad. Om adressen ni anger
                          ligger utanför vår leveransradie kommer ni då få ett meddelande om att vi inte kan leverera
                          till den angivna adressen. Alternativet då är att välja att ni själva hämtar maten från vår
                          upphämtning adress.`
      },
      {
        id: '2',
        title: 'Kan jag beställa från olika kök och ändå få en leverans?',
        content: `Absolut. Oavsett vad och från vilka kök ni beställer, så kommer allting med en och samma
                          leverans.`
      },
      {
        id: '3',
        title: 'Kan jag beställa till imorgon redan idag?',
        content: `Absolut. När ni avslutar er order kommer ni ha möjligheten att välja bland tillgängliga
                          leveranstider och datum för er ordern.`
      },
      {
        id: '4',
        title: 'Jag är allergisk, kan jag äta er mat?',
        content: `Maten bereds i vårt kök där vi dagligen hanterar flera olika ingredienser. Produkter som lagas
                          delar ofta utrustning för att tillverkas och förvaras. Trots att vi är oerhört noggranna och
                          följer föreskrifter i vårt arbete med att minimera eventuella kontamineringar, så finns det
                          ändå risk att produkter och ingredienser kommer i kontakt med varandra. På
                          produktinformationen framgår det om och vilka allergener som produkten innehåller, men vi kan
                          aldrig garantera att våra produkter är helt fria från någon allergen då spår kan förekomma.`
      },
      {
        id: '5',
        title: 'Hur exakta är era leveranstider?',
        content: `När ni gör en beställning kommer ni att få välja en preliminär leveranstid med 20 minuters
                          intervall. Denna är som sagt en preliminär tid eftersom leveranstiden beror på väldigt många
                          faktorer bortom vår kontroll. Däremot kommer ni få ett meddelande från oss så fort
                          leveransen går från oss och i det meddelandet kommer ni via en länk kunna spåra leveransen i
                          realtid.`
      }
    ];

    //this.state.lat = "57.5079875";
    //this.state.lon = "11.9407502";
    this.state.lat = "57.506280";
    this.state.lon = "12.059960";
    //this.state.adress = sbo.cdo.adress;
    this.state.adress = "";
    this.state.zipinfo = "";

    if(!sbo.cdo) sbo.cdo = {};
    if(!sbo.cdo.loco) {
      sbo.cdo.loco = {};
      sbo.cdo.loco.adress = "";
      sbo.cdo.loco.street = "";
      sbo.cdo.loco.snr = "";
      sbo.cdo.loco.city = "";
      sbo.cdo.loco.country = "";
    }

    var loco = {};
    if(sbo.cdo && sbo.cdo.loco) loco = sbo.cdo.loco;
    var adr = "";
    if(loco.street && (loco.street.length > 0)) adr = adr + loco.street;
    if(loco.snr && (loco.snr.length > 0)) adr = adr + " " + loco.snr;
    this.state.adress = adr;

    if(window.innerWidth < 700) {
      this.state.mgs_size = "2x";
    }

    //alert(JSON.stringify(sbo.cdo.loco));
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    //sbo.cdo.street = this.state.adress;
  }
  componentDidMount() {
    this.sz_adress();
    //if(!sbo.cdo.zip) this.geo_init();

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
    //this.geo_init();
    //this.get_adress();
    this.dsi_ecom();
  }
  geo_init() {
    var self = this;

    if(false) {
      var lat = "57.50802";
      var lon = "11.94076";
      this.setState({ lat: lat, lon: lon }, function() {
        this.get_adress();
      });
      return;
    }

    if ("geolocation" in navigator) {
      //alert("Available");
    } else {
      //alert("Not Available");
      return;
    }
    navigator.geolocation.getCurrentPosition(function(position) {
      if(position) {

        var lat = position.coords.latitude;
        var lon = position.coords.longitude;
        self.setState({ lat: lat, lon: lon }, function() {
          self.get_fulladress();
        });
      }
    }, function(error) {
      //alert("Error Code = " + error.code + " - " + error.message);
    });
  }

  sz_adress() {
    var self = this;

    const input = document.getElementById("autocomplete");
    const options = {
      componentRestrictions: { country: "se" },
      //fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
      //radius: 150000,
      types: ['address']
      //types: ['geocode']
      //types: ["establishment"],
    };
    const autocomplete = new window.google.maps.places.Autocomplete(input, options);

    window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
      var place = autocomplete.getPlace();
      if(!place.geometry.location) return;
      //alert(JSON.stringify(place));
      var lat = place.geometry.location.lat();
      var lon = place.geometry.location.lng();
      //alert(JSON.stringify(Object.values(place["address_components"])));
      var loco = {};
      loco.street = "";
      loco.snr = "";
      loco.city = "";
      loco.zip = "";
      loco.country = "";
      for(var post of place.address_components) {
        if(post.types.indexOf("street_number") != -1) loco.snr = post.short_name;
        if(post.types.indexOf("route") != -1) loco.street = post.short_name;
        if(post.types.indexOf("postal_code") != -1) loco.zip = post.short_name;
        if(post.types.indexOf("postal_town") != -1) loco.city = post.short_name;
        if(post.types.indexOf("country") != -1) loco.country = post.long_name;
      }
      var adr = "";
      adr = adr + loco.street;
      if(loco.snr && (loco.snr.length > 0)) adr = adr + " " + loco.snr;
      sbo.cdo.loco = loco;
      sessionStorage.setItem("sbo", JSON.stringify(sbo));

      self.setState({ lat: lat, lon: lon, adress: adr }, function() {
        self.get_adress();
      });
      return;

    });
  }

  zip_open() {
    var self = this;
    var zip = sbo.cdo.zip;

    var nkp = ["61100", "61102", "61103", "61104", "61105", "61107", "61108", "61110", "61111", "61120", "61122", "61123", "61124", "61125", "61126", "61127", "61129"
      ,"61130", "61131", "61132", "61133", "61134", "61135", "61136", "61137", "61138", "61139", "61144", "61145", "61146", "61147", "61150", "61155", "61156", "61157", "61160", "61161", "61162", "61163", "61164", "61165", "61166", "61167", "61168"
      , "61179", "61181", "61182", "61183", "61184", "61185", "61186", "61187", "61188", "61189", "61300" ];
    var dan = ["18200", "18202", "18203", "18204", "18205", "18206", "18207", "18211", "18212", "18213", "18214", "18215", "18216", "18217", "18220", "18230", "18231", "18232", "18233", "18234", "18235", "18236", "18237", "18238", "18239", "18245", "18246", "18247", "18248", "18249"
      , "18250", "18252", "18253", "18254", "18255", "18256", "18257", "18260", "18261", "18262", "18263", "18264", "18265", "18266", "18267", "18268", "18269", "18273", "18274", "18275", "18276", "18277", "18278", "18279", "18282", "18285", "18287", "18288", "18291" ];
    var taby = ["18300", "18301", "18302", "18303", "18304", "18311", "18312", "18313", "18314", "18315", "18316", "18320", "18321", "18322", "18323", "18325", "18326", "18330", "18331", "18332", "18333", "18334", "18335", "18336", "18337", "18338", "18339"
      , "18341", "18347", "18348", "18349", "18350", "18351", "18352", "18353", "18354", "18355", "18356", "18357", "18358", "18359", "18360", "18361", "18362", "18363", "18364", "18365", "18366", "18367", "18368", "18369", "18370"
      , "18371", "18372", "18374", "18375", "18376", "18377", "18378", "18379", "18380", "18383", "18700", "18705", "18720", "18726", "18728", "18729", "18730", "18731", "18732", "18733", "18734", "18735", "18736", "18737", "18738", "18740"
      , "18741", "18742", "18743", "18744", "18745", "18746", "18747", "18748", "18749", "18750", "18751", "18752", "18753", "18754", "18760", "18761", "18762", "18763", "18764", "18765", "18766", "18767", "18768", "18769"];
    var nac = [ "10523", "13100", "13102", "13104", "13105", "13106", "13107", "13108", "13120", "13121", "13122", "13124", "13125", "13126", "13127", "13128", "13130", "13131", "13132", "13133", "13134", "13135", "13136", "13137", "13138", "13139"
      , "13140", "13141", "13142", "13144", "13145", "13146", "13147", "13148", "13149", "13150", "13151", "13152", "13153", "13154", "13155", "13156", "13157", "13160", "13161", "13165", "13171", "13172", "13173", "13174", "13175", "13181", "13183", "13185", "13192"
      , "13300", "13380", "13381", "13383" ];
    var hbs = [ "12030", "12031", "12032", "12061", "12062", "12063", "12064", "12065", "12066", "12067", "12068", "12069", "12070", "12071", "12078", "12079" ];
    var hbh = [ "12002", "12006", "12007", "12008", "12009", "12047", "12048", "12105", "12106", "12107", "12114", "12115", "12116", "12117", "12118", "12120", "12121", "12122", "12123", "12125", "12126", "12127", "12128", "12129"
      , "12130", "12131", "12132", "12133", "12135", "12136", "12137", "12138", "12139", "12140", "12143", "12144", "12145", "12146", "12147", "12148", "12149", "12150", "12151", "12152", "12153", "12154", "12155"
      , "12162", "12163", "12177", "12231", "12232", "12234", "12262" ];
    var hud = [ "14100", "14104", "14118", "14120", "14121", "14122", "14123", "14124", "14125", "14126", "14127", "14128", "14130", "14131", "14132", "14133", "14135", "14143"
      , "14144", "14147", "14148", "14152", "14153", "14154", "14155", "14156", "14157", "14159", "14161", "14162", "14163", "14168", "14169"
      , "14181", "14183", "14184", "14185", "14187", "14188", "14189", "14650" ];
    var hud2 = [ "14134", "14138", "14139", "14140", "14141", "14142", "14145", "14146", "14149", "14150", "14151", "14158", "14160", "14173", "14192" ];
    var tull = [ "14600", "14620", "14621", "14622", "14630", "14631", "14632", "14634", "14635", "14636", "14637", "14638", "14640", "14645", "14646", "14648", "14649", "14652", "14653", "14654", "14680" ];

    sbo.cdo.unit = {};
    sbo.cdo.unit.id = "BAS";
    sbo.cdo.unit.sid = "S0003202";

    if(!sbo.cdo.zip) {
      sbo.cdo.zipok = 0;
      var info = "";
      self.setState({ zipinfo: info });
    }
    if(sbo.cdo.zip) {
      zip = sbo.cdo.zip;
      zip = zip.replaceAll(" ", "");
      //alert(zip);
      if(dan.includes(zip) == true) {
        //alert("Danderyd");
        sbo.cdo.zipok = 1;
        sbo.cdo.unit.id = "DAN";
        sbo.cdo.unit.sid = "S0003202";
        var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka här i rutan.";
        self.setState({ zipinfo: info });
      }
      else if(taby.includes(zip) == true) {
        //alert("Danderyd");
        sbo.cdo.zipok = 1;
        sbo.cdo.unit.id = "DAN";
        sbo.cdo.unit.sid = "S0003202";
        var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka här i rutan.";
        self.setState({ zipinfo: info });
      }
      /*
      else if(nkp.includes(zip) == true) {
        //alert("Nykoping");
        sbo.cdo.zipok = 1;
        sbo.cdo.unit.id = "NKP";
        sbo.cdo.unit.sid = "S0003201";
        var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka här i rutan.";
        self.setState({ zipinfo: info });
      }
      else if(nac.includes(zip) == true) {
        //alert("Danderyd");
        sbo.cdo.zipok = 1;
        sbo.cdo.unit.id = "SIC";
        sbo.cdo.unit.sid = "S0003203";
        var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka här i rutan.";
        self.setState({ zipinfo: info });
      }
      else if(hbs.includes(zip) == true) {
        //alert("Danderyd");
        sbo.cdo.zipok = 1;
        sbo.cdo.unit.id = "SIC";
        sbo.cdo.unit.sid = "S0003203";
        var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka här i rutan.";
        self.setState({ zipinfo: info });
      }
      else if(hbh.includes(zip) == true) {
        //alert("Danderyd");
        sbo.cdo.zipok = 1;
        sbo.cdo.unit.id = "SIC";
        sbo.cdo.unit.sid = "S0003203";
        var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka här i rutan.";
        self.setState({ zipinfo: info });
      }
      */
      /*
      else if(hud.includes(zip) == true) {
        //alert("Danderyd");
        sbo.cdo.zipok = 1;
        sbo.cdo.unit.id = "HUD";
        sbo.cdo.unit.sid = "S0003204";
        var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka här i rutan.";
        self.setState({ zipinfo: info });
      }
      else if(hud2.includes(zip) == true) {
        //alert("Danderyd");
        sbo.cdo.zipok = 1;
        sbo.cdo.unit.id = "HUD";
        sbo.cdo.unit.sid = "S0003204";
        var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka här i rutan.";
        self.setState({ zipinfo: info });
      }
      else if(tull.includes(zip) == true) {
        //alert("Danderyd");
        sbo.cdo.zipok = 1;
        sbo.cdo.unit.id = "HUD";
        sbo.cdo.unit.sid = "S0003204";
        var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka här i rutan.";
        self.setState({ zipinfo: info });
      }
      */
  else {
    sbo.cdo.zipok = 0;
    //var info = "Sorry!! Vi kan inte Leverans till din adress. Men ni kan alltid handla och hämta maten hos oss.";
    var info = "Tyvärr kan vi inte leverera till din adress. Men ni kan alltid handla och hämta maten hos oss. Välkommen!";
    self.setState({ zipinfo: info });
  }

}
self.MnuShowZip();
}

zipclick() {
if(sbo.cdo.zipok == 1) this.navGo("shop");
}

get_adress() {
var self = this;
var apikey = "AIzaSyD9Ai4X2zbmpvgl_I8VAaSjkj9KFpe0aI8";
//var apikey = "AIzaSyDWLiK2vil0dsYeFBaGv43OCOU0T-Hncb8";
var latlng = this.state.lat + "," + this.state.lon;
var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlng + "&key=" + apikey;
axios.get(url)
.then(function (response) {
//alert(JSON.stringify(rsp));
var rsp = response.data;
//alert(JSON.stringify(rsp));
if(rsp.results) {
  var ado = rsp.results[0];
  //alert(JSON.stringify(ado.formatted_address));
  var loco = sbo.cdo.loco;
  //var loco = {};
  //loco.adress = ado.formatted_address;
  loco.city = "";
  loco.zip = "";
  for(var post of ado.address_components) {
    //if(post.types.indexOf("route") != -1) loco.street = post.short_name;
    if(post.types.indexOf("postal_code") != -1) loco.zip = post.short_name;
    if(post.types.indexOf("postal_town") != -1) loco.city = post.short_name;
  }
  var adr = "";
  adr = adr + loco.street;
  if(loco.snr && (loco.snr.length > 0)) adr = adr + " " + loco.snr;
  if(loco.zip && (loco.zip.length > 0)) {
    adr = adr + ", " + loco.zip;
    if(loco.city && (loco.city.length > 0)) adr = adr + " " + loco.city;
  }
  else if(loco.city && (loco.city.length > 0)) adr = adr + ", " + loco.city;
  if(loco.country && (loco.country.length > 0)) adr = adr + ", " + loco.country;
  loco.adress = adr;

  //alert(JSON.stringify(loco));
  sbo.cdo.zip = loco.zip;
  sbo.cdo.adress = loco.adress;
  //sbo.cdo.adress = loco.street;
  sbo.cdo.loco = loco;
  //alert(JSON.stringify(sbo.cdo.adress));
  sessionStorage.setItem("sbo", JSON.stringify(sbo));
  self.setState({ loco: loco });
  //self.setState({ loco: loco, adress: loco.street });
  self.zip_open();
}
});
}
get_fulladress() {
var self = this;
var apikey = "AIzaSyD9Ai4X2zbmpvgl_I8VAaSjkj9KFpe0aI8";
//var apikey = "AIzaSyDWLiK2vil0dsYeFBaGv43OCOU0T-Hncb8";
var latlng = this.state.lat + "," + this.state.lon;
var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlng + "&key=" + apikey;
axios.get(url)
  .then(function (response) {
    //alert(JSON.stringify(rsp));
    var rsp = response.data;
    //alert(JSON.stringify(rsp));
    if(rsp.results) {
      var ado = rsp.results[0];
      var loco = {};
      //loco.adress = ado.formatted_address;
      loco.adress = "";
      loco.street = "";
      loco.snr = "";
      loco.city = "";
      loco.zip = "";
      loco.country = "";
      for(var post of ado.address_components) {
        if(post.types.indexOf("street_number") != -1) loco.snr = post.short_name;
        if(post.types.indexOf("route") != -1) loco.street = post.short_name;
        if(post.types.indexOf("postal_code") != -1) loco.zip = post.short_name;
        if(post.types.indexOf("postal_town") != -1) loco.city = post.short_name;
        if(post.types.indexOf("country") != -1) loco.country = post.short_name;
      }
      var adr = "";
      adr = adr + loco.street;
      if(loco.snr && (loco.snr.length > 0)) adr = adr + " " + loco.snr;
      if(loco.zip && (loco.zip.length > 0)) adr = adr + ", " + loco.zip;
      if(loco.city && (loco.city.length > 0)) adr = adr + ", " + loco.city;
      loco.adress = adr;
      var sadr = "";
      sadr = sadr + loco.street;
      if(loco.snr && (loco.snr.length > 0)) sadr = sadr + " " + loco.snr;

      //alert(JSON.stringify(loco));
      sbo.cdo.zip = loco.zip;
      sbo.cdo.adress = loco.adress;
      //sbo.cdo.adress = loco.street;
      sbo.cdo.loco = loco;
      sessionStorage.setItem("sbo", JSON.stringify(sbo));
      self.setState({ loco: loco, adress: sadr });
      //self.setState({ loco: loco, adress: loco.street });
      self.zip_open();
    }
  });
}
bg_full_style() {
var css = {};
css.height = window.innerHeight;
return css;
}
bg_style() {
var css = {};
css.margin = "0px auto";
//css.padding = "100px 20px";
css.width = "100%";
css.backgroundImage = 'url("https://story.svepos.se/foodle/foodle_hero_startsida.jpeg")';
css.backgroundSize = "auto";
//css.backgroundSize = "cover";
//css.backgroundAttachment = "fixed";
css.backgroundPosition = "-550px -100px";
//if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
//if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
return css;
}
zipinfo_style() {
var css = {};
//css.display = "none";
if(this.state.zipinfo.length < 1) css.display = "none";
if(sbo.cdo.zipok) css.background = "#5fbd71";
else css.background = "#be2b2b";
return css;
}
home_style() {
var css = {};
css.position = "relative";
css.margin = "0px";
return css;
}
bg1_style() {
var css = {};
css.margin = "0px auto";
css.padding = "0px";
css.width = "100%";
css.background = "rgb(207,226,248)";
//if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
//if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
return css;
}
bg2_style() {
var css = {};
css.margin = "0px auto";
css.padding = "0px";
css.width = "100%";
css.background = "#fff";
//if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
//if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
return css;
}
bg3_style() {
var css = {};
css.margin = "0px auto";
css.padding = "0px";
css.width = "100%";
css.background = "#ff92d4";
css.color = "#f43b24";
//if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
//if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
return css;
}
bg4_style() {
var css = {};
css.margin = "0px auto";
css.padding = "0px";
css.width = "100%";
css.background = "#fff";
//if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
//if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
return css;
}

fknGo(url) {
//props.history.push(row.url);
this.props.history.push(url);
};

ddo() {
//alert("POS" + this.state.pgid );
//this.setState({ status: 1 });
}
dsi_ecom() {
var self = this;

var prm = {};
//prm.sid = sbo.cdo.sid;
prm.sid = "S0000091";
prm.req = "dsi.dsi_ecom.ecom_adress";
prm.lat = this.state.lat;
prm.lon = this.state.lon;

//alert(JSON.stringify(prm));
srs.sio_req(prm)
.then(function(ret){
  //alert(JSON.stringify(ret));

  var zip = ret.zip;
  self.setState({ zip: zip });
  //self.syncTb("mnaTable");
})
.catch(function(e){
  var txt = JSON.stringify(e);
});
}
setAdress(e) {
//alert(JSON.stringify(e));
//alert(e.currentTarget.value);
this.setState({ adress: e.currentTarget.value});
};

mg_cls(btn) {
var cls = "col";
//if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
//else cls = cls + " btn_free";
return cls;
}
mg_style(btn) {
var css = {};
css.margin = "5px 12px";
//if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
//if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
return css;
}

imgUrl(fil) {
var url = "";
url = "https://story.svepos.se/foodle/";
url = url + fil;
return url;
}

mg_open(mgo) {
sbo.cdo.gid = mgo.gid;
var url = "";
//url = url + "/foodle/menu/" + mgo.id;
url = url + "/foodle/menu/grp";
this.props.history.push(url);
};
acch_style(id) {
var css = {};
css.color = "#000";
css.padding = "15px 5px";
css.borderTop = "1px solid #222";
if(id == this.state.selid) css.color = "#080";
return css;
}
acc1_style() {
var css = {};
//css.borderBottom = "1px solid #222";
return css;
}
acc2_style() {
var css = {};
css.borderTop = "1px solid #222";
return css;
}
acc_style(id) {
var css = {};
css.padding = "10px 5px";
if(id != this.state.selid) css.display = "none";
return css;
}
acc_icon_style() {
var css = {};
css.color = "#888";
return css;
}
acc_icon(id) {
var ico = "";
ico = "angle-down";
if(id == this.state.selid) {
ico = "angle-up";
}
return ico;
}
acc_set(id) {
if(this.state.selid == id) id = "";
this.setState({"selid": id});
return;
}
faq() {
return;
}
mgs_add() {
if(window.innerWidth > 700) {
var mgs = this.state.mgs + 1;
if(mgs > 3) mgs = 1;
this.setState({ mgs: mgs });
}
else {
var mgxs = this.state.mgxs + 1;
if(mgxs > 9) mgxs = 1;
this.setState({ mgxs: mgxs });
}
return;
}
mgs_sub() {
if(window.innerWidth > 700) {
var mgs = this.state.mgs - 1;
if(mgs < 1) mgs = 3;
this.setState({ mgs: mgs });
}
else {
var mgxs = this.state.mgxs - 1;
if(mgxs < 1) mgxs = 9;
this.setState({ mgxs: mgxs });
}
return;
}
mgs_a() {
var a = [];
if(window.innerWidth > 700) {
a = this.state.mgo[this.state.mgs];
}
else {
var mgxs = this.state.mgxs;
var mgo = this.state.mgx[mgxs - 1];
//var mgo = this.state.mgx[mgxs.toString().tonum(3)];
a.push(mgo);
}
return a;
}
navGo(url) {
//alert(JSON.stringify(url));
this.props.navigate(url);
};

MnuShowZip() {
var mgo = {};

if(sbo.cdo.unit.sid == "S0003201")
{
mgo[1] = [
  {gid: '001', id: 'burgers', text: 'BURGERS', img: 'tasty-burgers.jpg', txtimg: 'txtburger.jpeg', class: 'mg-noimg', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
  {gid: '002', id: 'pizza', text: 'PIZZA', img: 'bella-pizza.jpg', txtimg: 'txtpizza.png', class: 'mg-noimg', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
  {gid: '003', id: 'pasta', text: 'PASTA', img: 'la-pasta.jpg', txtimg: 'txtpasta.png', class: 'mg-noimg', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' }
];
mgo[2] = [
  {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sassy-sushi.jpg', txtimg: 'txtsushi.png', class: 'mg-noimg', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
  {gid: '005', id: 'taco', text: 'TACO', img: 'taco-loco.jpg', txtimg: 'txttaco.png', class: 'mg-noimg', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
  {gid: '006', id: 'kebab', text: 'KEBAB', img: 'pasha-kebab.jpg', txtimg: 'txtkebab.png', class: 'mg-noimg', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' }
];
mgo[3] = [
  {gid: '007', id: 'wok', text: 'WOK', img: 'wicked-wok.jpg', txtimg: 'txtwok.png', class: 'mg-noimg', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
  {gid: '008', id: 'sallad', text: 'SALLAD', img: 'lean-grean.jpg', txtimg: 'txtleangreen.png', class: 'mg-noimg', desc: 'Njut av våra fräscha sallader.' },
  //{gid: '009', id: 'husman', text: 'HUSMAN', img: 'hemlagad-husman.jpg', txtimg: 'txthusman.png', class: 'mg-noimg', desc: 'Husmanskost' }
];
}
if(sbo.cdo.unit.sid == "S0003202")
{
mgo[1] = [
  {gid: '001', id: 'burgers', text: 'BURGERS', img: 'tasty-burgers.jpg', txtimg: 'txtburger.jpeg', class: 'mg-noimg', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
  {gid: '002', id: 'pizza', text: 'PIZZA', img: 'bella-pizza.jpg', txtimg: 'txtpizza.png', class: 'mg-noimg', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
  {gid: '003', id: 'pasta', text: 'PASTA', img: 'la-pasta.jpg', txtimg: 'txtpasta.png', class: 'mg-noimg', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' }
];
mgo[2] = [
  {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sassy-sushi.jpg', txtimg: 'txtsushi.png', class: 'mg-noimg', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
  {gid: '005', id: 'taco', text: 'TACO', img: 'taco-loco.jpg', txtimg: 'txttaco.png', class: 'mg-noimg', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
  {gid: '006', id: 'kebab', text: 'KEBAB', img: 'pasha-kebab.jpg', txtimg: 'txtkebab.png', class: 'mg-noimg', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' }
];
mgo[3] = [
  {gid: '007', id: 'wok', text: 'WOK', img: 'wicked-wok.jpg', txtimg: 'txtwok.png', class: 'mg-noimg', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
  {gid: '008', id: 'sallad', text: 'SALLAD', img: 'lean-grean.jpg', txtimg: 'txtleangreen.png', class: 'mg-noimg', desc: 'Njut av våra fräscha sallader.' },
  {gid: '009', id: 'husman', text: 'HUSMAN', img: 'hemlagad-husman.jpg', txtimg: 'txthusman.png', class: 'mg-noimg', desc: 'Husmanskost' }
];
}
if(sbo.cdo.unit.sid == "S0003203")
{
mgo[1] = [
  {gid: '001', id: 'burgers', text: 'BURGERS', img: 'tasty-burgers.jpg', txtimg: 'txtburger.jpeg', class: 'mg-noimg', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
  {gid: '002', id: 'pizza', text: 'PIZZA', img: 'bella-pizza.jpg', txtimg: 'txtpizza.png', class: 'mg-noimg', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
  {gid: '003', id: 'pasta', text: 'PASTA', img: 'la-pasta.jpg', txtimg: 'txtpasta.png', class: 'mg-noimg', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' }
];
mgo[2] = [
  {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sassy-sushi.jpg', txtimg: 'txtsushi.png', class: 'mg-noimg', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
  {gid: '005', id: 'taco', text: 'TACO', img: 'taco-loco.jpg', txtimg: 'txttaco.png', class: 'mg-noimg', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
  {gid: '006', id: 'kebab', text: 'KEBAB', img: 'pasha-kebab.jpg', txtimg: 'txtkebab.png', class: 'mg-noimg', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' }
];
mgo[3] = [
  {gid: '007', id: 'wok', text: 'WOK', img: 'wicked-wok.jpg', txtimg: 'txtwok.png', class: 'mg-noimg', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
  {gid: '008', id: 'sallad', text: 'SALLAD', img: 'lean-grean.jpg', txtimg: 'txtleangreen.png', class: 'mg-noimg', desc: 'Njut av våra fräscha sallader.' },
  //{gid: '009', id: 'husman', text: 'HUSMAN', img: 'hemlagad-husman.jpg', txtimg: 'txthusman.png', class: 'mg-noimg', desc: 'Husmanskost' }
];
}
if(sbo.cdo.unit.sid == "S0003204")
{
mgo[1] = [
  {gid: '001', id: 'burgers', text: 'BURGERS', img: 'tasty-burgers.jpg', txtimg: 'txtburger.jpeg', class: 'mg-noimg', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
  {gid: '002', id: 'pizza', text: 'PIZZA', img: 'bella-pizza.jpg', txtimg: 'txtpizza.png', class: 'mg-noimg', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
  {gid: '003', id: 'pasta', text: 'PASTA', img: 'la-pasta.jpg', txtimg: 'txtpasta.png', class: 'mg-noimg', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' }
];
mgo[2] = [
  {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sassy-sushi.jpg', txtimg: 'txtsushi.png', class: 'mg-noimg', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
  {gid: '005', id: 'taco', text: 'TACO', img: 'taco-loco.jpg', txtimg: 'txttaco.png', class: 'mg-noimg', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
  {gid: '006', id: 'kebab', text: 'KEBAB', img: 'pasha-kebab.jpg', txtimg: 'txtkebab.png', class: 'mg-noimg', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' }
];
mgo[3] = [
  {gid: '007', id: 'wok', text: 'WOK', img: 'wicked-wok.jpg', txtimg: 'txtwok.png', class: 'mg-noimg', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
  {gid: '008', id: 'sallad', text: 'SALLAD', img: 'lean-grean.jpg', txtimg: 'txtleangreen.png', class: 'mg-noimg', desc: 'Njut av våra fräscha sallader.' },
  //{gid: '009', id: 'husman', text: 'HUSMAN', img: 'hemlagad-husman.jpg', txtimg: 'txthusman.png', class: 'mg-noimg', desc: 'Husmanskost' }
];
}

this.setState({ mgo: mgo });
}

render() {

return (
  <div className="web-app">

    <section className="bg_home_01" style={ this.bg_full_style() }>

      <div className="wp">
        <div className="h80"></div>
        <div className="flex-col py-5 zipauto" style={ this.home_style() }>

        <div className="font-bold font-colby text-white f120 py-1">
          <div className="lh120">FLERA KÖK -</div>
          <div className="lh120">EN LEVERANS</div>
        </div>
        <div className="flex-row">
          <div className="txt_14">ANGE ER ADRESS</div>
        </div>
          <div className="flex-row">
            <input className="places-input" id="autocomplete" value={this.state.adress} onChange={ (e) => this.setAdress(e) }/>
          </div>
          <div className="flex-row places-info" >
            <div className="">{ sbo.cdo.loco.zip }</div>
            <div className="px-1"></div>
            <div className="">{ sbo.cdo.loco.city }</div>
          </div>
          <div className="flex-row zipinfo" style={ this.zipinfo_style() } onClick={() => this.zipclick() }>
            <div className="txt_14">{ this.state.zipinfo }</div>
          </div>
        </div>
      </div>

    </section>

    <section className="" style={ this.bg1_style() }>
      <div className="text-center font-bold font-colby text-black f80 py-3">
              Så FUNKAR det!
      </div>

      <div className="flex-row wpx row">

        <div className="flex-col col-md-4 col-sm-12">
          <div className="text-center py-1">
            <img className="" src="https://a.storyblok.com/f/100164/157x157/e82eca85f5/foodle_karta_ikon_svar.png" />
          </div>
          <div className="text-center font-bold font-colby fw700 f32 px-1 py-1">
            FYLL I DIN ADRESS.
          </div>
          <div className="text-center font-colby f16 fw100 px-3 py-1">
            Och se om vi kan leverera till dig.
          </div>
        </div>

        <div className="flex-col col-md-4 col-sm-12">
          <div className="text-center py-1">
            <img className="" src="https://a.storyblok.com/f/100164/157x157/f3a6b7cce4/foodle_mat_ikon_svart.png" />
          </div>
          <div className="text-center font-bold font-colby text-black f20 py-1">
            VÄLJ OCH VRAKA
          </div>
          <div className="text-center font-colby text-black f16 py-1">
            Vi har tio olika konceptkök du kan välja mellan! Hoppas du hittar något som passar dig.
          </div>
        </div>

        <div className="flex-col col-md-4 col-sm-12">
          <div className="text-center py-1">
            <img className="" src="https://a.storyblok.com/f/100164/157x157/8e484a4c77/foodle_timer_ikon_svar.png" />
          </div>
          <div className="text-center font-bold font-colby text-black f20 py-1">
            VÄNTA PÅ OSS!
          </div>
          <div className="text-center font-colby text-black f16 py-1">
            Och se om vi kan leverera till dig.
          </div>
        </div>

      </div>

      <div className="h80"></div>

      <div className="flex-row flex-center">
        <div className="whome-btn bg-white" onClick={() => this.navGo("shop") }>BESTÄLL NU</div>
      </div>

      <div className="h80"></div>

    </section>

    <section className="" style={ this.bg2_style() }>
      <div className="text-center font-bold f32 py-5">
        Upptäck våra kök
      </div>

        <div className="wpx flex-row">
          <div className="mh-center-left" >
            <FontAwesomeIcon className="foodle_circle foodle_circle_shadow" icon="circle-arrow-left" size={this.state.mgs_size} onClick={() => this.mgs_sub() } />
          </div>
        <div className="hs">
        { this.mgs_a().map((mgo, key) =>
            <div key={key} className={ this.mg_cls(mgo) }
                 onClick={() => this.mg_open(mgo) }
                 style={ this.mg_style(mgo) }
            >
              <div className="flex-col">
                <div className={ mgo.class }>
                <img src={this.imgUrl(mgo.img)} className="mg-img" alt="Foodle" />
                </div>
                <img src={this.imgUrl(mgo.txtimg)} className="foodle-txt px-5" alt="Foodle" />
              </div>
              <div className="flex-row px-5 py-3" >
                <div>{ mgo.desc }</div>
              </div>
              <div className="flex-row flex-center py-3 hide" >
                <div className="mg-btn" onClick={() => this.mg_open(mgo) }>TILL KÖK</div>
              </div>
            </div>
        )}
          </div>
          <div className="mh-center-right" >
            <FontAwesomeIcon className="foodle_circle foodle_circle_shadow" icon="circle-arrow-right" size={this.state.mgs_size} onClick={() => this.mgs_add() } />
          </div>
        </div>

        <div className="flex-row flex-center py-3">
          <div className="bhollow-btn bg-white" onClick={() => this.navGo("shop") }>ALLA VÅRA KÖK</div>
        </div>

      <div className="h80"></div>

    </section>

    <section className="" style={ this.bg3_style() }>
      <div className="text-center font-bold font-colby text-tomato f80 py-3">
        STORYN OM FOODLE
      </div>
      <div className="flex-row wp row">

        <div className="flex-col col-md-6 col-sm-12 flex-center">
          <div className="text-center px-2">
          Att äta en god måltid tillsammans med nära och kära kan vara dagens höjdpunkt.
          Men när det är ont om tid, inspirationen tryter och alla är oense om vad man ska äta kan det vara dagens jobbigaste stund…
          Att stå i köket och försöka laga hela familjens favoriträtter är nästan omöjligt.
          </div>
        </div>

        <div className="flex-col col-md-6 col-sm-12">
          <div className="text-center py-1">
            <img className="" src="https://a.storyblok.com/f/100164/157x157/ce468b50a2/foodle_mat_ikon_red.png" />
          </div>
          <div className="flex-row flex-center">
          <div className="text-center font-bold font-feltthat f80 w350 py-1">
            God, enkel mat för folket!
          </div>
          </div>
        </div>

      </div>

      <div className="flex-row flex-center">
        <div className="whollow-btn text-white" onClick={() => this.navGo("about") }>OM OSS</div>
      </div>

      <div className="h80"></div>
    </section>

    <section className="" style={ this.bg4_style() }>
      <div className="text-center font-bold font-colby f80 py-3">
        VANLIGA frågor
      </div>
      <div className="wp">
      <div className="accordion faq">
        <div style={ this.acc1_style() }></div>
        {this.state.accdata.map(({ id, title, content }) => (
            <div key={id} className="flex-col">
              <div className="flex-row txt_20" onClick={() => this.acc_set(id) } style={ this.acch_style(id) }>
                <div className="flex">{title}</div>
                <FontAwesomeIcon icon={ this.acc_icon(id) } size="2x" style={ this.acc_icon_style(id) } />
              </div>
              <div className="flex-row txt_16" style={ this.acc_style(id) }>
                {content}
              </div>
            </div>
        ))}
        <div style={ this.acc2_style() }></div>
      </div>
      <div className="flex-row flex-left">
        <div className="txt_16" onClick={() => this.navGo("faq") }>FLER FRÅGOR OCH SVAR <FontAwesomeIcon icon="arrow-right" size="1x" /></div>
      </div>
      </div>

      <div className="h80"></div>
    </section>

  </div>
);
}
}
//Sys.contextType = ADOContext;

export default observer(withRouter(Foodle_Home));
