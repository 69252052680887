import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from "../lib/react/withRouter";

import Tiden from "../lib/ui/tiden";
import * as srs from "../lib/srs";
import sbo from "../shop/sbo";

import * as utils from "../assets/utils";

import Order_Home from "./order/home";
import Order_Kitchen from "./order/kitchen";
import Order_Sides from "./order/sides";
import Order_Drink from "./order/drink";
import Order_Kassa from "./order/kassa";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Mobs_Order extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.zip = "";
    this.state.adress = "";
    this.state.loco = {};

    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mgo = {};

    //this.state.lat = "57.5079875";
    //this.state.lon = "11.9407502";
    this.state.lat = "57.506280";
    this.state.lon = "12.059960";
    this.state.selurl = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    var path = window.location.pathname;
    if(path.substr(-1) == "/") path = path.substring(0, path.length - 1);
    var op = path.split("/").pop();
    if(op == "order") op = "home";
    this.setState({ selurl: op });
  }

  bg_full_style() {
    var css = {};
    css.height = window.innerHeight;
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    //css.background = "#f43b24";
    //css.color = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  mnu_style(url) {
    var css = {};
    if(url == this.state.selurl) {
      css.background = "#080";
      css.color = "#fff";
    }
    return css;
  }

  navGo(url) {
    this.props.navigate(url);
    this.setState({ selurl: url });
   };

  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }

  render() {

    return (
        <div className="web-app">

          <section className="wnd-fill flex-col" style={ this.bg_style() }>

            <div className="flex-center flex">
              <Routes>
                <Route path="home" element={ <Order_Home store={this.store} />} />
                <Route path="kitchen" element={ <Order_Kitchen store={this.store} />} />
                <Route path="sides" element={ <Order_Sides store={this.store} />} />
                <Route path="drink" element={ <Order_Drink store={this.store} />} />
                <Route path="kassa" element={ <Order_Kassa store={this.store} />} />
                <Route path="*" element={ <Order_Home store={this.store} />} />
              </Routes>
            </div>

            <div className="flex-row">
              <div className="foot-btn flex-col flex-sa flex" style={ this.mnu_style("home") } onClick={()=> this.navGo("home")}>
                <FontAwesomeIcon className="txt_18" icon="home" size="1x" />
                <span>HEM</span>
              </div>
              <div className="foot-btn flex-col flex-sa flex" style={ this.mnu_style("kitchen") } onClick={()=> this.navGo("kitchen")}>
                <FontAwesomeIcon className="txt_18" icon="kitchen-set" size="1x" />
                <span>KÖK</span>
              </div>
              <div className="foot-btn flex-col flex-sa flex" style={ this.mnu_style("sides") } onClick={()=> this.navGo("sides")}>
                <FontAwesomeIcon className="txt_18" icon="chocolate-bar" size="1x" />
                <span>SIDES</span>
              </div>
              <div className="foot-btn flex-col flex-sa flex" style={ this.mnu_style("drink") } onClick={()=> this.navGo("drink")}>
                <FontAwesomeIcon className="txt_18" icon="cocktail" size="1x" />
                <span>DRYCK</span>
              </div>
              <div className="foot-btn flex-col flex-sa flex" style={ this.mnu_style("kassa") } onClick={()=> this.navGo("kassa")}>
                <FontAwesomeIcon className="txt_18" icon="cart-shopping" size="1x" />
                <span>KASSA</span>
              </div>
            </div>

          </section>

        </div>
  );
}
}

export default observer(withRouter(Mobs_Order));
