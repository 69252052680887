import React, { useState, useEffect, useContext } from 'react';
import socketIOClient from "socket.io-client";
import { observer } from 'mobx-react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Tiden from "../lib/ui/tiden";
import * as utils from "../assets/utils";
import Fr_Input from "../lib/ui/fr_input";
import * as srs from "../lib/srs";
import {toTid} from "../assets/utils";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Kds_Pickup extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.wnds = {};
      this.state.msg = {};
      this.state.srs = {};
      this.state.bqreq = {};
      this.state.srs.connected = 0;
      this.sio = null;

      this.state.bqp = [];
      this.state.bqw = [];

      this.state.dlt = [
          {id: '1', ftid: '1300', ttid: '1330', text: '' },
          {id: '2', ftid: '1330', ttid: '1400', text: '' },
          {id: '3', ftid: '1400', ttid: '1430', text: '' },
          {id: '4', ftid: '1430', ttid: '1500', text: '' },
          {id: '5', ftid: '1500', ttid: '1530', text: '' },
          {id: '6', ftid: '1530', ttid: '1600', text: '' },
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    this.srs_down();
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
    this.srs_up();
  }
    srs_up() {
        var self = this;
        //var url = window.location.host;
        //var url = window.location.origin;
        //alert(JSON.stringify(url));
        var url = 'http://212.112.180.151:12200/';
        //var url = 'http://127.0.0.1:9231/';
        //if(true) url = 'https://services.svepos.se:9431/';
        this.sio = socketIOClient(url, {transports: ["websocket"]});

        this.sio.on('connect', function () {
            //alert('Connected!');
            //toastr.success("IO CONNECTED");
            var srs = self.state.srs;
            srs.connected = 1;
            self.setState({ srs: srs });
        });
        this.sio.on('disconnect', function () {
            var srs = self.state.srs;
            srs.connected = 0;
            self.setState({ srs: srs });
        });
        this.sio.on('connect_error', function (e) {
            //alert('Connect Error : ' + JSON.stringify(e));
            //toastr.error("IO CONNECT ERROR", e);
        });
        this.sio.on('error', function (e) {
            //alert('Socket Error : ' + e);
            //toastr.error("IO ERROR", e);
        });
        this.sio.on('srs:rsp', function (base64) {
            //var s = atob(base64);
            //var rsp = JSON.parse(s);
            //alert(JSON.stringify(base64));
            var rsp = base64;
            var reqid = rsp.reqid;
            //if(self.defer[reqid]) self.defer[reqid].resolve(rsp);
            //if(defer[reqid]) defer[reqid].resolve(rsp);
        });
        this.sio.on('bq:refresh', function (base64) {
            //var s = atob(base64);
            //var req = JSON.parse(base64);
            var req = base64;
            //alert(JSON.stringify(req));

            //if(!req.bqo) alert(JSON.stringify(req));

            var bqw = [];
            var bqp = [];
            for(var bong of Object.values(req.bqo)) {
                if(bong.state == "8") {
                    bqp.push( bong );
                }
            }

            self.setState({ bqo: req.bqo, bqw: bqw, bqp: bqp });
        });
    }
    srs_down() {
        this.sio.disconnect();
    }

    mg_cls(btn) {
        var cls = "flex col";
        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }
    bg_style() {
        var css = {};
        //css.background = "#000000";
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
      var url = "";
        url = "https://story.svepos.se/foodle/";
      url = url + fil;
      return url;
    }

    back() {
        if(true) {
            var url = "/foodle/delivery";
            this.props.history.push(url);
            return;
        }
    }
    cart_do() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = "S0000091";
        prm.req = "vcm.ps.ps_ecom_sell";

        return;
        srs.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));

            //self.setState({ mpa: ret.rca });
            //self.syncTb("mnaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
  render() {

    return (
    <div className="web-app">

        <div className="flex-col flex">
            <div className="flex-row px-5 py-3">
                <div className="flex"></div>
                <div className="txt_22">BESTÄLLNINGAR KLARA ATT HÄMTA</div>
                <div className="flex"></div>
            </div>
            <div className="divider_green"></div>
            <div className="" style={ this.bg_style() }>
                <div className="kds-body">
                    <div className="kds-grid">

                        { this.state.bqp.map((bong, key) =>
                            <div key={key} className="bong-body flex-col" >
                                <div className="flex-row" >
                                    <div className="flex"></div>
                                    <div className="txt_62">{ utils.toNum(bong.notanr) }</div>
                                    <div className="flex"></div>
                                </div>
                                <div className="flex-row hide" >
                                    <div className="flex"></div>
                                    <div className="txt_16">{ utils.toTid(bong.tid) }</div>
                                    <div className="flex"></div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>

            </div>

        </div>
        <div className="divider_blue"></div>
        <div className="flex-row px-3 py-3">
            <div className="flex">UPKOPPLAD: { this.state.srs.connected }</div>
        </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Kds_Pickup);
