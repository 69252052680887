import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../../lib/srs";
import * as utils from "../../assets/utils";
import sbo from "../../shop/sbo";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Order_PrdSel extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.mka = [];

    this.state.gnr = "001";
    this.state.ynq = [
      {id: '0', text: 'NEJ'},
      {id: '1', text: 'JA'}
    ];

    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mgo = {};

    this.state.pro = {};
    this.state.pxo = {};

    this.state.mdo = {};

    this.state.varsel = 0;
    this.state.gid = "";
    this.state.pbsel = "";
    this.state.pbo = {};
    if(props.wnds.pro) {
      this.state.gid = props.wnds.gid;
      this.state.pro = props.wnds.pro;
      this.state.mdo = props.wnds.mdo;
    }

    this.state.typ = props.wnds.typ;
    this.state.typtext = "";
    if(this.state.typ == "E"){
      this.state.typtext = "INGREDIENSER";
      this.state.pxo = this.state.pro.ixo;
    }
    if(this.state.typ == "T") {
      this.state.typtext = "TILLBEHÖR";
      this.state.pxo = this.state.pro.txo;
    }
    if(this.state.typ == "S") {
      this.state.typtext = "SIDEORDER";
      this.state.pxo = this.state.pro.sxo;
    }
    if(this.state.typ == "D") {
      this.state.typtext = "DRYCK";
      this.state.pxo = this.state.pro.dxo;
    }
  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
  }

  componentDidMount() {
    //this.mnu_md();
  }

  dlg_style() {
    var css = {};
    //css.height = window.innerHeight;
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    //css.background = "#f43b24";
    //css.color = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  ico_style() {
    var css = {};
    css.color = "#ddd";
    return css;
  }
  var_style() {
    var css = {};
    //if(this.state.pbo.pbo.plu02.p2storhet != "20") {
    if(this.state.varsel != 1) {
      css.display = "none";
    }
    return css;
  }
  nrof_style(row) {
    var css = {};
    if(!row.m0nrof || (row.m0nrof < 1)) {
      css.display = "none";
    }
    return css;
  }

  pxa_toggle() {
    var toggle = this.state.pxabox;
    toggle = !toggle;
    this.setState({ pxabox: toggle });
  }
  pxa_box() {
    var css = {};
    //css.height = "0px";
    return css;
  }
  pxa_style(row) {
    var css = {};
    css.color = "#ddd";
    css.fontSize = "18px";
    if(this.state.pxo[row.m0lopnr]) {
      css.color = "#fee260";
    }
    return css;
  }
  pxa_row_style(row) {
    var css = {};
    //css.color = "#ddd";
    if(this.state.pxo[row.m0lopnr]) {
      css.color = "#000";
    }
    return css;
  }

  var_icon(row) {
    var ico = "";
    ico = "circle";
    if(this.state.pbsel == row.plu00.p0katnr) {
      ico = "circle-check";
    }
    return ico;
  }
  pxa_icon(row) {
    var ico = "";
    ico = "circle";
    if(this.state.pxo[row.m0lopnr]) {
      ico = "circle-check";
    }
    return ico;
  }
  var_set(row) {
    var plusel = row.plu00.p0katnr
    this.setState({ pbsel: plusel });
  }
  pxo_set(row) {
    var pro = this.state.pro;
    var pxo = this.state.pxo;
    var belopp = pro.belopp * 1;
    //alert(JSON.stringify(row));
    if(pxo[row.m0lopnr]) {
      delete( pxo[row.m0lopnr] );
      if(this.state.gid == "008") {
        belopp = belopp - ((row.m0pris * 1) * ((row.m0nrof * 1) - 1));
      }
      else belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
      row.m0nrof = 0;
    }
    else {
      var keys = Object.keys(pxo);
      pxo[row.m0lopnr] = row;
      row.m0nrof = 1;

      if(this.state.gid == "008") {
        if(keys.length > 0) belopp = belopp + (row.m0pris * 1);
      }
      else belopp = belopp + (row.m0pris * 1);

    }
    pro.belopp = belopp.toString();
    this.setState({ pro: pro, pxo: pxo });
  }
  pxa_price(row) {
    var pris = "0";
    var pro = this.state.pro;
    var pxo = this.state.pxo;

    if(this.state.gid == "008") {
      var keys = Object.keys(pxo);
      if(keys.length < 1) {
        pris = "0";
      }
      else pris = row.m0pris;
    }
    else pris = row.m0pris;
    pris = utils.toPris(pris);
    return pris;
  }
  tbo_add(row, typ) {
    var pro = this.state.pro;
    var pxo = this.state.pxo;
    var belopp = pro.belopp * 1;
    if(!row.m0nrof) row.m0nrof = 0;
    row.m0nrof = row.m0nrof + 1;
    //alert(row.m0nrof);
    belopp = belopp + (row.m0pris * 1);
    pro.belopp = belopp.toString();
    this.setState({ pro: pro });
  }
  tbo_sub(row, typ) {
    var pro = this.state.pro;
    var pxo = this.state.pxo;
    var belopp = pro.belopp * 1;

    if(!row.m0nrof) row.m0nrof = 0;
    else row.m0nrof = row.m0nrof - 1;
    //alert(row.m0nrof);

    if(row.m0nrof < 1) {
      delete( pxo[row.m0lopnr] );
      row.m0nrof = 0;
    }
    if(this.state.gid == "008") {
      var keys = Object.keys(pxo);
      if((keys.length < 1) && (row.m0nrof < 1)) {
        if(typ !== "T") belopp = belopp - (row.m0pris * 1);
      }
      else belopp = belopp - (row.m0pris * 1);
    }
    else belopp = belopp - (row.m0pris * 1);
    //belopp = belopp - (row.m0pris * 1);
    pro.belopp = belopp.toString();
    this.setState({ pro: pro, pxo: pxo });
  }

  wnd_close = () => {
    if(this.props.wnds.cb) {
      var rsp = {};
      rsp.ok = "999";
      this.props.wnds.cb(this.props.ctx, rsp);
    }
    else this.props.ctx.setState({ wnds: {} });
  };

  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }
  imgUrl(fil) {
    var url = "";
    url = "https://story.svepos.se/foodle/";
    url = url + fil;
    return url;
  }

  mnu_md() {
    var self = this;

    var mnuid = this.state.gid;

    var prm = {};
    prm.sid = "S0000091";
    prm.req = "pos.pos_mnudata.md_list";
    prm.mnuid = mnuid;
    //alert(JSON.stringify(prm));

    srs.sio_req(prm)
        .then(function(ret){
          //alert(JSON.stringify(ret.rco));

          self.setState({ mdo: ret.rco });
        })
        .catch(function(e){
          var txt = JSON.stringify(e);
        });
  }
  get_md(typ) {
    var a = [];
    //if(typ == "E") a = this.state.mdo[typ];
    a = this.state.mdo[typ];
    if(!a) a = [];
    return a;
  }
  get_cart(typ) {
    var a = [];
    //if(typ == "E") a = this.state.mdo[typ];
    //a = this.state.mdo[typ];
    if(!a) a = [];
    return a;
  }
  prd_add() {
    var self = this;

    var pro = this.state.pro;

    //pro.txo[] = txo;

    this.props.ctx.setState({ wnds: {} });
  }

  render() {

    return (
        <div className="mx_base">
          <div className="m2_backdrop" />
          <div className="md_full flex-col" style={ this.dlg_style() } >
            <div className="mx_content flex">
              <div className="mx-close-btn" onClick={() => this.wnd_close() }><FontAwesomeIcon icon="xmark" size="lg" /></div>

              <div className="flex-col py-3 px-3">
                <div className="flex-row">
                  <div className="txt_22 font-bold">{ utils.toLang(this.state.pro.text) }</div>
                </div>
                <div className="flex-row">
                  <div className="text-left txt_14 darkgrey">{ utils.toLang(this.state.pro.desc) }</div>
                </div>
              </div>
              <div className="divider_orange"></div>

              <div className="mx-body darkgrey">

                { this.get_md(this.state.typ).length ?
                    <div className="mpg">
                      <div className="flex-row">
                        <div className="txt_b16 text-black">{ this.state.typtext }</div>
                        <div className="flex"></div>
                        <div className="txt_b14 text-black">Välj</div>
                      </div>
                      { ((this.state.gid == "008") && (this.state.typ == "T")) ?
                      <div className="flex-row">
                        <div className="txt_b14 text-black"><span>(1 gratis)</span></div>
                      </div>
                      : null }
                      <div className="flex-col pxrow text-left" style={ this.pxa_box() }>
                        { this.get_md(this.state.typ).map((tbo, key) =>
                            <div key={key} className="flex-row" style={ this.pxa_row_style(tbo) }>
                              <FontAwesomeIcon icon={ this.pxa_icon(tbo) } size="lg" style={ this.pxa_style(tbo) } onClick={() => this.pxo_set(tbo) } />
                              <div className="txt_16 px-3" onClick={() => this.pxo_set(tbo) }>{ utils.toLang(tbo.m0text) }</div>
                              <div className="flex" onClick={() => this.pxo_set(tbo) }></div>
                              <div className="flex-row px-2" style={ this.nrof_style(tbo) }>
                                <FontAwesomeIcon icon="circle-minus"  style={ this.pxa_style(tbo) } onClick={() => this.tbo_sub(tbo, "T") } />
                                <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                                <FontAwesomeIcon icon="circle-plus"  style={ this.pxa_style(tbo) } onClick={() => this.tbo_add(tbo, "T") } />
                              </div>
                              <div className="txt_16 text-right" onClick={() => this.pxo_set(tbo) }>{ this.pxa_price(tbo) } kr</div>
                            </div>
                        )}
                      </div>
                    </div>
                    :  null }
              </div>
            </div>
            <div className="mx_foot flex-col px-5 py-2">
              <div className="flex-row py-2">
                <div className="txt_b18">Summa</div>
                <div className="flex"></div>
                <div className="txt_b18">{ utils.toPris( this.state.pro.belopp ) } kr</div>
              </div>
              <div className="flex-row">
                <div className="mfull-btn" onClick={() => this.wnd_close() }>AVBRYT</div>
                <div className="mfull-btn" onClick={() => this.prd_add() }>KLAR</div>
              </div>
            </div>
          </div>
  </div>
  );
}
}

export default observer(Order_PrdSel);
