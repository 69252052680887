import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { observer } from 'mobx-react'

import Tiden from "../lib/ui/tiden";


function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_About extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
      this.state = {};
      this.state.selid = "";
    //context.posrend = this;
      this.state.accdata = [
          {
              id: '1',
              title: 'Hur vet jag om ni levererar till min adress?',
              content: `På startsidan anger du till vilken adress ni vill har ordern levererad. Om adressen ni anger
                          ligger utanför vår leveransradie kommer ni då få ett meddelande om att vi inte kan leverera
                          till den angivna adressen. Alternativet då är att välja att ni själva hämtar maten från vår
                          upphämtning adress.`
          },
          {
              id: '2',
              title: 'Kan jag beställa från olika kök och ändå få en leverans?',
              content: `Absolut. Oavsett vad och från vilka kök ni beställer, så kommer allting med en och samma
                          leverans.`
          },
          {
              id: '3',
              title: 'Kan jag beställa till imorgon redan idag?',
              content: `Absolut. När ni avslutar er order kommer ni ha möjligheten att välja bland tillgängliga
                          leveranstider och datum för er ordern.`
          },
          {
              id: '4',
              title: 'Jag är allergisk, kan jag äta er mat?',
              content: `Maten bereds i vårt kök där vi dagligen hanterar flera olika ingredienser. Produkter som lagas
                          delar ofta utrustning för att tillverkas och förvaras. Trots att vi är oerhört noggranna och
                          följer föreskrifter i vårt arbete med att minimera eventuella kontamineringar, så finns det
                          ändå risk att produkter och ingredienser kommer i kontakt med varandra. På
                          produktinformationen framgår det om och vilka allergener som produkten innehåller, men vi kan
                          aldrig garantera att våra produkter är helt fria från någon allergen då spår kan förekomma.`
          },
          {
              id: '5',
              title: 'Hur exakta är era leveranstider?',
              content: `När ni gör en beställning kommer ni att få välja en preliminär leveranstid med 20 minuters
                          intervall. Denna är som sagt en preliminär tid eftersom leveranstiden beror på väldigt många
                          faktorer bortom vår kontroll. Däremot kommer ni få ett meddelande från oss så fort
                          leveransen går från oss och i det meddelandet kommer ni via en länk kunna spåra leveransen i
                          realtid.`
          }
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.pos_init();
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  pg_style() {
    var css = {};
    css.padding = "10px auto";
    return css;
  }
    ctx_style() {
        var css = {};
        css.width = "750px";
        return css;
    }
    acch_style(id) {
        var css = {};
        css.color = "#000";
        css.padding = "15px 5px";
        css.borderTop = "1px solid #222";
        if(id == this.state.selid) css.color = "#080";
        return css;
    }
    acc1_style() {
        var css = {};
        //css.borderBottom = "1px solid #222";
        return css;
    }
    acc2_style() {
        var css = {};
        css.borderTop = "1px solid #222";
        return css;
    }
    acc_style(id) {
        var css = {};
        css.padding = "10px 5px";
        if(id != this.state.selid) css.display = "none";
        return css;
    }
    acc_icon_style() {
        var css = {};
        css.color = "#888";
        return css;
    }
    acc_icon(id) {
        var ico = "";
        ico = "angle-down";
        if(id == this.state.selid) {
            ico = "angle-up";
        }
        return ico;
    }
    acc_set(id) {
        this.setState({"selid": id});
        return;
    }

  render() {

    return (
    <div className="web-app">
      <section>
          <div className="page" style={ this.pg_style() }>

              <div className="flex-row py-5">
                  <div className="flex"></div>
                  <div style={ this.ctx_style() }>

                      <div className="text-center font-bold font-colby f80 py-3">
                          VANLIGA frågor
                      </div>
          <div className="accordion">
              <div style={ this.acc1_style() }></div>
              {this.state.accdata.map(({ id, title, content }) => (
                  <div className="flex-col">
                  <div className="flex-row txt_20" onClick={() => this.acc_set(id) } style={ this.acch_style(id) }>
                      <div className="flex">{title}</div>
                      <FontAwesomeIcon icon={ this.acc_icon(id) } size="lg" style={ this.acc_icon_style(id) } />
                  </div>
                  <div className="flex-row txt_16" style={ this.acc_style(id) }>
                    {content}
                  </div>
                  </div>
              ))}
              <div style={ this.acc2_style() }></div>
          </div>
        </div>
          <div className="flex"></div>

        </div>
      </div>
      </section>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_About);
