import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../lib/ui/tiden";


function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Rules extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.pos_init();
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  pg_style() {
    var css = {};
    css.background = "#ff92d4";
    css.color = "#f43b24";
    css.font = "Colby";
    css.padding = "10px auto";
    return css;
  }
  ctx_style() {
    var css = {};
    css.width = "450px";
    return css;
  }

  render() {

    return (
    <div className="web-app">
      <section>
        <div className="page">

          <div className="text-center font-bold font-colby f80 py-3">
            REGLER OCH VILLKOR
          </div>
          <div className="body-text">
              <div className="txt-head">
              Allmänna riktlinjer för behandling av personuppgifter
              </div>
              <div className="txt-stycke">
              Denna Integritetspolicy beskriver hur Foodle AB (nedan Foodle eller vi), org. nr [559292-6348], samlar in, använder, lämnar ut och lagrar personuppgifter.
              </div>
              <div className="txt-stycke">
              Integritetspolicyn gäller då Foodle tillhandahåller varor och tjänster i anslutning till köp och övrig kontakt med Foodle, såsom besök på webbplats.
              Du ska alltid kunna känna dig trygg när du lämnar dina personuppgifter till oss.
                  Vi vill med denna integritetspolicy visa hur vi säkerställer att dina personuppgifter behandlas säkert och i enlighet med gällande personuppgiftslagstiftning.

              </div>
              <div className="txt-head">
                  PERSONUPPGIFTSANSVARIG
              </div>
              <div className="txt-stycke">
                  Foodle är personuppgiftsansvarig för behandling av dina personuppgifter och ansvarar för att sådan behandling sker i enlighet med tillämplig lagstiftning.
              </div>

              <div className="txt-head">
                  BEHANDLING AV PERSONUPPGIFTER
              </div>
              <div className="txt-stycke">
                  För att du ska kunna besöka vår webbplats, köpa våra varor eller tjänster eller kontakta oss för information måste vi samla in och behandla personuppgifter om dig.Vi samlar in och behandlar personuppgifter om dig när du genomför ett köp på Foodles webbplats, när du ansöker och blir medlem i vår kundklubb, använder dig av vår support, besöker vår webbplats eller när du på annat sätt har kontakt med oss.
                  Informationen som samlas in från dig krävs för att du ska kunna ingå avtal med Foodle och för att Foodle ska kunna tillhandahålla sina tjänster och erbjudanden.
                  För att kunna ha korrekta adressuppgifter till dig uppdaterar vi dessa automatiskt via tredje parts tjänster för adressuppdatering.
                  För detta ändamål använder vi ditt personnummer för att säkerställa att vi har din senaste adress (beaktansvärt skäl).
              </div>
              <div className="txt-stycke">
              De personuppgifter vi samlar in och behandlar om dig i egenskap av kund är:<br />
              • Namn<br />
              • Identifikationsnummer (personnummer och/eller kundnummer)<br />
              • Adress<br />
              • Telefonnummer<br />
              • E-post<br />
              • Betalningsuppgifter, uppgifter om valt betalsätt och betalningshistorik<br />
              • Uppgifter om dina köp<br />
              • Angivna kundval om dina produkter och tjänster<br />
              • Ärenderelaterad korrespondens (t.ex. personuppgifter som du lämnar vid kontakter med vår kundtjänst)<br />
              • [IP-adress och information om din användning av Foodle:s webbplats<br />
              • Platsinformation från dina mobila enheter, t.ex. mobiltelefon eller surfplatta<br />
              • Foodle kan hämta in personuppgifter adressregisterVi på Foodle använder dina personuppgifter för att på bästa sätt kunna tillhandahålla dig de produkter och tjänster vi erbjuder. Vi använder därför dina personuppgifter för följande syften:• För att kunna tillhandahålla och administrera ditt användarkonto<br />
              • För att kunna leverera varor och tjänster i enlighet med våra avtal med dig<br />
              • För att hantera och kvalitetssäkra kundtjänstärenden<br />
              • För att kunna lämna anpassad information, förmåner och erbjudanden samt ge personligt anpassad upplevelse av vår tjänst<br />
              • För att lämna information om och marknadsföra våra och tredje parters produkter och tjänster<br />
              • För att kunna genomföra och hantera deltagande i tävlingar och event<br />
              • För att utveckla våra tjänster och system och för att genomföra utvärderingar av vår affärsmodell<br />
              • För att förhindra missbruk av våra tjänster, eller för att förhindra, förebygga och utreda brott<br />
              • För att fullgöra rättsliga förpliktelser (t.ex. avseende krav i bokföringslagen, produktansvar och produktsäkerhet och skydd av personuppgifter i IT-system)I nedanstående tabeller får du mer information om bl.a. varför vi använder dina personuppgifter, vilka personuppgifter vi sparar för att uppnå de ändamålen och hur länge vi sparar dina personuppgifter.Ändamålet till varför vi behandlar dina uppgifter:Vi använder dina uppgifter för att kunna hantera dina beställningar och leverera våra produkter och tjänster till dig på ett så smidigt sätt som möjligt.<br />
              </div>

              <div className="txt-head">
                  Exempel på hur vi behandlar dina uppgifter för ändamålet:
              </div>
              <div className="txt-stycke">
              Vi levererar din produkt eller tjänst (inklusive kommunicerar med dig rörande din leverans).
              Vi bekräftar din identitet och ålder.
              Vi administrerar din betalning (i detta ingår också analyser av vilka betallösningar som vi kan erbjuda dig genom kontroll mot din betalningshistorik.
                  Laglig grund som vi stödjer vår behandling på:
                  Fullgörande av avtal.Lagringstid:Uppgifterna sparas under hela avtalsförhållandet och upp till 12 månader från det att avtalet upphörde att gälla.
                  Ändamålet till varför vi behandlar dina uppgifter:För att kunna tillhandahålla och administrera ditt användarkonto.
              </div>
              <div className="txt-head">
                  Exempel på hur vi behandlar dina uppgifter för ändamålet:
              </div>
              <div className="txt-stycke">
              Vi administrerar t.ex.:<br />
              • användaruppgifter och prefenser<br />
              • kontaktuppgifter<br />
              • order- och betalningsuppgifter inklusive orderhistorik<br />
              • meddelanden och samtal mellan medlem och kundtjänst<br />
              • uppgifter om hur du använder våra tjänster, t.ex. besökshistorik på hemsidan, IP-nummer och webbläsarinställningar<br />
              • vilka kampanjer och erbjudanden som utnyttjats<br />
              </div>
              <div className="txt-head">
                  Lagringstid:
              </div>
              <div className="txt-stycke">
                  Uppgifterna sparas under hela avtalsförhållandet och upp till 12 månader från det att avtalet upphörde att gälla.
                  Ändamålet till varför vi behandlar dina uppgifter:
                  Möjliggöra allmän kundvård och kundservice.
                  Exempel på hur vi behandlar dina uppgifter för ändamålet:
                  Vi kommunicerar med dig och besvarar dina frågor som du skickar till oss via telefon.
                  Epost och/eller pushnotiser eller i digitala kanaler.
                  Vi rättar dina felaktiga uppgifter.
                  Vi bekräftar din identitet (om nödvändigt).
                  Vi utreder dina klagomål- och supportärenden.
                  Ett supportärende kan t.ex. gälla teknisk support.
                  Laglig grund som vi stödjer vår behandling på:
                  Behandlingen är nödvändig för att tillgodose vårt och ditt berättigade intresse av att möjliggöra allmän kundvård och hantera kundserviceärenden.
              </div>
              <div className="txt-head">
                  Lagringstid:
              </div>
              <div className="txt-stycke">
                  Uppgifterna sparas under hela avtalsförhållandet och upp till 12 månader från det att avtalet upphörde att gälla.
                  Ändamålet till varför vi behandlar dina uppgifter:
                  Marknadsföring av våra produkter och tjänster.
                  Exempel på hur vi behandlar dina uppgifter för ändamålet:
                  Vi skickar direktmarknadsföring såsom relevant information och anpassade erbjudanden i nyhetsbrev samt på webben och per post, e-post, SMS/MMS samt telefon.
                  Laglig grund som vi stödjer vår behandling på:
                  Vårt berättigade intresse av att kunna marknadsföra vårt företag och våra produkter och tjänster.
              </div>
              <div className="txt-head">
                  Lagringstid:
              </div>
              <div className="txt-stycke">
                  Senast 2 år efter din senaste aktivitet (t.ex. när du senast gjorde ett köp, öppnade och klickade på en länk i vårt nyhetsbrev eller loggade in på ditt användarkonto).
                  Ändamålet till varför vi behandlar dina uppgifter:
                  Förbättra vårt kunderbjudande, till exempel utveckling av tjänster, produkter och funktioner.
              </div>
              <div className="txt-head">
                  Exempel på hur vi behandlar dina uppgifter för ändamålet:
              </div>
              <div className="txt-stycke">
                  Vi gör våra tjänster mer användarvänliga.
                  Vi ger dig och andra kunder möjlighet att påverka vårt sortiment, t.ex. genom kund- och marknadsundersökningar.
                  Vi tar fram underlag i syfte att förbättra IT-system i syfte att höja säkerheten för företaget och för besökarna/kunderna generellt.
              </div>
              <div className="txt-head">
                  Laglig grund som vi stödjer vår behandling på:
              </div>
              <div className="txt-stycke">
                  Behandlingen är nödvändig för att tillgodose vårt och våra kunders berättigade intresse av att förbättra vårt kunderbjudande, till exempel utveckling av tjänster, produkter och funktioner.
              </div>
              <div className="txt-head">
                  Lagringstid:
              </div>
              <div className="txt-stycke">
                  För detta ändamål är det svårt för oss att på förhand ange hur länge dina personuppgifter kommer sparas.
                  Vi har istället infört rutiner för att löpande kontrollera om dina personuppgifter fortfarande är nödvändiga för detta ändamål.
                  Personuppgifter som vi inte har använt under en period om 1 år kommer vi att ta bort eftersom vi då inte längre bedömer att dina personuppgifter är nödvändiga.
                  Självklart vidtar vi åtgärder för att skydda din integritet.
                  När vi utför analyser baserat på dina personuppgifter kommer vår medarbetare som utför analysen inte veta att personuppgifterna tillhör dig, även om vi skulle kunna göra kopplingen till dig baserat på uppgifter som vi har i ett annat system.
                  Ändamålet till varför vi behandlar dina uppgifter:Förhindra bedrägerier, missbruk eller för att förhindra, förebygga eller utreda brott.
              </div>
              <div className="txt-head">
                  Exempel på hur vi behandlar dina uppgifter för ändamålet:
              </div>
              <div className="txt-stycke">
                  Vi utreder och förhindrar bedrägerier eller andra lagöverträdelser.
                  Vi vidtar åtgärder för att förhindra skräppostutskick, phishing, trakasserier, försök till olovlig inloggning på användarkonton eller andra åtgärder som är förbjudna enligt våra användarvillkor.
                  Vi vidtar åtgärder för att skydda och förbättra vår IT-miljö mot angrepp och intrång.
              </div>
              <div className="txt-head">
                  Laglig grund som vi stödjer vår behandling på:
              </div>
              <div className="txt-stycke">
                  Vårt berättigade intresse av att förhindra missbruk av en tjänst eller för att förhindra, förebygga och utreda brott mot företaget.
              </div>
              <div className="txt-head">
                  Lagringstid:
              </div>
              <div className="txt-stycke">
                  Från insamlandet och för en tid om (som längst) 1 år därefter.
                  Om vi misstänker missbruk av en tjänst eller att ett brott har begåtts kommer vi att spara uppgifterna under den tid som är nödvändiga för att fastställa, göra gällande eller försvara våra (eller tredje parts) rättsliga anspråk.
                  </div>
              <div className="txt-head">
                  VEM LÄMNAR VI UT PERSONUPPGIFTER TILL?
              </div>
              <div className="txt-stycke">
                  Foodle kan vid behov lämna ut dina uppgifter till tredje part, såsom till koncernbolag och personuppgiftsbiträden (parter som behandlar uppgifter åt oss och enligt gällande regler).
                  [Foodle kan även komma att lämna ut dina uppgifter till företag som tillhandahåller tjänster för adressuppdateringar för att säkerställa att vi har rätt adressuppgifter till dig.]
                  Personuppgifter kan vidare komma att lämnas ut av Foodle om det är nödvändigt för att följa gällande lagkrav eller krav från myndigheter, för att tillvarata Foodle:s rättsliga intressen eller för att upptäcka, förebygga eller uppmärksamma bedrägerier och andra säkerhets- eller tekniska problem.
                  Vi kommer inte att lämna ut dina personuppgifter till annan tredje part än ovan om vi inte har ditt tillstånd till det.
                  Om hela eller delar av vår verksamhet säljs eller integreras med en annan verksamhet kan dina personuppgifter lämnas ut till våra rådgivare, eventuella köpare och dennes rådgivare.
              </div>
              <div className="txt-head">
                  ÄNDRING AV INTEGRITETSPOLICYN
              </div>
              <div className="txt-stycke">
                  Foodle har rätt att när som helst ändra integritetspolicyn och detta meddelas via vår hemsida.
                  Om vi genomför omfattande förändringar så kommer vi meddela dig detta innan de träder i kraft.
                  Om du inte godtar ändringarna har du rätt att säga upp avtalet med Foodle.
              </div>
              <div className="txt-head">
                  SKYDDET AV DINA PERSONUPPGIFTER
              </div>
              <div className="txt-stycke">
                  Foodle har vidtagit tekniska och organisatoriska säkerhetsåtgärder för att skydda dina personuppgifter mot otillbörlig åtkomst, förändring och radering.
                  Exempelvis sparas uppgifter om kunder på server i säker miljö och i en databas som är skyddad genom behörighetsstyrning samt brandvägg.
                  För kortköp arbetar vi med ett auktoriserat betalningsombud som hjälper oss att direkt kontrollera med din bank att kortet är giltigt för köp.
                  All information skickas i krypterad form via en säker anslutning och Hööks sparar inte dina kortuppgifter.
                  Vårt betalningsombud bearbetar dina kortuppgifter i enlighet med den internationella säkerhetsstandarden PCI DSS.
                  Detta innebär att säkerheten är mycket hög.
              </div>
              <div className="txt-head">
                  DINA RÄTTIGHETER
              </div>
              <div className="txt-stycke">
                  Foodle ansvarar för att dina personuppgifter behandlas i enlighet med gällande lagstiftning.
                  Foodle kommer på din begäran eller på eget initiativ att rätta, avidentifiera, radera eller komplettera uppgifter som upptäcks vara felaktiga eller ofullständiga.
                  Du har bland annat rätt att av begära tillgång till dina personuppgifter.
                  Det innebär att du har rätt att när som helst (gratis en gång om året) begära ett registerutdrag avseende vilka personuppgifter vi har om dig.
                  Rättelse av dina personuppgifter.
                  Vi kommer på din begäran att så snabbt som möjligt rätta de felaktiga eller ofullständiga uppgifter vi behandlar om dig.
              </div>
              <div className="txt-head">
                  Radering av dina personuppgifter.
              </div>
              <div className="txt-stycke">
                  Det innebär att du har rätt att begära att dina personuppgifter tas bort om de t.ex. inte längre är nödvändiga för det ändamål de samlades in för.
                  Det kan dock finnas lagkrav på att vi inte omedelbart får radera dina personuppgifter i exempelvis bokförings- och skattelagstiftning.
                  Vi kommer då att avsluta den behandling som görs för andra ändamål än att följa lagstiftningen.
              </div>
              <div className="txt-head">
                  Begränsning av behandling.
              </div>
              <div className="txt-stycke">
                  Det innebär att dina personuppgifter markeras så att de endast får behandlas för vissa avgränsade ändamål.
                  Om du inte vill att vi behandlar dina personuppgifter för direktmarknadsföring har du alltid rätt att invända mot sådan behandling genom att kontakta oss.
                  När vi har mottagit din invändning kommer vi att upphöra med att behandla personuppgifterna för sådant marknadsföringsändamål.
                  Du har rätt att inge eventuella klagomål angående behandling av dina personuppgifter till Datainspektionen.
                  Foodle leverans och avtalsvillkor
              </div>
              <div className="txt-head">
                  Regler och villkor
              </div>
              <div className="txt-stycke">
                  Endast privatpersoner eller representanter för företag, som är över 15 år och myndig med full juridisk rätt att ingå avtal med annan part, har rätt att utföra inköp via Foodle.se
                  Pris och betalning
                  Priserna för de beställda varorna är de som anges på Foodle.se dagen då ordern läggs.
                  Alla priser är angivna inklusive moms om inte annat anges.
                  Betalning sker med bank- eller kontokort från Visa, Visa Electron, American Express, Maestro eller MasterCard eller med Swish.
                  Foodle.se accepterar inte betalning med kontanter eller checkar.
                  Vid kortbetalning debiteras angivet kort vid beställning.
                  Inga kortuppgifter sparas av Foodle.se utan endast det referensnummer som nyttjas i dialogen med Adyen, vilka tillhandahåller betalningslösningen för Foodle.se.
                  Adyen är certifierad enligt PCI-DSS.
                  Alla kommunikation i samband med betalning är krypterad enligt industristandarden SSL (Secure Socket Layer) för säker överföring över Internet.Erbjudanden
                  Det är endast tillåtet att använda nykundserbjudanden hos Foodle.se en gång per hushåll om inte annat anges.
              </div>
              <div className="txt-head">
                  Leverans
              </div>
              <div className="txt-stycke">
                  Leverans av din mat sker till den adress som du har angivit vid registrering.
                  Det är du som kund som är ansvarig för att hålla dina uppgifter uppdaterade och därmed ge oss möjlighet att leverera till rätt adress.
                  Vid mottagande av din leverans kan du komma att behöva signera att du mottagit dina varor.
                  Om du inte kan ta emot dina varor vid hemleverans gör transportören en bedömning om varorna kan lämnas.
                  Under förutsättning att transportören kommer fram till din ytterdörr lämnas varorna utanför dörren.
                  Om en port eller grind hindrar oss från att nå fram till din dörr lämnas varorna inte kvar.
                  Beställda varor levereras inom ramen för det tidsfönster som anges i orderbekräftelsen.
                  I det fall försening uppstår kommer Foodle.se att snarast kontakta dig för att avtala en ny leveranstid.
                  Ändring av din beställningDu kan avboka eller ändra leveranstid för din beställning fram till 2 timmar före angiven leveransdag eller klockslag utan kostnad.
              </div>
              <div className="txt-head">
                  Reklamation
              </div>
              <div className="txt-stycke">
                  Vi vill alltid ha nöjda kunder.
                  Om det skulle vara något fel på maten vi levererat så är det viktigt att du hör av dig till oss med det samma.
                  Så fort vi mottagit din reklamation så går vi igenom ditt ärende och försöker hitta en lösning på problemet utifrån varje enskild situation.
                  Du når oss enklast via info@foodle.se
              </div>
              <div className="txt-head">
                  Ångerrätt
              </div>
              <div className="txt-stycke">
                  Enligt lagen om distansavtal och avtal utanför affärslokaler (SFS 2005:59) gäller inte ångerrätt för livsmedel och andra dagligvaror.
                  Du har alltså ingen ångerrätt för köpta varor.
                  För varor som inte definieras som dagligvaror kan du ansöka om ångerrätt med ångerblankett från Konsumentverket (KO).
                  Force majeure
                  Skulle Foodle på grund av omständighet, som kan anses ligga bortom Foodle:s kontroll (”Force majeure”), vara förhindrad att utföra våra åtaganden gentemot dig, utgör detta inte ett avtalsbrott.
                  Även vid Force majeure-situationer kommer Foodle alltid att i möjligaste mån försöka utföra våra åtaganden och i de fall där detta inte är möjligt i skälig omfattning kompensera dig.
              </div>
              <div className="txt-head">
                  Tvist
              </div>
              <div className="txt-stycke">
              Vid en eventuell tvist hänvisar vi till Allmänna Reklamationsnämnden.
                  Vi följer alltid Allmänna Reklamationsnämndens rekommendationer.
              </div>
              <div className="txt-head">
                  Behandling av personuppgifter
              </div>
              <div className="txt-stycke">

                  Foodle behandlar dina personuppgifter, såsom dina kontaktuppgifter och dina köp för att skapa en relevant köpupplevelse för dig.
                  Läs mer om vår behandling av dina personuppgifter ovan under rubriken - Allmänna riktlinjer för behandling av personuppgifterPrenumeration på nyhetsbrev
                  Vi skickar nyhetsbrev till den e-postadress du använder när du skapar ett konto på Foodle.se.
                  Du kan när som helst avsluta denna prenumeration genom att avregistrera dig från nyhetsbrevet längst ned i varje utskick.
                  UpphovsrättDessa villkor, liksom all data på denna webbplats, tillhör Foodle och är tillgängliga endast för bruk i samband med köp och korrespondens med Foodle.
                  Annat användande såsom reproduktion, redistribution, derivat av och publicering utanför denna webbplats, såväl för kommersiella som privata syften, är inte tillåtet utan uttryckligt medgivande från Foodle.

              </div>

              <div className="txt-head">
                  Tjänster från Foodle tillhandahålls av:
              </div>
              <div className="txt-stycke">
              Foodle AB<br />
              Klara Södra kyrkogata 1<br />
              111 52 Stockholm<br />
              e-post: info@foodle.se<br />
              Organisationsnummer: 559292-6348<br />
              </div>
          </div>
        </div>
      </section>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_Rules);
