import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Fr_Input from "../lib/ui/fr_input";

import * as srs from "../lib/srs";
import sbo from './sbo.js'
import * as utils from "../assets/utils";

import Foodle_Adyen from "./ext/adyen";
import Foodle_Onfleet from "./ext/onfleet";

import Foodle_Pay from "./pay/pay";
import Foodle_PayOk from "./pay/pay_ok";
import Foodle_PayFail from "./pay/pay_fail";
import {withRouter} from "../lib/react/withRouter";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Kassa extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.loco = {};
      this.state.zip = "";
      this.state.adress = "";
      this.state.discount = "";
      this.state.error = "";
      this.state.errormsg = "";
      this.state.info = "";
      this.state.wnds = {};

      this.state.fnamn = "";
      this.state.enamn = "";
      this.state.tele = "";
      this.state.email = "";
      this.state.msg = "";
      this.state.rabmsg = "";

      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }
      if(!sbo.cart.pro) {
          sbo.cart.pro = {};
      }

      sbo.cart.levbelopp = 0;
      if(sbo.cart.levtyp == "deliver") {
          sbo.cart.levbelopp = 3900;
      }
      if(!sbo.cart.discount) {
          sbo.cart.discount = 0;
      }
      if(isNaN(sbo.cart.discount)) {
          sbo.cart.discount = 0;
      }

      sbo.cart.topay = (sbo.cart.belopp * 1) + (sbo.cart.levbelopp * 1);
      if(sbo.cart.discount) sbo.cart.topay = (sbo.cart.topay * 1) - (sbo.cart.discount * 1);
      sbo.cart.topay = sbo.cart.topay.toString();
      this.state.discount = "";
      if(sbo.cart.rabkod && (sbo.cart.rabkod.length > 0)) this.state.discount = sbo.cart.rabkod;

      //alert(JSON.stringify(sbo.cdo));
      if(sbo.cdo) {
          this.state.fnamn = sbo.cdo.fnamn;
          this.state.enamn = sbo.cdo.enamn;
          this.state.tele = sbo.cdo.tele;
          this.state.email = sbo.cdo.email;
          this.state.msg = sbo.cdo.msg;
      }
      //if(!sbo.cdo) sbo.cdo = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //alert(JSON.stringify(pos));
  }

    bg_style() {
        var css = {};
        if((this.state.info.length > 0) || (this.state.info.length > 0)) {
            css.display = "none";
        }
        css.background = "#fee260";
        return css;
    }
    bg_error_style() {
        var css = {};
        if(this.state.error.length < 1) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#d50a19";
        css.color = "#fff";
        return css;
    }
    bg_info_style() {
        var css = {};
        if(this.state.info.length < 1) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#3e79bb";
        css.color = "#fff";
        return css;
    }

    imgUrl(fil) {
      var url = "";
      url = "https://story.svepos.se/foodle/";
      url = url + fil;
      return url;
    }

    row_del(row) {
        sbo.cart.belopp = 0;
        if(!sbo.cart.rows) sbo.cart.rows = [];

        //sbo.cart.rows = sbo.cart.rows.splice(key - 1, 1);
        var belopp = 0;
        var rows = [];
        for(var crow of sbo.cart.rows) {
            if(crow.plu != row.plu) {
                //alert(JSON.stringify(crow));
                belopp = belopp + (crow.belopp * 1);
                rows.push(crow);
            }
        }
        sbo.cart.rows = rows;
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    pro_del(row) {
        //sbo.cart.belopp = 0;
        //if(!sbo.cart.rows) sbo.cart.rows = [];

        var belopp = sbo.cart.belopp * 1;
        if(!sbo.cart.pro[row.plu]) {
            return;
        }
        delete( sbo.cart.pro[row.plu] );
        belopp = belopp - ((row.nrof * 1) * (row.price * 1));
        //belopp = belopp - (row.price * 1);
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    pro_ina(row) {
        var a = [];
        //alert(JSON.stringify(row.pro.ino));
        if(row.pro.ino) a = Object.values(row.pro.ino);
        return a;
    }
    pro_ixa(row) {
        var a = [];
        if(row.pro.ixo) a = Object.values(row.pro.ixo);
        return a;
    }
    pro_txa(row) {
        var a = [];
        if(row.pro.txo) a = Object.values(row.pro.txo);
        return a;
    }
    pro_sxa(row) {
        var a = [];
        if(row.pro.sxo) a = Object.values(row.pro.sxo);
        return a;
    }
    pro_dxa(row) {
        var a = [];
        if(row.pro.dxo) a = Object.values(row.pro.dxo);
        return a;
    }

    discount_change(e) {
        this.setState({discount: e});
    }
    val_save( id, val ) {
        var state = this.state;
        state[id] = val;
        this.setState(state);
    }
    val_anm_save( id, val ) {
        var state = this.state;
        state[id] = val.currentTarget.value;
        this.setState(state);
    }

    discount_clear() {
        var belopp = sbo.cart.belopp * 1;
        var rkod = sbo.cart.rabkod;
        var discount = sbo.cart.discount * 1;
        discount = 0;

        delete(sbo.cart.rabkod);
        delete(sbo.cart.discount);
        sbo.cart.topay = belopp + (sbo.cart.levbelopp * 1);
        sbo.cart.topay = sbo.cart.topay.toString();
        //sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
        this.setState({discount: ""});
   }

    cart_discount() {
        if(sbo.cart.discount) {
            return;
        }

        var discount = 0;
        var belopp = sbo.cart.belopp.toString();
        belopp = belopp.replaceAll(".", "");
        belopp = belopp * 1;
        var topay = sbo.cart.topay.toString();
        topay = topay.replaceAll(".", "");
        topay = topay * 1;

        if(this.state.discount == "rab50") {
            var rproc = 50;
            discount = belopp * (rproc / 100);
        }
        if(this.state.discount == "chef") {
            discount = topay;
        }
        if(this.state.discount == "AMAA1121") {
            discount = 25000;
            if(topay < discount) discount = topay;
        }

        topay = topay - discount;

        sbo.cart.rabkod = this.state.discount;
        sbo.cart.discount = discount.toString();
        //sbo.cart.belopp = belopp.toString();
        sbo.cart.topay = topay.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
        this.setState({discount: ""});
    }
    cart_rkod_val(vpo) {
        if(sbo.cart.discount && (sbo.cart.discount > 0)) {
            return;
        }

        var discount = 0;
        var belopp = sbo.cart.belopp.toString();
        belopp = belopp.replaceAll(".", "");
        belopp = belopp * 1;
        var topay = sbo.cart.topay.toString();
        topay = topay.replaceAll(".", "");
        topay = topay * 1;

        var rproc = vpo.rproc * 1;
        var rbelopp = vpo.belopp * 1;
        if(rproc > 0) {
            discount = belopp * (rproc / 100);
        }
        else if(rbelopp > 0) {
            discount = rbelopp;
            if(topay < discount) discount = topay;
        }
        else if(vpo.state == "2") {
            discount = topay;
        }

        topay = topay - discount;

        sbo.cart.rabkod = this.state.discount;
        sbo.cart.discount = discount.toString();
        //sbo.cart.belopp = belopp.toString();
        sbo.cart.topay = topay.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
        this.setState({discount: ""});
    }
    cart_rkod() {
        var self = this;

        this.setState({rabmsg: ""});

        if(this.state.discount == "jgtst") {
            var discount = 0;
            var belopp = sbo.cart.belopp.toString();
            belopp = belopp.replaceAll(".", "");
            belopp = belopp * 1;
            var topay = sbo.cart.topay.toString();
            topay = topay.replaceAll(".", "");
            topay = topay * 1;

            discount = topay - 100;

            topay = topay - discount;

            sbo.cart.rabkod = this.state.discount;
            sbo.cart.discount = discount.toString();
            //sbo.cart.belopp = belopp.toString();
            sbo.cart.topay = topay.toString();
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
            this.setState({discount: ""});
            return;
        }
        //sbo.cart.slot = "1300";

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        //prm.sid = sbo.cdo.unit.sid;
        prm.sid = "S0003200";
        prm.req = "vcm.ecom.rkod_use";
        prm.rkod = this.state.discount;
        prm.slot = sbo.cart.slot;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
                var vpo = ret.rco;
                self.cart_rkod_val(vpo);
            }
            else if(ret.ok == "991") {
                var vpo = ret.rco;
                self.setState({rabmsg: "ENDAST GILTIG PÅ LUNCH MELLAN 11.30 - 14.00"});
            }
            else if(ret.ok == "992") {
                var vpo = ret.rco;
                self.setState({rabmsg: "RABATTKOD HAR GÅTT UT"});
            }
            else {
                self.setState({rabmsg: "RABATTKOD EJ GILTIG"});
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    cart_pay() {
      var self = this;
        var fnamn = this.state.fnamn;
        var enamn = this.state.enamn;
        var tele = this.state.tele;
        var email = this.state.email;

        if(!fnamn || (fnamn.length < 1)) {
            alert("Vänligen fyll i för och efternamn");
            return;
        }
        if(!enamn || (enamn.length < 1)) {
            alert("Vänligen fyll i för och efternamn");
            return;
        }
        if(!tele || tele.length < 1) {
            alert("Vänligen fyll i telefon nr");
            return;
        }
        if(!email || email.length < 1) {
            alert("Vänligen fyll i epost adress");
            return;
        }
        this.setState({ info: "BETALNING FÖRBEREDS..." });

        if(true) {
            //alert(JSON.stringify(sbo.cdo));
            //sbo.cart.cdo = {};
            sbo.cdo.fnamn = this.state.fnamn;
            sbo.cdo.enamn = this.state.enamn;
            sbo.cdo.tele = this.state.tele;
            sbo.cdo.email = this.state.email;
            sbo.cdo.msg = this.state.msg;
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
        }

        if(sbo.cart.topay == 0) {
            this.cart_direct_pay();
            return;
        }
        if(false) {
            var url = "/shop/payment";
            this.props.navigate(url);
            return;
        }

        self.setState({ info: "Skapar order" });
        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = sbo.cdo.unit.sid;
        //prm.req = "vcm.ps.ps_ecom_sell";
        prm.req = "vcm.ecom.ecom_new";
        prm.sbo = sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret) {

            if(ret.ok == "000") {
                sbo.cart.orderid = ret.orderid;
                var url = "/shop/payment";
                self.props.navigate(url);
                return;
            }
            else {
                self.setState({ info: "", error: "Kan ej skapa order.", errormsg: "Vänligen försök igen" });
            }

        })

    }
    cart_cb(ctx, rsp) {
        //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            var wnds = {};
            wnds.cartpayok = true;
            wnds.cb = ctx.payok_cb;
            ctx.setState({ wnds: wnds });
        }
        else {
            var wnds = {};
            wnds.cartpayfail = true;
            //wnds.cb = ctx.payok_cb;
            ctx.setState({ wnds: wnds });
        }

        //else ctx.setState({ wnds: {} });
    }
    payok_cb(ctx, rsp) {
        ctx.setState({ wnds: {} });
        if(true) {
            var url = "/shop";
            ctx.props.navigate(url);
            return;
        }
    }
    cart_direct_pay() {
        var self = this;

        //sbo.cart.slot = "1300";

        self.setState({ info: "Skapar order" });
        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = sbo.cdo.unit.sid;
        //prm.req = "vcm.ps.ps_ecom_sell";
        prm.req = "vcm.ecom.ecom_new";
        prm.sbo = sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret) {

            if(ret.ok != "000") {
                self.setState({ info: "", error: "Kan ej skapa order.", errormsg: "Vänligen försök igen" });
                return;
            }
            sbo.cart.orderid = ret.orderid;

            var prm = {};
            //prm.sid = sbo.cdo.sid;
            prm.sid = sbo.cdo.unit.sid;
            prm.req = "vcm.ecom.ecom_direct_pay";
            prm.sbo = sbo;
            prm.orderid = sbo.cart.orderid;
            prm.result = "";

            //alert(JSON.stringify(sbo));
            //return;
            return srs.sio_req(prm);
        })
        .then(function(ret){

            sbo.cart.notanr = ret.notanr;

            if(ret.ok == "000") {
                var wnds = {};
                wnds.cartpayok = true;
                //wnds.cb = self.payok_cb;
                //self.setState({ wnds: wnds });
                var orderkey = sbo.cdo.unit.sid + ";" + sbo.cart.orderid;
                var okey = btoa(orderkey);
                var url = "/shop/order?okey=" + okey;
                self.props.navigate(url);
            }
            else {
                var wnds = {};
                wnds.cartpayfail = true;
                //wnds.cb = ctx.payok_cb;
                self.setState({ wnds: wnds });
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    cart_order_save() {
        var self = this;

        //sbo.cart.slot = "1300";

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = sbo.cdo.unit.sid;
        prm.req = "vcm.ecom.order_add";
        prm.sbo = sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok == "000") {
                    var wnds = {};
                    wnds.cartpayok = true;
                    //wnds.cb = self.payok_cb;
                    self.setState({ wnds: wnds });
                }
                else {
                    var wnds = {};
                    wnds.cartpayfail = true;
                    //wnds.cb = ctx.payok_cb;
                    self.setState({ wnds: wnds });
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    cart_adyen() {

        var self = this;
        var wnds = {};
        wnds.cartadyen = true;
        //wnds.cb = this.cart_cb;
        this.setState({ wnds: wnds });
    }
    cart_onfleet() {

        var self = this;
        var wnds = {};
        wnds.cartonfleet = true;
        //wnds.cb = this.cart_cb;
        this.setState({ wnds: wnds });
    }

    cart_abort(ctx, rsp) {
        var url = "/shop";
        this.props.navigate(url);
    }
    back() {
        if(true) {
            //alert(JSON.stringify(sbo.cdo));
            //sbo.cart.cdo = {};
            sbo.cdo.fnamn = this.state.fnamn;
            sbo.cdo.enamn = this.state.enamn;
            sbo.cdo.tele = this.state.tele;
            sbo.cdo.email = this.state.email;
            sbo.cdo.msg = this.state.msg;
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
        }
        if(true) {
            var url = "/shop/delivery";
            this.props.navigate(url);
            return;
        }
    }
    not_min() {
        var brc = false;
        var minbelopp = 13000;
        var belopp = sbo.cart.belopp * 1;
        if(belopp < minbelopp) {
            brc = true;
        }
        return brc;
    }
    pb_show(typ) {
        var brc = false;
        if(typ == "min") brc = this.not_min();
        if(typ == "zero") brc = (sbo.cart.topay * 1) == 0 ? true : false;
        if(typ == "pay") {
            brc = !this.not_min();
            if(brc) brc = (sbo.cart.topay * 1) != 0 ? true : false;
        }
        return brc;
    }
    change_adress() {

    }
    error_confirm() {
        this.setState({ error: "", errormsg: "" });
        //this.setState({ info: "KOPPLAR UPP..." });
    }
  render() {

    return (
    <div className="web-app">

        <div className="wp flex-row px-5 py-3">
            <div className="flex">
            <div className="txt_18 cursor-pointer" onClick={() => this.back() }><FontAwesomeIcon icon="arrow-left" size="1x" /> Tillbaka</div>
            </div>
            <div className="txt_22">KASSA</div>
            <div className="flex"></div>
        </div>

        <div className="flex-col flex-center" style={ this.bg_error_style() }>

            <div className="flex-col py-5 px-5">
                <div className="txt_22">{ this.state.error }</div>
                <div className="txt_22">{ this.state.errormsg }</div>
            </div>
            <div className="flex-row">
                <div className="mpay-btn" onClick={() => this.error_confirm() }>STÄNG</div>
            </div>

        </div>
        <div className="flex-col flex-center" style={ this.bg_info_style() }>

            <div className="flex-col py-5 px-5">
                <div className="txt_22">{ this.state.info }</div>
            </div>

        </div>

        <div className="" style={ this.bg_style() }>
            <div className="dlt-body">
                <div className="flex-col py-5 px-5">

                    <div className="mpg">
                        <div className="flex-row">
                            <div className="txt_b16">Produkter</div>
                        </div>
                        <div className="flex-col px-2">
                            { sbo.cart.rows.map((crow, ckey) =>
                                <div key={ckey} className="flex-col py-2 cart-item" >
                                    <div className="flex-row" >
                                        <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                        <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.pris) } kr</div>
                                    </div>
                                    { this.pro_ina(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_16">- { utils.toLang(row.p0text) }</div>
                                            <div className="flex"></div>
                                        </div>
                                    )}
                                    { this.pro_ixa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_txa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_sxa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_dxa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    <div className="flex-row" >
                                        <div className="txt_16">Summa</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                                    </div>
                                    <div className="flex-row" >
                                        <div className="flex"></div>
                                        <FontAwesomeIcon icon="trash" size="lg" onClick={() => this.row_del(crow) } />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex-col px-2">
                            { Object.values(sbo.cart.pro).map((crow, ckey) =>
                                <div key={ckey} className="flex-col py-3 cart-item" >
                                    <div className="flex-row" >
                                        <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                        <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                        <div className="flex"></div>
                                        <div className="txt_14">{ utils.toNum(crow.nrof) } * </div>
                                        <div className="txt_14">{ utils.toPris(crow.price) } kr</div>
                                    </div>
                                    <div className="flex-row" >
                                        <FontAwesomeIcon icon="trash-can" className="red" size="lg" onClick={() => this.pro_del(crow) } />
                                        <div className="flex"></div>
                                        <div className="txt_16">Summa</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    <div className="flex-row">
                        <div className="txt_18">SUBTOTAL</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( sbo.cart.belopp ) } kr</div>
                    </div>
                    { sbo.cart.levbelopp != 0 ?
                        <div className="flex-row">
                            <div className="txt_14">Leveranskostnad</div>
                            <div className="flex"></div>
                            <div className="txt_b14">{ utils.toPris( sbo.cart.levbelopp ) } kr</div>
                        </div>
                        : null }

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    { !sbo.cart.discount || (sbo.cart.discount == 0) ?
                        <div className="">
                            <div className="flex-row">
                                <div className="txt_b16">ANGE RABATTKOD</div>
                            </div>
                            <div className="flex-row">
                                <Fr_Input col noflex cls="col-md-3 col-sm-6" className="text-left foodle-input" text={this.state.discount} getValue={(e)=> this.discount_change(e) } />
                                <div className="gray-btn col-md-3 col-sm-6" onClick={() => this.cart_rkod()}>LÄGG TILL</div>
                            </div>
                            { this.state.rabmsg ? <div className="text-danger">{ this.state.rabmsg }</div> : null }
                        </div>
                        : null }
                    { sbo.cart.discount > 0 ?
                        <div className="flex-row" onClick={() => this.discount_clear()}>
                            <div className="txt_18">AVGÅR RABATT</div>
                            <div className="flex"></div>
                            <div className="txt_b18">{ utils.toPris( sbo.cart.discount ) } kr</div>
                        </div>
                        : null }
                    <div className="flex-row">
                        <div className="txt_18">ATT BETALA</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( sbo.cart.topay ) } kr</div>
                    </div>
                </div>
            </div>

            <div className="flex-row py-3">
                <div className="flex"></div>
            </div>

            <div className="dlt-body">
                <div className="flex-col py-5 px-5">

                    <div className="flex-row">
                        <div className="txt_b16">Leverans uppgifter</div>
                    </div>
                    { (sbo.cart.levtyp == "deliver") ?
                        <div className="flex-col py-3 box-lev">
                            <div className="flex-row">
                                <div className="txt_14">HEMLEVERANS</div>
                                <div className="flex"></div>
                                <div className="txt_14">Slot: { utils.toTid( sbo.cart.slot ) }</div>
                            </div>
                            <div className="flex-row">
                                <div className="txt_14">{ sbo.cdo.loco.street } { sbo.cdo.loco.snr }</div>
                                <div className="flex"></div>
                                <div className="txt_14 hide" onClick={() => this.change_adress() }>Ändra</div>
                            </div>
                            <div className="flex-row">
                                <div className="txt_14">{ sbo.cdo.loco.zip } { sbo.cdo.loco.city }</div>
                                <div className="flex"></div>
                                <div className="txt_14"></div>
                            </div>
                        </div>
                    : null }
                    { (sbo.cart.levtyp == "pickup") ?
                        <div className="lev-box flex-col">
                            <div className="txt_14">AVHÄMTNING</div>
                            <div className="fbold">{ sbo.cdo.unit.city }</div>
                            <div className="">{ sbo.cdo.unit.adress }</div>
                        </div>
                    : null }

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    <div className="flex-row">
                        <div className="txt_b16">Era uppgifter</div>
                    </div>
                    <div className="flex-row row">
                        <Fr_Input col noflex label="Namn" cls="col-md-6 col-sm-12" className="text-left foodle-input" text={this.state.fnamn} getValue={(e)=> this.val_save("fnamn", e) } />
                        <Fr_Input col noflex label="Efternamn" cls="col-md-6 col-sm-12" className="text-left foodle-input" text={this.state.enamn} getValue={(e)=> this.val_save("enamn", e) } />
                    </div>
                    <div className="flex-row row">
                        <Fr_Input col noflex label="Telefonnr" cls="col-md-6 col-sm-12" className="text-left foodle-input" text={this.state.tele} getValue={(e)=> this.val_save("tele", e) } />
                        <Fr_Input col noflex label="Epost" cls="col-md-6 col-sm-12" className="text-left foodle-input" text={this.state.email} getValue={(e)=> this.val_save("email", e) } />
                    </div>
                    <div className="flex-row row hide">
                        <Fr_Input col noflex label="Meddelande (Valfritt)" cls="col-md-12 col-sm-12" className="text-left foodle-input" text={this.state.msg} getValue={(e)=> this.val_save("msg", e) } />
                    </div>
                    <div className="flex-col row">
                        <span className="header">Meddelande (Valfritt)</span>
                        <textarea className="text-left col-md-12 col-sm-12" maxLength="512" rows="5" value={this.state.msg} onChange={(e) => this.val_anm_save("msg", e)}></textarea>
                    </div>

                </div>

                <div className="flex-row py-3">
                    <div className="flex">Genom att du fyller i dessa uppgifter är du införstådd med att vi lagrar dina personuppgifter i enlighet med vår <a href="https://foodle.se/rules">personuppgiftspolicy</a>.</div>
                </div>

                { this.pb_show("min") ?
                    <div className="flex-row">
                        <div className="mpay-btn" onClick={() => this.cart_abort()}>MINI BELOPP EJ UPPNÅTT</div>
                    </div>
                : null }
                { this.pb_show("zero") ?
                    <div className="flex-row">
                        <div className="mpay-btn" onClick={() => this.cart_pay() }>BESTÄLL</div>
                    </div>
                    : null }
                { this.pb_show("pay") ?
                    <div className="flex-row">
                        <div className="mpay-btn hide" onClick={() => this.cart_order_save() }>SPARA</div>
                        <div className="mpay-btn" onClick={() => this.cart_pay() }>VÄLJ BETALSÄTT</div>
                    </div>
                    : null }
                <div className="flex-row py-3">
                    <div className="flex"></div>
                </div>

            </div>
        </div>
        { this.state.wnds.cartadyen ? <Foodle_Adyen wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.cartonfleet ? <Foodle_Onfleet wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.cartpay ? <Foodle_Pay wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.cartpayok ? <Foodle_PayOk wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.cartpayfail ? <Foodle_PayFail wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Foodle_Kassa));
