import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../../lib/srs";
import * as utils from "../../assets/utils";
import Order_Prd from "./prd";
import sbo from "../../shop/sbo";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Order_Grp extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.wnds = {};

    this.state.gid = "";
    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mpa = [];
    this.state.mgo = {};
    this.state.mpo = {};
    this.state.pro = {};

    //this.state.lat = "57.5079875";
    //this.state.lon = "11.9407502";
    this.state.lat = "57.506280";
    this.state.lon = "12.059960";

    this.state.gid = "001";
    if(sbo.cdo && sbo.cdo.gid) this.state.gid = sbo.cdo.gid;
    this.state.ktxt = "";
    if(this.state.gid == "001") this.state.ktxt = "BURGER";
    if(this.state.gid == "002") this.state.ktxt = "PIZZA";
    if(this.state.gid == "003") this.state.ktxt = "PASTA";
    if(this.state.gid == "004") this.state.ktxt = "SUSHI";
    if(this.state.gid == "005") this.state.ktxt = "TACO";
    if(this.state.gid == "006") this.state.ktxt = "KEBAB";
    if(this.state.gid == "007") this.state.ktxt = "WOK";
    if(this.state.gid == "008") this.state.ktxt = "SALLAD";
    if(this.state.gid == "009") this.state.ktxt = "HUSMAN";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.dsi_mg();
  }

  dlg_style() {
    var css = {};
    //css.height = window.innerHeight;
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    //css.background = "#f43b24";
    //css.color = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  nrof_style(row) {
    var css = {};
    if(!row.nrof || (row.nrof < 1)) {
      css.display = "none";
    }
    return css;
  }
  mpo_style(row) {
    var css = {};
    css.color = "#ddd";
    css.fontSize = "26px";
    if(this.state.pro[row.plu]) {
      css.color = "#fee260";
    }
    return css;
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }
  wnd_close = () => {
    if(this.props.wnds.cb) {
      var rsp = {};
      rsp.ok = "999";
      this.props.wnds.cb(this.props.ctx, rsp);
    }
    else this.props.ctx.setState({ wnds: {} });
  };
  dsi_mg() {
    var self = this;

    this.setState({ txt:"Laddar grupp", mga: [] });

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_chan";
    //prm.mnuid = gid;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
        .then(function(ret){
          //alert(JSON.stringify(ret));

          var mga = ret.rca;
          //var mgo = mga[0];
          var mgo = mga.filter(row => {
            return row.gid === self.state.gid;
          })
          var ix = 0;
          //var mgo = null;
          var mgo = mga[ix];
          var keys = Object.keys(mga);
          for(var key of keys) {
            var row = mga[key];
            if(row.gid == self.state.gid) {
              ix = key;
              mgo = row;
            }
          }
          //var ix = mga.indexOf(mgo);
          //alert(JSON.stringify(mgo));
          self.setState({ txt: "", mga: mga, gid: mgo.gid, mgo: mgo, selix: ix }, function() {
            //self.scroll_init();
            self.dsi_mp(mgo.gid);
          });
          //self.syncTb("mnaTable");
          //self.mg_open(mgo);
        })
        .catch(function(e){
          var txt = JSON.stringify(e);
        });
  }
  dsi_mp(gid) {
    var self = this;

    this.setState({ gid: gid, mpa: [] });

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_mp.mp_web";
    prm.mnuid = gid;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
        .then(function(ret){
          //alert(JSON.stringify(ret));

          self.setState({ mpa: ret.rca });
          //self.syncTb("mnaTable");
        })
        .catch(function(e){
          var txt = JSON.stringify(e);
        });
  }
  plu_sel(mpo) {
    //sbo.cdo.gid = mgo.gid;
    //var url = "";
    //url = url + "/shop/menu/grp";
    //this.props.navigate(url);
    var wnds = {};
    wnds.prdopen = true;
    wnds.gid = this.state.gid;
    wnds.pbo = mpo;
    wnds.cb = this.plu_cb;
    this.setState({ wnds: wnds });
  };

  plu_cb(ctx, rsp) {
    if(rsp.ok == "000") {
      ctx.setState({ wnds: {} });
      if(ctx.props.wnds.cb) {
        var rsp = {};
        rsp.ok = "000";
        ctx.props.wnds.cb(ctx.props.ctx, rsp);
      }
      else ctx.props.ctx.setState({ wnds: {} });
      return;
    }
    ctx.setState({ wnds: {} });
  }
  plutxt(txt) {
    var txt1 = utils.toLang( txt );
    txt1 = txt1.substring(0,15);
    return txt1;
  }

  render() {

    return (
        <div className="mx_base">
          <div className="m2_backdrop" />
          <div className="md_full flex-col" style={ this.dlg_style() } >
            <div className="mx_content flex">
              <div className="mx-close-btn" onClick={() => this.wnd_close() }><FontAwesomeIcon icon="xmark" size="lg" /></div>

              <div className="flex-col py-3 px-3">
                <div className="flex-row">
                  { this.state.ktxt }
                </div>
              </div>
              <div className="divider_orange"></div>

              <div className="mx-body darkgrey">

                <div className="flex-center flex">

                  { this.state.mpa.map((mpo, key) =>
                      <div key={key} className="row plurow" >
                        <div className="" >
                          <div className="flex-row text-left" >
                            <div className="plutxt">{ this.plutxt( mpo.text ) }</div>
                            <div className="flex"></div>

                            <div className="flex-row px-2" style={ this.nrof_style(mpo) }>
                              <FontAwesomeIcon icon="circle-minus" style={ this.mpo_style(mpo) } onClick={() => this.tbo_sub(mpo, "D") } />
                              <div className="txt_18 px-1">{ utils.toNum( mpo.nrof ) }</div>
                              <FontAwesomeIcon icon="circle-plus" style={ this.mpo_style(mpo) } onClick={() => this.tbo_add(mpo, "D") } />
                            </div>

                            <div className="plutxt txt_24">{ utils.toPris(mpo.price) } kr</div>
                          </div>
                          <div className="flex-col" >
                            <div className="text-left pludesc txtwrap">{ utils.toLang(mpo.desc) }</div>
                          </div>
                          <div className="flex-row" >
                            <div className="flex"></div>
                            <div className="mp-btn" onClick={() => this.plu_sel(mpo) }>LÄGG TILL</div>
                          </div>
                        </div>

                      </div>
                  )}
                  </div>
              </div>
            </div>

          </div>
          { this.state.wnds.prdopen ? <Order_Prd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        </div>
  );
}
}

export default observer(Order_Grp);
