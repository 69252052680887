import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import './css/mobs.css';

import Tiden from "../lib/ui/tiden";

import Mobs_Home from "./home";
import Mobs_Order from "./order";

class Foodle_Mobs extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  render() {

    return (
        <Routes>
          <Route path="home" element={ <Mobs_Home store={this.store} />} />
          <Route path="order/*" element={ <Mobs_Order store={this.store} />} />
            <Route path="*" element={ <Mobs_Home store={this.store} />} />
        </Routes>
  );
}
}
export default observer(Foodle_Mobs);
