import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../lib/ui/tiden";
import FoodleLogo from "../img/foodle-logo.svg";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Pitch extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.pitchcode = "";
    this.state.pitchok = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.pos_init();
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  pg_style() {
    var css = {};
    css.color = "#fee260";
    css.font = "Colby";
    css.padding = "0px";
    css.height = "100%";
    return css;
  }
  ctx_style() {
    var css = {};
    css.width = "450px";
    return css;
  }
  sign() {
    var signatur = this.state.signatur;
    //alert(signatur);
    if(signatur == "n1R2g3") {
      this.setState({signok: true});
    }
  }
  setCode (e) {
    var pitchcode = this.state.pitchcode;
    //var pitchok = this.state.pitchok;
    var pitchok = false;
    //tbo[name] = value;
    var code = e.target.value;
    //signatur = signatur + sign;
    if(code == "2023") pitchok = true;
    this.setState({pitchcode: code, pitchok: pitchok}, function () {
    });
  }

  showPdf (pdf) {
    /*
    var rawbuf = ret.data;
    var file = new Blob([(rawbuf)], {type: 'application/octet-stream'});
    var fileURL = window.URL.createObjectURL(file);
    //$scope.fil_object = $sce.trustAsResourceUrl(fileURL);
    var a = document.createElement('a');
    a.href = fileURL;
    //var filename = req.filename;
    var filename = "";
    //if(filename.length < 1) filename = "webfile.txt";
    //if(filename.length < 1) filename = prm.fdat + "-" + prm.tdat + ".xlsx";
    if(filename.length < 1) filename = "stat.xlsx";
    a.download = filename;
    document.body.appendChild(a);
    a.click();
     */
  }

  render() {

    return (
    <div className="web-app">

      { ( this.state.pitchok == true ) ?
          <div className="text-center py-3" style={ this.pg_style() }>
            <object data="https://fx.svepos.se/foodle/foodlepresentation.pdf" type="application/pdf" width="100%" height="100%">
              Presentation
            </object>
          </div>
      :

        <div className="page foodle-bg" style={ this.pg_style() }>

          <div className="flex-row flex-center py-5">
            <div style={ this.ctx_style() }>

              <div className="text-center f80 py-3">
                <div className=""><img src={FoodleLogo} className="cursor-pointer" alt="Foodle"  onClick={() => this.fknGo("/") } /></div>
              </div>
              <div className="text-center font-bold font-colby f80 py-3">
                PRESENTATION
              </div>
              <div className="text-center font-bold font-colby f80 py-3">
                ANGE ER KOD
              </div>
              <div className="flex-row py-3">
                <input type="password" className="foodle-input text-center flex" value={ this.state.pitchcode } onChange={ (e) => this.setCode(e) } />
              </div>
              <div className="h80"></div>

           <div className="h80"></div>

            </div>
          </div>

        </div>
      }
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_Pitch);
