import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../../lib/srs";
import * as utils from "../../assets/utils";
import sbo from "../../shop/sbo";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Order_Sides extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.zip = "";
    this.state.adress = "";
    this.state.loco = {};

    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mgo = {};

    //this.state.lat = "57.5079875";
    //this.state.lon = "11.9407502";
    this.state.lat = "57.506280";
    this.state.lon = "12.059960";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  bg_full_style() {
    var css = {};
    css.height = window.innerHeight;
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    //css.background = "#f43b24";
    //css.color = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }

  render() {

    return (
        <div className="web-app">

          <div className="flex-row px-3">
            <div className="txt_22">BELOPP</div>
            <div className="flex"></div>
            <div className="txt_b22">{ utils.toPris(sbo.cart.belopp) }</div>
          </div>
          <div className="divider_orange"></div>

          <section className="wnd-fill flex-col" style={ this.bg_style() }>
            <div className="text-center font-bold font-colby f80 py-3">
              SIDES
            </div>

            <div className="flex-center flex">

            </div>
          </section>

        </div>
  );
}
}

export default observer(Order_Sides);
