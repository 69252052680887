import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../../lib/srs";
import * as utils from "../../assets/utils";
import Order_Prd from "./prd";
import sbo from "../../shop/sbo";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Order_Kvitto extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.wnds = {};

    this.state.gid = "";
    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mpa = [];
    this.state.mgo = {};
    this.state.mpo = {};
    this.state.pro = {};

    //this.state.lat = "57.5079875";
    //this.state.lon = "11.9407502";
    this.state.lat = "57.506280";
    this.state.lon = "12.059960";

    this.state.gid = "001";
    if(sbo.cdo && sbo.cdo.gid) this.state.gid = sbo.cdo.gid;
    this.state.ktxt = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.dsi_mg();
  }

  dlg_style() {
    var css = {};
    //css.height = window.innerHeight;
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    //css.background = "#f43b24";
    //css.color = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  nrof_style(row) {
    var css = {};
    if(!row.nrof || (row.nrof < 1)) {
      css.display = "none";
    }
    return css;
  }
  mpo_style(row) {
    var css = {};
    css.color = "#ddd";
    css.fontSize = "26px";
    if(this.state.pro[row.plu]) {
      css.color = "#fee260";
    }
    return css;
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }
  wnd_close = () => {
    if(this.props.wnds.cb) {
      var rsp = {};
      rsp.ok = "999";
      this.props.wnds.cb(this.props.ctx, rsp);
    }
    else this.props.ctx.setState({ wnds: {} });
  };
  kvitto_close() {
    if(this.props.wnds.cb) {
      sbo.cart.notanr = "";
      sbo.cart.belopp = "0";
      sbo.cart.rows = [];
      sessionStorage.setItem("sbo", JSON.stringify(sbo));
      var rsp = {};
      rsp.ok = "000";
      this.props.wnds.cb(this.props.ctx, rsp);
    }
    else this.props.ctx.setState({ wnds: {} });
  }

  render() {

    return (
        <div className="mx_base">
          <div className="m2_backdrop" />
          <div className="md_full flex-col" style={ this.dlg_style() } >
            <div className="mx_content flex">
              <div className="mx-close-btn" onClick={() => this.wnd_close() }><FontAwesomeIcon icon="xmark" size="lg" /></div>

              <div className="flex-col py-3 px-3">
                <div className="flex-row">
                  <div className="txt_18">KVITTO</div>
                  <div className="flex"></div>
                </div>
              </div>

              <div className="divider_orange"></div>

              <div className="flex-row">
                <div className="txt_18">BETALT</div>
                <div className="flex"></div>
                <div className="txt_18">{ utils.toPris(sbo.cart.belopp) }</div>
              </div>
              <div className="flex-row">
                <div className="txt_18">NOTANR</div>
                <div className="flex"></div>
                <div className="txt_18">{ utils.toNum(sbo.cart.notanr) }</div>
              </div>

              <div className="flex-col flex-center flex">
                <div className="mfull-btn" onClick={() => this.kvitto_close()}>STÄNG</div>
              </div>
            </div>

          </div>
        </div>
  );
}
}

export default observer(Order_Kvitto);
