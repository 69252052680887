import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './css/shop.css';

import Tiden from "../lib/ui/tiden";
import * as utils from "../assets/utils";
import sbo from "./sbo";

import Shop_Menu from "./menu";
import Shop_Kitchens from "./kitchens";
import Shop_Units from "./units";
import Shop_Delivery from "./delivery";
import Shop_Kassa from "./kassa";
import Shop_Direct from "./direct";
import Shop_Payment from "./payment";
import Shop_Paid from "./paid";
import Shop_Order from "./order";
import FoodleLogo from "../img/foodle-logo.svg";
import {withRouter} from "../lib/react/withRouter";
import Shop_Cart from "./cart";
import {faCartShoppingFast} from "@fortawesome/pro-regular-svg-icons";

class Shop extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    //this.pos = props.store.pos;
    this.state = {};
      this.state.top = 1;
      this.state.info = "";
      this.state.infotxt = "";
      this.state.wnds = {};
      this.state.mwnd = 0;

      var gbg = ["43437", "42943" ];
      var nkp = ["61100", "61102", "61103", "61104", "61105", "61107", "61108", "61110", "61111", "61120", "61122", "61123", "61124", "61125", "61126", "61127", "61129"
          ,"61130", "61131", "61132", "61133", "61134", "61135", "61136", "61137", "61138", "61139", "61144", "61145", "61146", "61147", "61150", "61155", "61156", "61157", "61160", "61161", "61162", "61163", "61164", "61165", "61166", "61167", "61168"
          , "61179", "61181", "61182", "61183", "61184", "61185", "61186", "61187", "61188", "61189", "61300" ];
      var dan = ["18200", "18202", "18203", "18204", "18205", "18206", "18207", "18211", "18212", "18213", "18214", "18215", "18216", "18217", "18220", "18230", "18231", "18232", "18233", "18234", "18235", "18236", "18237", "18238", "18239", "18245", "18246", "18247", "18248", "18249"
          , "18250", "18252", "18253", "18254", "18255", "18256", "18257", "18260", "18261", "18262", "18263", "18264", "18265", "18266", "18267", "18268", "18269", "18273", "18274", "18275", "18276", "18277", "18278", "18279", "18282", "18285", "18287", "18288", "18291" ];
      var taby = ["18300", "18301", "18302", "18303", "18304", "18311", "18312", "18313", "18314", "18315", "18316", "18320", "18321", "18322", "18323", "18325", "18326", "18330", "18331", "18332", "18333", "18334", "18335", "18336", "18337", "18338", "18339"
          , "18341", "18347", "18348", "18349", "18350", "18351", "18352", "18353", "18354", "18355", "18356", "18357", "18358", "18359", "18360", "18361", "18362", "18363", "18364", "18365", "18366", "18367", "18368", "18369", "18370"
          , "18371", "18372", "18374", "18375", "18376", "18377", "18378", "18379", "18380", "18383", "18700", "18705", "18720", "18726", "18728", "18729", "18730", "18731", "18732", "18733", "18734", "18735", "18736", "18737", "18738", "18740"
          , "18741", "18742", "18743", "18744", "18745", "18746", "18747", "18748", "18749", "18750", "18751", "18752", "18753", "18754", "18760", "18761", "18762", "18763", "18764", "18765", "18766", "18767", "18768", "18769"];
      var nac = [ "10523", "13100", "13102", "13104", "13105", "13106", "13107", "13108", "13120", "13121", "13122", "13124", "13125", "13126", "13127", "13128", "13130", "13131", "13132", "13133", "13134", "13135", "13136", "13137", "13138", "13139"
          , "13140", "13141", "13142", "13144", "13145", "13146", "13147", "13148", "13149", "13150", "13151", "13152", "13153", "13154", "13155", "13156", "13157", "13160", "13161", "13165", "13171", "13172", "13173", "13174", "13175", "13181", "13183", "13185", "13192"
          , "13300", "13380", "13381", "13383" ];
      var hbs = [ "12030", "12031", "12032", "12061", "12062", "12063", "12064", "12065", "12066", "12067", "12068", "12069", "12070", "12071", "12078", "12079" ];
      var hbh = [ "12002", "12006", "12007", "12008", "12009", "12047", "12048", "12105", "12106", "12107", "12114", "12115", "12116", "12117", "12118", "12120", "12121", "12122", "12123", "12125", "12126", "12127", "12128", "12129"
          , "12130", "12131", "12132", "12133", "12135", "12136", "12137", "12138", "12139", "12140", "12143", "12144", "12145", "12146", "12147", "12148", "12149", "12150", "12151", "12152", "12153", "12154", "12155"
          , "12162", "12163", "12177", "12231", "12232", "12234", "12262" ];
      var hud = [ "14100", "14104", "14118", "14120", "14121", "14122", "14123", "14124", "14125", "14126", "14127", "14128", "14130", "14131", "14132", "14133", "14135", "14143"
          , "14144", "14147", "14148", "14152", "14153", "14154", "14155", "14156", "14157", "14159", "14161", "14162", "14163", "14168", "14169"
          , "14181", "14183", "14184", "14185", "14187", "14188", "14189", "14650" ];
      var hud2 = [ "14134", "14138", "14139", "14140", "14141", "14142", "14145", "14146", "14149", "14150", "14151", "14158", "14160", "14173", "14192" ];

      this.state.unit = "00";
      sbo.cdo.zipok = 0;
      sbo.cdo.unit = {};
      sbo.cdo.unit.id = "BAS";
      sbo.cdo.unit.sid = "S0003202";
      sbo.cdo.unit.ftid = "1130";
      //sbo.cdo.unit.ftid = "1200";
      sbo.cdo.unit.ttid = "2100";
      sbo.cdo.unit.adress = "Svärdvägen 23, 182 33 Danderyd";
      sbo.cdo.unit.city = "Danderyd";

      var zip = "";
      if(sbo.cdo.zip) {
          zip = sbo.cdo.zip;
          zip = zip.replaceAll(" ", "");
          //alert(zip);
          if(gbg.includes(zip) == true) {
          //if(zip.substr(0,1) == "4") {
              this.state.unit = "09";
              sbo.cdo.zipok = 1;
              sbo.cdo.unit.id = "GBG";
              sbo.cdo.unit.sid = "S0000300";
              sbo.cdo.unit.ftid = "1200";
              //sbo.cdo.unit.ftid = "1130";
              sbo.cdo.unit.ttid = "2300";
              sbo.cdo.unit.adress = "Energigatan 9";
              sbo.cdo.unit.city = "Göteborg";
          }
          if((dan.includes(zip) == true) || (taby.includes(zip) == true)) {
              //if(zip.substr(0,1) == "1") {
              this.state.unit = "02";
              sbo.cdo.zipok = 1;
              sbo.cdo.unit.id = "DAN";
              sbo.cdo.unit.sid = "S0003202";
              //sbo.cdo.unit.ftid = "1200";
              sbo.cdo.unit.ftid = "1130";
              sbo.cdo.unit.ttid = "2100";
              sbo.cdo.unit.adress = "Svärdvägen 23, 182 33 Danderyd";
              sbo.cdo.unit.city = "Danderyd";
          }
/*          if(nkp.includes(zip) == true) {
          //if(zip.substr(0,1) == "6") {
              this.state.unit = "01";
              sbo.cdo.zipok = 1;
              sbo.cdo.unit.id = "NKP";
              sbo.cdo.unit.sid = "S0003201";
              //sbo.cdo.unit.ftid = "1200";
              sbo.cdo.unit.ftid = "1130";
              sbo.cdo.unit.ttid = "2100";
              sbo.cdo.unit.adress = "Sankt Annegatan 2, 611 34 Nyköping";
              sbo.cdo.unit.city = "Nyköping";
          }
          if((nac.includes(zip) == true) || (hbs.includes(zip) == true) || (hbh.includes(zip) == true)) {
              //if(zip.substr(0,1) == "1") {
              this.state.unit = "03";
              sbo.cdo.zipok = 1;
              sbo.cdo.unit.id = "SIC";
              sbo.cdo.unit.sid = "S0003203";
              //sbo.cdo.unit.ftid = "1200";
              sbo.cdo.unit.ftid = "1130";
              sbo.cdo.unit.ttid = "2100";
              sbo.cdo.unit.adress = "Fannys väg 5, 131 54 Nacka";
              sbo.cdo.unit.city = "Sickla";
          }
 */
          if((hud.includes(zip) == true) || (hud2.includes(zip) == true)) {
              //if(zip.substr(0,1) == "1") {
              this.state.unit = "04";
              sbo.cdo.zipok = 1;
              sbo.cdo.unit.id = "HUD";
              sbo.cdo.unit.sid = "S0003204";
              //sbo.cdo.unit.ftid = "1200";
              sbo.cdo.unit.ftid = "1130";
              sbo.cdo.unit.ttid = "2100";
              sbo.cdo.unit.adress = "Novavägen 17, 141 44 Huddinge";
              sbo.cdo.unit.city = "Huddinge";
          }
      }
      sessionStorage.setItem("sbo", JSON.stringify(sbo));
      //alert(JSON.stringify(sbo.cdo.unit));
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    topr_class() {
        var cls = "";
        cls = "fmv0";
        if(this.state.top == 0) {
            cls = cls + " fmv1";
        }
        if(window.scrollY > 100) {
            //cls = cls + " fmv1";
        }
        return cls;
    }
    top_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        //if(this.state.top == 0) css.height = 35;
        return css;
    }
    topr_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        if(this.state.top == 0) {
            //css.display = "none";
        }
        css.display = "none";
        return css;
    }
    foot_icon_style() {
        var css = {};
        css.color = "#fff";
        return css;
    }
  fknGo(url) {
    //props.history.push(row.url);
    this.props.navigate(url);
   };

    cart_open() {
        if(false) {
            var url = "delivery";
            this.props.navigate(url);
            return;
        }
        var self = this;
        var wnds = {};
        wnds.cartopen = true;
        wnds.cb = self.cart_cb;
        this.setState({ wnds: wnds });
    }
    cart_cb(ctx, rsp) {
        //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            ctx.setState({ wnds: {} });
            //var url = "direct";
            var url = "delivery";
            ctx.props.navigate(url);
            return;
        }
        ctx.setState({ wnds: {} });
    }

    mobile_mopen() {
        var mwnd = this.state.mwnd;
        if(mwnd == 0) mwnd = 1;
        else mwnd = 0;
        this.setState({ mwnd: mwnd });
    }
    mnu_open(url) {
        //alert(JSON.stringify(this.props));
        this.setState({ mwnd: 0 });
        this.props.navigate(url);
    }
    bg_full_style() {
        var css = {};
        //css.height = window.innerHeight;
        css.overflow = "auto";
        return css;
    }
    mobile_wnd_style() {
        var css = {};
        css.top = 44;
        css.height = window.innerHeight - 44;
        //css.overflow = "auto";
        if (this.state.top == 1) {
            css.top = 94;
            css.height = window.innerHeight - 94;
        }
        if (this.state.mwnd == 0) css.display = "none";
        return css;
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 80) {
            this.setState({top: 0})
            this.setState({info: 'small'})
        } else {
            if (body.scrollTop < 1) {
                this.setState({top: 1})
                this.setState({info: 'big:'+body.scrollTop})
            }
        }

        if (false) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;
        }
    }

    get_badge() {
      var nrr = 0;
        if(sbo.cart.rows) nrr = nrr + sbo.cart.rows.length;
        if(sbo.cart.pro) nrr = nrr + Object.keys(sbo.cart.pro).length;
      return nrr;
    }

  render() {

    return (
        <div className="web-app">
        <div className="web-top foodle-top" style={ this.top_style() }>
            <div className="wp">
                <div className={ this.topr_class() } style={ this.topr_style() }>
                    <div className="text-center text-white">Våra öppettider är mån-fre 11.30 - 21.00 & lör-sön 12.00 - 21.00 (Ni kan alltid lägga er order i förväg)</div>
                    <div className="flex-row py-3"></div>
                </div>
                <div className="flex-row foodle-mmenu">
                    <div className=""><img src={FoodleLogo} className="cursor-pointer" alt="Foodle" onClick={() => this.fknGo("/") } /></div>
                    <div className="flex text-left text-white txt_16 px-3">{ this.state.infotxt }</div>
                    <ul className="foodle-horiz-ul px-2">
                        <li className="py-1 text-white cursor-pointer" onClick={() => this.mnu_open("kitchens")}>Våra kök</li>
                        <li className="py-1 text-white cursor-pointer" onClick={() => this.mnu_open("/about")}>Om Foodle</li>
                        <li className="py-1 text-white cursor-pointer" onClick={() => this.mnu_open("/contact")}>Kontakt</li>
                    </ul>
                    <div className="cart-btn flex-row" onClick={() => this.cart_open() }>
                        <div className="flex"></div>
                        <div className="flex-row text-center">
                            <div className="txt_b16 px-1">{ utils.toPris(sbo.cart.belopp) } kr</div>
                            <FontAwesomeIcon className="cart-btn-icon" icon="briefcase" size="1x" color="black" />
                        </div>
                        <div className="flex"></div>
                        <span className="cart-badge">{ this.get_badge() }</span>
                    </div>
                </div>
                <div className="flex-row foodle-mobile">
                    <div className=""><img src={FoodleLogo} className="" alt="Foodle" onClick={() => this.fknGo("/") } /></div>
                    <div className="flex"></div>
                    <FontAwesomeIcon className="" icon="bars" size="2x" color="white" onClick={() => this.mobile_mopen() } />
                </div>
            </div>
        </div>

            <div className="foodle-mobile-mwnd" style={ this.mobile_wnd_style() }>
                <div className="foodle-mobile-btn" onClick={() => this.mobile_mopen() }><FontAwesomeIcon icon="xmark" size="lg" /></div>

                <div className="flex-col flex" style={{ height: "100%", fontSize: "24px", padding: "50px 0px", position: "relative" }}>

                    <div className="flex-row flex-center py-3">
                        <div className="text-white cursor-pointer" onClick={() => this.mnu_open("/")}>Hem</div>
                    </div>
                    <div className="flex-row flex-center py-3">
                        <div className="text-white cursor-pointer" onClick={() => this.mnu_open("/shop/kitchens")}>Våra kök</div>
                    </div>
                    <div className="flex-row flex-center py-3">
                        <div className="text-white cursor-pointer" onClick={() => this.mnu_open("/about")}>Om Foodle</div>
                    </div>
                    <div className="flex-row flex-center py-3">
                        <div className="text-white cursor-pointer" onClick={() => this.mnu_open("/contact")}>Kontakt</div>
                    </div>

                </div>

            </div>
            <div className="fab-btn fab-btn_shadow" onClick={() => this.cart_open() }>
                <FontAwesomeIcon icon={faCartShoppingFast} size="1x" />
                <span className="cart-badge">{ this.get_badge() }</span>
            </div>

        <div className="web-body flex" id="web-body" style={ this.bg_full_style() } onScroll={ (event) => this.onScroll(event) }>
        <Routes>
          <Route path="menu/*" element={ <Shop_Menu store={this.store} />} />
          <Route path="kitchens" element={ <Shop_Kitchens store={this.store} />} />
          <Route path="units" element={ <Shop_Units store={this.store} />} />
          <Route path="delivery" element={ <Shop_Delivery store={this.store} />} />
          <Route path="kassa" element={ <Shop_Kassa store={this.store} />} />
          <Route path="direct" element={ <Shop_Direct store={this.store} />} />
            <Route path="payment" element={ <Shop_Payment store={this.store} />} />
            <Route path="paid" element={ <Shop_Paid store={this.store} />} />
            <Route path="order" element={ <Shop_Order store={this.store} />} />
          <Route path="*" element={ <Shop_Kitchens store={this.store} />} />
        </Routes>
        </div>

        <div className="foodle-foot hide">
            <div className="py-3 wp">
                <div className="row">
                    <div className="col-md-3 col-sm-12 foodle_bottom_logo">
                        <img src={FoodleLogo} className="" alt="Foodle" />
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <ul className="foodle-ul">
                            <li className="py-1"><a href="/about" className="text-white">OM FOODLE</a></li>
                            <li className="py-1"><a href="/faq" className="text-white" aria-current="page">FAQ</a></li>
                            <li className="py-1"><a href="/contact" className="text-white">KONTAKT</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <ul className="foodle-ul py-2">
                            <li className="py-1"><a href="/rules" className="text-white">REGLER OCH VILLKOR</a></li>
                            <li className="py-1"><a href="/cookies" className="text-white">COOKIES</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <ul className="foodle-ul py-2">
                            <li className="py-1">
                                <FontAwesomeIcon icon={['fab', 'facebook-f']} size="1x" style={ this.foot_icon_style() } />
                                <a href="/fb" className="text-white px-2">FACEBOOK</a>
                            </li>
                            <li className="py-1">
                                <FontAwesomeIcon icon={['fab', 'instagram']} size="1x" style={ this.foot_icon_style() } />
                                <a href="/insta" className="text-white px-2">INSTAGRAM</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        { this.state.wnds.cartopen ? <Shop_Cart wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
  </div>
  );
}
}
export default withRouter(observer(Shop));
