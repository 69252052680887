import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../lib/srs";
import sbo from './sbo.js'
import * as utils from "../assets/utils";

import {withRouter} from "../lib/react/withRouter";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Payment extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.dropinRef = React.createRef();

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.loco = {};
      this.state.zip = "";
      this.state.adress = "";
      this.state.discount = "";
      this.state.error = "";
      this.state.errormsg = "";
      this.state.info = "";
      this.state.wnds = {};

      this.state.response = "";

      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }

      //if(!sbo.cdo) sbo.cdo = {};
      this.state.tstmode = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

      if(this.state.tstmode) this.adyen_test_pay();
      else this.adyen_pay();
  }

    adyen_test_pay() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = sbo.cdo.unit.sid;
        prm.req = "vcm.adyen.tst_session";
        prm.amount = sbo.cart.topay;
        prm.sbo = sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok == "000") {
                    self.setState({ response: ret.response }, function() {
                        self.adyen_test_init();
                    });
                }
                //self.setState({ mpa: ret.rca });
                //self.syncTb("mnaTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    async adyen_test_init() {
      var self = this;
        const configuration = {
            environment: 'test', // Change to 'live' for the live environment.
            clientKey: 'test_EQ3D27XBCRFDZITNLI6VMTEPLMDJOGZQ', // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
            session: {
                id: this.state.response.id, // Unique identifier for the payment session.
                sessionData: this.state.response.sessionData // The payment session data.
            },
            onPaymentCompleted: (result, component) => {
                alert(JSON.stringify(result));
                console.info(result, component);
                if(result.resultCode == "Authorised") {
                    //self.cart_adyen_pay(result);
                    self.cart_adyen_result(result);
                }
                else {
                    setTimeout(function(){
                        //self.adyen_pay();
                        var url = "/shop/kassa";
                        self.props.navigate(url);
                    }, 2000);
                }
                return;
            },
            onError: (error, component) => {
                //alert(JSON.stringify(error));
                console.error(error.name, error.message, error.stack, component);
            },
            // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
            // For example, this is 3D Secure configuration for cards:
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    billingAddressRequired: false
                }
            },
            locale: "sv-SE"
        };
        //alert(JSON.stringify(configuration));

        const checkout = await AdyenCheckout(configuration);
        const dropinComponent = checkout.create('dropin').mount("#dropin-container");
        //const dropinComponent = checkout.create('dropin').mount(this.dropinRef);
    }
    adyen_pay() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = sbo.cdo.unit.sid;
        prm.req = "vcm.adyen.pay_session";
        prm.amount = sbo.cart.topay;
        prm.sbo = sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok == "000") {
                    self.setState({ response: ret.response }, function() {
                        self.adyen_live_init();
                    });
                }
                //self.setState({ mpa: ret.rca });
                //self.syncTb("mnaTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    async adyen_live_init() {
        var self = this;
        const configuration = {
            environment: 'live', // Change to 'live' for the live environment.
            clientKey: 'live_HYAWNYLAAJAKPDS5B4DKK3NZBEMMZTAP', // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
            session: {
                id: this.state.response.id, // Unique identifier for the payment session.
                sessionData: this.state.response.sessionData // The payment session data.
            },
            onPaymentCompleted: (result, component) => {
                //alert(JSON.stringify(result));
                //console.info(result, component);
                self.cart_adyen_result(result);
                return;
            },
            onError: (error, component) => {
                //alert(JSON.stringify(error));
                console.error(error.name, error.message, error.stack, component);
            },
            // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
            // For example, this is 3D Secure configuration for cards:
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    billingAddressRequired: false
                }
            },
            locale: "sv-SE"
        };

        const checkout = await AdyenCheckout(configuration);
        const dropinComponent = checkout.create('dropin').mount("#dropin-container");
        //const checkout = await AdyenCheckout(configuration);
        //const dropinComponent = checkout.create('dropin').mount(this.dropinRef);
        //const dropinComponent = checkout.create('dropin').mount(this.dropinRef);
    }

    bg_style() {
        var css = {};
        if((this.state.info.length > 0) || (this.state.error.length > 0)) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#fee260";
        return css;
    }
    bg1_style() {
        var css = {};
        css.height = window.innerHeight * 0.75;
        css.background = "#fff";
        return css;
    }
    bg_error_style() {
        var css = {};
        if(this.state.error.length < 1) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#d50a19";
        css.color = "#fff";
        return css;
    }
    bg_info_style() {
        var css = {};
        if(this.state.info.length < 1) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#3e79bb";
        css.color = "#fff";
        return css;
    }

    imgUrl(fil) {
        var url = "";
        url = "https://story.svepos.se/foodle/";
        url = url + fil;
        return url;
    }

    cart_adyen_result(result) {
        var self = this;

        //lert(JSON.stringify(result));
        //alert(JSON.stringify(sbo));

        if(result.resultCode == "Authorised") {
            self.setState({ info: "BETALNING GENOMFÖRS..." });
            //self.cart_adyen_pay(result);
            var orderkey = sbo.cdo.unit.sid + ";" + sbo.cart.orderid;
            var okey = btoa(orderkey);
            var url = "/shop/order?okey=" + okey;
            self.props.navigate(url);
        }
        else {
            var error = "BETALNING MISSLYCKADES: " + result.resultCode;
            var errormsg = "";
            if(result.refusalReason) errormsg = result.refusalReason;
            self.setState({ error: error, errormsg: errormsg });

            var prm = {};
            //prm.sid = sbo.cdo.sid;
            prm.sid = sbo.cdo.unit.sid;
            prm.req = "vcm.adyen.log_result";
            prm.sbo = sbo;
            prm.result = JSON.stringify(result);

            //alert(JSON.stringify(sbo));
            //return;
            srs.sio_req(prm)
            .then(function(ret){
            })
        }
    }

    back() {
        if(true) {
            var url = "/shop/kassa";
            this.props.navigate(url);
            return;
        }
    }
    tst() {
        //this.setState({ error: "FEL FRÅN ADYEN...", errormsg: "Resultat..." });
        //this.setState({ info: "KOPPLAR UPP..." });
    }

  render() {

    return (
    <div className="web-app">

        <div className="wp flex-row px-5 py-3">
            <div className="flex">
            <div className="txt_18 cursor-pointer" onClick={() => this.back() }><FontAwesomeIcon icon="arrow-left" size="1x" /> Tillbaka</div>
            </div>
            <div className="txt_22" onClick={() => this.tst() }>BETALNING</div>
            <div className="flex"></div>
        </div>

        <div className="flex-col flex-center" style={ this.bg_error_style() }>

            <div className="flex-col py-5 px-5">
                <div className="txt_22">{ this.state.error }</div>
                <div className="txt_22">{ this.state.errormsg }</div>
            </div>
            <div className="flex-row">
                <div className="mpay-btn" onClick={() => this.back() }>STÄNG</div>
            </div>

        </div>
        <div className="flex-col flex-center" style={ this.bg_info_style() }>

            <div className="flex-col py-5 px-5">
                <div className="txt_22">{ this.state.info }</div>
            </div>

        </div>

        <div className="flex-col flex-center" style={ this.bg_style() }>

            <div className="dlt-body py-2 px-2">

                     <div className="flex-col py-5 px-5">
                        <div id="dropin-container" ref={this.dropinRef}></div>
                    </div>

                <div className="flex-row">
                    <div className="mpay-btn" onClick={() => this.back() }>AVBRYT</div>
                </div>
           </div>
        </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Foodle_Payment));
