import React from 'react';
import {Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { withRouter } from "../lib/react/withRouter";
import * as utils from "../assets/utils";
import Tiden from "../lib/ui/tiden";

import '../shop/css/shop.css';
import SDOContext from '../sdo.js'
import sbo from '../shop/sbo.js'

import Shop_Cart from "../shop/cart.js";

import Foodle_Home from './home.js';
import Foodle_About from '../content/about.js';
import Foodle_Faq from '../content/faq.js';
import Foodle_Contact from '../content/contact.js';
import Foodle_Rules from '../content/rules.js';
import Foodle_Cookies from '../content/cookies.js';
import Foodle_Foot from '../content/foot.js';

import FoodleLogo from "../img/foodle-logo.svg";
import { faCartShoppingFast } from '@fortawesome/pro-regular-svg-icons'

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.top = 1;
      this.state.info = "";
      this.state.wnds = {};
      this.state.mwnd = 0;
      var str = sessionStorage.getItem("sbo");
      this.state.sdo = JSON.parse(str);
      //alert(JSON.stringify(this.state.sdo));
      if(this.state.sdo) {
          if(this.state.sdo.cdo) sbo.cdo = this.state.sdo.cdo;
          if(this.state.sdo.cart) sbo.cart = this.state.sdo.cart;
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      //window.removeEventListener('scroll', this.wndScroll);
  }
  componentDidMount() {
      //window.addEventListener('scroll', this.wndScroll);
  }
    onScroll(event) {
        var body = document.getElementById("web-main");
        if (body.scrollTop > 80) {
            this.setState({top: 0})
            this.setState({info: 'small'})
        } else {
            if (body.scrollTop < 1) {
            this.setState({top: 1})
            this.setState({info: 'big:'+body.scrollTop})
            }
        }

        if (false) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;
        }
    }
    top_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        //if(this.state.top == 0) css.height = 35;
        return css;
    }
    topr_class() {
        var cls = "";
        cls = "fmv0";
        if(this.state.top == 0) {
            cls = cls + " fmv1";
        }
        if(window.scrollY > 100) {
            //cls = cls + " fmv1";
        }
        return cls;
    }
    topr_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        if(this.state.top == 0) {
            //css.display = "none";
        }
        css.display = "none";
        return css;
    }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
    cart_open() {
      if(false) {
          var url = "/foodle/delivery";
          this.props.history.push(url);
          return;
      }
        var self = this;
        var wnds = {};
        wnds.cartopen = true;
        wnds.cb = self.cart_cb;
        this.setState({ wnds: wnds });
    }
    cart_cb(ctx, rsp) {
        //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            ctx.setState({ wnds: {} });
            var url = "/shop/delivery";
            ctx.props.navigate(url);
            return;
        }
        ctx.setState({ wnds: {} });
    }
    foot_icon_style() {
        var css = {};
        css.color = "#fff";
        return css;
    }
    show_zip() {
        var txt = "";
        //if(true) txt = this.state.info;
        if(false) {
            txt = sbo.cdo.zip;
            txt = txt + " : ";
            if(this.state.sdo && this.state.sdo.cdo) txt = txt + this.state.sdo.cdo.zip;
        }
        return txt;
    }
    mnu_open(url) {
      //alert(JSON.stringify(this.props));
        this.setState({ mwnd: 0 });
      this.props.navigate(url);
    }
    mobile_wnd_style() {
        var css = {};
        css.top = 44;
        css.height = window.innerHeight - 44;
        //css.overflow = "auto";
        if (this.state.top == 1) {
            css.top = 94;
            css.height = window.innerHeight - 94;
        }
        if (this.state.mwnd == 0) css.display = "none";
        return css;
    }
    mobile_mopen() {
        var mwnd = this.state.mwnd;
        if(mwnd == 0) mwnd = 1;
        else mwnd = 0;
        this.setState({ mwnd: mwnd });
    }
  render() {

  return (
      <div className="web-app">

          <div className="web-top foodle-top" style={ this.top_style() }>
              <div className="wp">
                  <div className={ this.topr_class() } style={ this.topr_style() }>
                      <div className="text-center text-white">Våra öppettider är mån-fre 11.30 - 21.00 & lör-sön 12.00 - 21.00 (Ni kan alltid lägga er order i förväg)</div>
                      <div className="flex-row py-3"></div>
                  </div>
                  <div className="flex-row foodle-mmenu">
                      <div className=""><img src={FoodleLogo} className="cursor-pointer" alt="Foodle"  onClick={() => this.fknGo("/") } /></div>
                      <div className="flex text-center text-white txt_16">{ this.show_zip() }</div>
                      <ul className="foodle-horiz-ul px-2">
                          <li className="py-1 text-white cursor-pointer" onClick={() => this.mnu_open("/shop/kitchens")}>Våra kök</li>
                          <li className="py-1 text-white cursor-pointer" onClick={() => this.mnu_open("/about")}>Om Foodle</li>
                          <li className="py-1 text-white cursor-pointer" onClick={() => this.mnu_open("/contact")}>Kontakt</li>
                      </ul>
                      <div className="cart-btn flex-row" onClick={() => this.cart_open() }>
                          <div className="flex"></div>
                          <div className="flex-row text-center">
                              <div className="txt_b16 px-1">{ utils.toPris(sbo.cart.belopp) } kr</div>
                              <FontAwesomeIcon className="cart-btn-icon" icon="briefcase" size="1x" color="black" />
                          </div>
                          <div className="flex"></div>
                          <span className="cart-badge">0</span>
                      </div>
                  </div>
                  <div className="flex-row foodle-mobile">
                      <div className=""><img src={FoodleLogo} className="" alt="Foodle"  onClick={() => this.fknGo("/") } /></div>
                      <div className="flex"></div>
                      <FontAwesomeIcon className="" icon="bars" size="2x" color="white" onClick={() => this.mobile_mopen() } />
                  </div>
              </div>
          </div>

          <div className="foodle-mobile-mwnd" style={ this.mobile_wnd_style() }>
              <div className="foodle-mobile-btn" onClick={() => this.mobile_mopen() }><FontAwesomeIcon icon="xmark" size="lg" /></div>

              <div className="flex-col flex" style={{ height: "100%", fontSize: "24px", padding: "50px 0px", position: "relative" }}>

                  <div className="flex-row flex-center py-3">
                      <div className="text-white cursor-pointer" onClick={() => this.mnu_open("/")}>Hem</div>
                  </div>
                  <div className="flex-row flex-center py-3">
                      <div className="text-white cursor-pointer" onClick={() => this.mnu_open("/shop/kitchens")}>Våra kök</div>
                  </div>
                  <div className="flex-row flex-center py-3">
                      <div className="text-white cursor-pointer" onClick={() => this.mnu_open("/about")}>Om Foodle</div>
                  </div>
                  <div className="flex-row flex-center py-3">
                      <div className="text-white cursor-pointer" onClick={() => this.mnu_open("/contact")}>Kontakt</div>
                  </div>

              </div>

          </div>

          <div className="web-main" id="web-main" onScroll={ (event) => this.onScroll(event) }>

              <div className="web-body">
                  <Routes>
                      <Route path="about" element={ <Foodle_About store={this.store} />} />
                      <Route path="faq" element={ <Foodle_Faq store={this.store} />} />
                      <Route path="contact" element={ <Foodle_Contact store={this.store} />} />
                      <Route path="rules" element={ <Foodle_Rules store={this.store} />} />
                      <Route path="cookies" element={ <Foodle_Cookies store={this.store} />} />
                      <Route path="*" element={ <Foodle_Home store={this.store} />} />
                  </Routes>
              </div>

              <div className="foodle-foot">
                  <div className="py-3 wp">
                  <div className="row">
                      <div className="col-md-3 col-sm-12 foodle_bottom_logo">
                          <img src={FoodleLogo} className="" alt="Foodle" />
                      </div>
                      <div className="col-md-3 col-sm-12">
                      <ul className="foodle-ul">
                          <li className="py-1"><a href="/about" className="text-white">OM FOODLE</a></li>
                          <li className="py-1"><a href="/faq" className="text-white" aria-current="page">FAQ</a></li>
                          <li className="py-1"><a href="/contact" className="text-white">KONTAKT</a></li>
                      </ul>
                      </div>
                      <div className="col-md-3 col-sm-12">
                      <ul className="foodle-ul py-2">
                          <li className="py-1"><a href="/rules" className="text-white">REGLER OCH VILLKOR</a></li>
                          <li className="py-1"><a href="/cookies" className="text-white">COOKIES</a></li>
                      </ul>
                      </div>
                      <div className="col-md-3 col-sm-12">
                      <ul className="foodle-ul py-2">
                          <li className="py-1">
                              <FontAwesomeIcon icon={['fab', 'facebook-f']} size="1x" style={ this.foot_icon_style() } />
                              <a href="/fb" className="text-white px-2">FACEBOOK</a>
                          </li>
                          <li className="py-1">
                              <FontAwesomeIcon icon={['fab', 'instagram']} size="1x" style={ this.foot_icon_style() } />
                              <a href="/insta" className="text-white px-2">INSTAGRAM</a>
                          </li>
                      </ul>
                      </div>
                  </div>
                  </div>
              </div>

          </div>

          { this.state.wnds.cartopen ? <Shop_Cart wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
      </div>
  );
}
}
export default observer(withRouter(Foodle));

/*
background-color: rgb(244, 59, 36);
*/