import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faClock } from '@fortawesome/pro-regular-svg-icons'

import Tiden from "../lib/ui/tiden";
import Fr_Input from "../lib/ui/fr_input";

import * as srs from "../lib/srs";
import sbo from './sbo.js'
import * as utils from "../assets/utils";

import Foodle_Onfleet from "./ext/onfleet";

import {withRouter} from "../lib/react/withRouter";
import FoodleLogo from "../img/foodle-logo.svg";
import AdyenCheckout from "@adyen/adyen-web";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Paid extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.loco = {};
      this.state.zip = "";
      this.state.adress = "";
      this.state.discount = "";
      this.state.wnds = {};

      this.state.fnamn = "";
      this.state.enamn = "";
      this.state.tele = "";
      this.state.email = "";
      this.state.msg = "";
      this.state.info = "";
      this.state.error = "";
      this.state.errormsg = "";

      this.state.mga = [
          {id: 'dan', sid: 'S0000281', text: 'FOODLE DANDERYD', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'sic', sid: 'S0000283', text: 'FOODLE SICKLA', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'hud', sid: 'S0000284', text: 'FOODLE HUDDINGE', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'nyk', sid: 'S0000282', text: 'FOODLE NYKÖPING', img: '', txtimg: '', class: 'mg-sallad', desc: '' }
      ];
      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }
      if(!sbo.cart.pro) {
          sbo.cart.pro = {};
      }

      sbo.cart.levbelopp = 0;
      if(sbo.cart.levtyp == "deliver") {
          sbo.cart.levbelopp = 3900;
      }

      sbo.cart.topay = (sbo.cart.belopp * 1) + (sbo.cart.levbelopp * 1);
      if(sbo.cart.discount) sbo.cart.topay = (sbo.cart.topay * 1) - (sbo.cart.discount * 1);
      sbo.cart.topay = sbo.cart.topay.toString();
      this.state.discount = sbo.cart.rabkod;

      //alert(JSON.stringify(sbo.cdo));
      if(sbo.cdo) {
          this.state.fnamn = sbo.cdo.fnamn;
          this.state.enamn = sbo.cdo.enamn;
          this.state.tele = sbo.cdo.tele;
          this.state.email = sbo.cdo.email;
          this.state.msg = sbo.cdo.msg;
      }
      //if(!sbo.cdo) sbo.cdo = {};
      this.state.order = {};
      this.state.order.order00 = {};
      this.state.order.order01 = {};
      this.state.order.order02 = {};
      this.state.order.order03 = {};

      this.state.sbo = {};
      this.state.sbo.cdo = {};
      this.state.sbo.cart = {};
      this.state.sbo.cart.rows = [];
      this.state.sbo.cart.pro = {};
      /*
      if(sbo.cdo) this.state.sbo.cdo = sbo.cdo;
      if(sbo.cart) this.state.sbo.cart = sbo.cart;
      if(false) {
          sbo.cdo = {};
          sbo.cart = {};
          sbo.cart.belopp = "0";
          sbo.cart.rows = [];
          sbo.cart.pro = {};
          sessionStorage.setItem("sbo", JSON.stringify(sbo));
      }
      */

      //const query = new URLSearchParams(this.props.location.search);
      //sessionId=CSD9CAC34EBAE225DD&
      //redirectResult=
      //alert(JSON.stringify(window.location.search));
      var qs = window.location.search;
      qs = qs.replace("?", "");
      var qa = qs.split("&");
      this.state.qso = {};
      for(var item of qa) {
          var va = item.split("=");
          this.state.qso[va[0]] = va[1];
      }
      //alert(JSON.stringify(this.state.qso));
      this.state.info = "RESULTAT BEARBETAS";
      this.state.tstmode = false;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.adyen_result();
      //this.pay_result();
      this.ecom_result();
  }

    ecom_result() {
        var self = this;

        var sid = this.state.qso.sid;
        var orderid = this.state.qso.orderid;
        var qso = this.state.qso;
        //alert(JSON.stringify(this.state.qso));

        self.setState({ info: "ORDER KONTROLLERAS..." });
        var prm = {};
        prm.sid = sid;
        prm.req = "vcm.ecom.ecom_result";
        prm.orderid = orderid;

        //alert(JSON.stringify(prm));
        //return;
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(ret.ok != "000") {
                self.setState({ error: "Order ej godkänd, Kontrollera och försök igen!" });
                return;
            }

            //alert(JSON.stringify(ret.order));
            var order = ret.order;
            var xbo = ret.sbo;
            //alert(JSON.stringify(xbo));
            if(order.order00.paid == "1") {
                self.setState({ info: "", order: order });
            }
            else self.setState({ error: "Order obetald, Kontrollera och försök igen!", order: order });
            //alert(JSON.stringify(ret));
        })

    }
    ecom_pay_result() {
        var self = this;

        var sid = this.state.qso.sid;
        var orderid = this.state.qso.orderid;
        var qso = this.state.qso;
        //alert(JSON.stringify(this.state.qso));

        self.setState({ info: "ORDER BETALAS..." });
        var prm = {};
        prm.sid = sid;
        prm.req = "vcm.ecom.ecom_adyen_pay";
        prm.orderid = orderid;
        prm.result = "";

        //alert(JSON.stringify(prm));
        //return;
        srs.sio_req(prm)
            .then(function(ret){
                alert(JSON.stringify(ret));
                if(ret.ok != "000") {
                    self.setState({ error: "Order ej godkänd, Kontrollera och försök igen!" });
                    return;
                }
                self.setState({ info: "" });
            })

    }
    pay_result(result) {
        var self = this;

        var sid = this.state.qso.sid;
        var orderid = this.state.qso.orderid;
        var qso = this.state.qso;
        //alert(JSON.stringify(this.state.qso));

        if(qso.pp) {
            //window.close();
            self.get_order(sid, orderid);
            return;
        }

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = sid;
        prm.req = "vcm.adyen.pay_result";
        prm.orderid = orderid;
        prm.qso = this.state.qso;
        prm.sbo = sbo;
        if(this.state.tstmode) prm.req = "vcm.adyen.tst_result";

        //alert(JSON.stringify(prm));
        //return;
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                var result = ret.result;
                if(result.resultCode == "Authorised") {
                    self.setState({ info: "BETALNING GENOMFÖRS..." });
                    self.pay_ecom(ret.result);
                }
                else {
                    var error = "BETALNING MISSLYCKADES: " + result.resultCode;
                    var errormsg = "";
                    if(result.refusalReason) errormsg = result.refusalReason;
                    self.setState({ error: error, errormsg: errormsg });
                }
            }
        })
    }
    pay_ecom(result) {
        var self = this;

        self.setState({ info: "BETALNING GENOMFÖRS..." });
        var prm = {};
        prm.sid = sbo.cdo.sid;
        prm.req = "vcm.ecom.ecom_pay";
        prm.sbo = sbo;
        prm.result = result;

        //alert(JSON.stringify(prm));
        //return;
        srs.sio_req(prm)
            .then(function(ret){
                sbo.cart.notanr = ret.notanr;
                self.setState({ info: "" });
                //alert(JSON.stringify(ret));
            })
    }
    get_order(sid, orderid) {
        var self = this;

        self.setState({ info: "ORDER KONTROLLERAS..." });
        var prm = {};
        prm.sid = sid;
        prm.req = "vcm.ecom.ecom_order";
        prm.orderid = orderid;

        //alert(JSON.stringify(prm));
        //return;
        srs.sio_req(prm)
        .then(function(ret){
            var order = ret.order;
            var xbo = ret.sbo;
            //alert(JSON.stringify(xbo));
            if(xbo.cart.paid == "1") {
                self.setState({ info: "", order: order, sbo: xbo });
            }
            else self.setState({ error: "Order obetald, Kontrollera och försök igen!", order: order, sbo: xbo });
            //alert(JSON.stringify(ret));
        })
    }

    async adyen_result() {
        var self = this;
        //var env = "live";
        //var clientKey = "live_HYAWNYLAAJAKPDS5B4DKK3NZBEMMZTAP";
        var env = "test";
        var clientKey = "test_EQ3D27XBCRFDZITNLI6VMTEPLMDJOGZQ";

        const configuration = {
            environment: env,
            clientKey: clientKey,
            session: {
                id: this.state.qso.sessionId,
                //sessionData: this.state.response.sessionData // The payment session data.
            },
            onPaymentCompleted: (result, component) => {
                alert(JSON.stringify("PC 11"));
                self.cart_adyen_result(result);
                return;
            },
            onError: (error, component) => {
                alert(JSON.stringify("ERR 11"));
                alert(JSON.stringify(error));
                //console.error(error.name, error.message, error.stack, component);
            },
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    billingAddressRequired: false
                }
            },
            locale: "sv-SE"
        };

        alert(JSON.stringify(this.state.qso));
        var redirectResult = this.state.qso.redirectResult;

        const checkout = await AdyenCheckout(configuration);
        checkout.submitDetails({ details: { redirectResult } });
    }

    mg_cls(btn) {
        var cls = "flex col";
        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }
    bg_style() {
        var css = {};
        if((this.state.info.length > 0) || (this.state.error.length > 0)) {
            css.display = "none";
        }
        //css.margin = "0px auto";
        //css.padding = "100px 20px";
        //css.width = "100%";
        css.background = "#080";
        //css.color = "#fff";
        //css.backgroundImage = 'url("https://story.svepos.se/foodle/foodle_hero_startsida.jpeg")';
        //css.backgroundSize = "cover";
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }
    bg_error_style() {
        var css = {};
        if(this.state.error.length < 1) {
            css.display = "none";
        }
        //css.margin = "0px auto";
        //css.padding = "100px 20px";
        //css.width = "300px";
        css.height = window.innerHeight * 0.75;
        css.background = "#d50a19";
        css.color = "#fff";
        return css;
    }
    bg_info_style() {
        var css = {};
        if(this.state.info.length < 1) {
            css.display = "none";
        }
        if(this.state.error.length > 0) {
            css.display = "none";
        }
        //css.margin = "0px auto";
        //css.padding = "100px 20px";
        //css.width = "300px";
        css.height = window.innerHeight * 0.75;
        css.background = "#3e79bb";
        css.color = "#fff";
        //css.backgroundImage = 'url("https://story.svepos.se/foodle/foodle_hero_startsida.jpeg")';
        //css.backgroundSize = "cover";
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }

    cart_adyen_result(result) {
        var self = this;
        alert(JSON.stringify("CAR 22"));
        alert(JSON.stringify(result));

        if(result.resultCode == "Authorised") {
            //self.setState({ info: "BETALNING GENOMFÖRS..." });
            //self.cart_adyen_pay(result);
            var prm = {};
            //prm.sid = sbo.cdo.sid;
            prm.sid = sbo.cdo.unit.sid;
            prm.req = "vcm.adyen.log_result";
            prm.sbo = sbo;
            prm.result = JSON.stringify(result);

            //alert(JSON.stringify(sbo));
            //return;
            srs.sio_req(prm)
                .then(function(ret){
                })
        }
        else {
            var error = "BETALNING MISSLYCKADES: " + result.resultCode;
            var errormsg = "";
            if(result.refusalReason) errormsg = result.refusalReason;
            self.setState({ error: error, errormsg: errormsg });

            var prm = {};
            //prm.sid = sbo.cdo.sid;
            prm.sid = sbo.cdo.unit.sid;
            prm.req = "vcm.adyen.log_result";
            prm.sbo = sbo;
            prm.result = JSON.stringify(result);

            alert(JSON.stringify(prm));
            //return;
            srs.sio_req(prm)
            .then(function(ret){
            })
        }
    }

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
        var url = "";
        url = "https://story.svepos.se/foodle/";
        url = url + fil;
        return url;
    }
    pro_ina(row) {
        var a = [];
        //alert(JSON.stringify(row.pro.ino));
        if(row.pro.ino) a = Object.values(row.pro.ino);
        return a;
    }
    pro_ixa(row) {
        var a = [];
        if(row.pro.ixo) a = Object.values(row.pro.ixo);
        return a;
    }
    pro_txa(row) {
        var a = [];
        if(row.pro.txo) a = Object.values(row.pro.txo);
        return a;
    }
    pro_sxa(row) {
        var a = [];
        if(row.pro.sxo) a = Object.values(row.pro.sxo);
        return a;
    }
    pro_dxa(row) {
        var a = [];
        if(row.pro.dxo) a = Object.values(row.pro.dxo);
        return a;
    }

    cart_mail() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = this.state.sbo.cdo.unit.sid;
        prm.req = "vcm.ecom.confirm_mail";
        //prm.req = "vcm.ecom.confirm_sms";
        prm.sbo = this.state.sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
            }
            else {
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    cart_sms() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = this.state.sbo.cdo.unit.sid;
        prm.req = "vcm.ecom.confirm_sms";
        prm.sbo = this.state.sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok == "000") {
                }
                else {
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    cart_adyen() {

        var self = this;
        var wnds = {};
        wnds.cartadyen = true;
        //wnds.cb = this.cart_cb;
        this.setState({ wnds: wnds });
    }
    cart_onfleet() {

        var self = this;
        var wnds = {};
        wnds.cartonfleet = true;
        //wnds.cb = this.cart_cb;
        this.setState({ wnds: wnds });
    }

    cart_abort(ctx, rsp) {
        var url = "/shop";
        this.props.navigate(url);
    }
    cart_close() {
        //sbo.cdo = {};
        sbo.cart = {};
        sbo.cart.belopp = "0";
        sbo.cart.rows = [];
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
        var url = "/shop";
        this.props.navigate(url);
    }
    back() {
        if(true) {
            sbo.cdo = {};
            sbo.cart = {};
            sbo.cart.belopp = "0";
            sbo.cart.rows = [];
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
            var url = "/shop/";
            this.props.navigate(url);
            return;
        }
    }
    not_min() {
        var brc = false;
        var minbelopp = 13000;
        var belopp = this.state.sbo.cart.belopp * 1;
        if(belopp < minbelopp) {
            brc = true;
        }
        return brc;
    }
    pb_show(typ) {
        var brc = false;
        if(typ == "min") brc = this.not_min();
        if(typ == "zero") brc = (this.state.sbo.cart.topay * 1) == 0 ? true : false;
        if(typ == "pay") {
            brc = !this.not_min();
            if(brc) brc = (this.state.sbo.cart.topay * 1) != 0 ? true : false;
        }
        return brc;
    }
    change_adress() {

    }
  render() {

    return (
    <div className="web-app">

        <div className="wp flex-row px-5 py-3">
            <div className="flex">
            <div className="txt_18 cursor-pointer" onClick={() => this.back() }><FontAwesomeIcon icon="arrow-left" size="1x" /> Tillbaka</div>
            </div>
            <div className="txt_22">ORDER</div>
            <div className="flex"></div>
        </div>

        <div className="flex-col flex-center" style={ this.bg_error_style() }>

            <div className="flex-col py-5 px-5">
                <div className="txt_22">{ utils.toLang(this.state.error) }</div>
                <div className="txt_22">{ utils.toLang(this.state.errormsg) }</div>
            </div>
            <div className="flex-row">
                <div className="mpay-btn" onClick={() => this.back() }>STÄNG</div>
            </div>

        </div>
        <div className="flex-col flex-center" style={ this.bg_info_style() }>

            <div className="flex-col py-5 px-5">
                <div className="txt_22">{ utils.toLang(this.state.info) }</div>
            </div>

        </div>

        <div className="" style={ this.bg_style() }>
            <div className="dlt-body">
                <div className="flex-col py-5 px-5">

                    <div className="kvitto-logo"><img src={FoodleLogo} className="cursor-pointer" alt="Foodle" /></div>
                    <div className="txt_22">TACK FÖR DIN ORDER!</div>
                    <div className="flex-row py-3">
                        <div className="txt_22">ORDERID: { utils.toNum(this.state.order.order00.notanr) }</div>
                    </div>

                    <div className="mpg">
                        <div className="flex-row py-3">
                            <div className="flex"></div>
                        </div>
                        <div className="flex-row">
                            <div className="txt_18">{ utils.toLang(this.state.order.order01.fnamn) } { utils.toLang(this.state.order.order01.enamn) }</div>
                        </div>
                        <div className="flex-row">
                            <div className="txt_18">{ this.state.order.order01.email }</div>
                        </div>
                        <div className="flex-row">
                            <div className="txt_18">{ this.state.order.order01.tele }</div>
                        </div>

                        <div className="flex-row py-3">
                            <div className="flex"></div>
                        </div>

                        <div className="flex-row hide">
                            <div className="txt_b16">Produkter</div>
                        </div>
                        <div className="flex-col px-2 hide">
                            { this.state.sbo.cart.rows.map((crow, ckey) =>
                                <div key={ckey} className="flex-col py-2 cart-item" >
                                    <div className="flex-row" >
                                        <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                        <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.pris) } kr</div>
                                    </div>
                                    { this.pro_ina(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_16">- { utils.toLang(row.p0text) }</div>
                                            <div className="flex"></div>
                                        </div>
                                    )}
                                    { this.pro_ixa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_txa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_sxa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_dxa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    <div className="flex-row" >
                                        <div className="txt_16">Summa</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex-col px-2">
                            { Object.values(this.state.sbo.cart.pro).map((crow, ckey) =>
                                <div key={ckey} className="flex-col py-3 cart-item" >
                                    <div className="flex-row" >
                                        <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                        <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                        <div className="flex"></div>
                                        <div className="txt_14">{ utils.toNum(crow.nrof) } * </div>
                                        <div className="txt_14">{ utils.toPris(crow.price) } kr</div>
                                    </div>
                                    <div className="flex-row" >
                                        <div className="txt_16">Summa</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    <div className="flex-row hide">
                        <div className="txt_18">SUBTOTAL</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( this.state.order.order00.belopp ) } kr</div>
                    </div>
                    { sbo.cart.levbelopp != 0 ?
                        <div className="flex-row">
                            <div className="txt_14">Leveranskostnad</div>
                            <div className="flex"></div>
                            <div className="txt_b14">{ utils.toPris( this.state.order.order00.levbelopp ) } kr</div>
                        </div>
                        : null }


                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    { sbo.cart.discount ?
                        <div className="flex-row">
                            <div className="txt_18">AVGÅR RABATT</div>
                            <div className="flex"></div>
                            <div className="txt_b18">{ utils.toPris( this.state.order.order00.discount ) } kr</div>
                        </div>
                        : null }
                    <div className="flex-row">
                        <div className="txt_18">BETALT</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( this.state.order.order00.topay ) } kr</div>
                    </div>
                </div>
                <div className="flex-col py-5 px-5">

                    <div className="flex-row">
                        <div className="txt_b16">Leverans uppgifter</div>
                    </div>
                    { (sbo.cart.levtyp == "deliver") ?
                        <div className="flex-col py-3 box-lev">
                            <div className="txt_14">LEVERANS</div>
                            <div className="flex-row">
                                <div className="txt_14">{ utils.toLang(this.state.order.order02.adress) }</div>
                                <div className="flex"></div>
                                <div className="txt_14">Ändra</div>
                            </div>
                            <div className="flex-row">
                                <div className="txt_14">{ utils.toLang(this.state.order.order02.zip) } { utils.toLang(this.state.order.order02.city) }</div>
                                <div className="flex"></div>
                                <div className="txt_14"></div>
                            </div>
                        </div>
                        : null }
                    { (sbo.cart.levtyp == "pickup") ?
                        <div className="lev-box flex-col">
                            <div className="txt_14">AVHÄMTNING</div>
                            <div className="fbold">{ utils.toLang(this.state.sbo.cdo.unit.city) }</div>
                            <div className="">{ utils.toLang(this.state.sbo.cdo.unit.adress) }</div>
                        </div>
                        : null }


                </div>

                <div className="flex-row px-5">
                    <div className="web-link" onClick={() => this.cart_sms() }>SMS Bekräftelse</div>
                    <div className="flex"></div>
                    <div className="web-link" onClick={() => this.cart_mail() }>Mail Bekräftelse</div>
                </div>
                <div className="flex-row py-3">
                    <div className="flex"></div>
                </div>
                <div className="flex-row flex-center">
                    <div className="mpay-btn w300" onClick={() => this.cart_close() }>KLAR</div>
                </div>
                <div className="flex-row py-3">
                    <div className="flex"></div>
                </div>


            </div>
        </div>
        { this.state.wnds.cartonfleet ? <Foodle_Onfleet wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Foodle_Paid));
