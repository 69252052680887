import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Tiden from "../lib/ui/tiden";
import * as srs from "../lib/srs";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Kds_Home extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.zip = "";
    this.state.adress = "";
    this.state.loco = {};

    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mgo = {};

    //this.state.lat = "57.5079875";
    //this.state.lon = "11.9407502";
    this.state.lat = "57.506280";
    this.state.lon = "12.059960";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }
  bg_full_style() {
    var css = {};
    css.height = window.innerHeight;
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    //css.padding = "100px 20px";
    css.width = "100%";
    css.backgroundImage = 'url("https://story.svepos.se/foodle/foodle_hero_startsida.jpeg")';
    css.backgroundSize = "auto";
    //css.backgroundSize = "cover";
    //css.backgroundAttachment = "fixed";
    css.backgroundPosition = "-550px -100px";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  home_style() {
    var css = {};
    css.margin = "0px";
    //css.padding = "300px 225px";
    css.padding = "100px 25px";
    //css.width = "1024px";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  bg1_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    css.background = "rgb(207,226,248)";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  bg2_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    css.background = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  bg3_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    css.background = "#ff92d4";
    css.color = "#f43b24";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  bg4_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    css.background = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }
  unit_zip() {
    var sid = "";
    var zip = this.state.zip;
    //alert(zip);

    if(zip && (zip.substr(0, 2) == "11") ) {
      sid = "S0000281";
    }
    else if(zip && (zip.substr(0, 3) == "611") ) {
      sid = "S0000282";
    }
    else {
      sid = "S0000281";
      //alert("Ej aktivt postnummer");
      //return;
    }
    var url = "";
    //url = url + "/foodle/menu/" + mgo.id;
    url = url + "/foodle/kitchens";
    this.props.history.push(url);
  };
  setAdress(e) {
    //alert(e.currentTarget.value);
    this.setState({ adress: e.currentTarget.value});
  };
  setZip(e) {
    //alert(e.currentTarget.value);
    this.setState({ zip: e.currentTarget.value});
  };

  mg_cls(btn) {
    var cls = "col";
    //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
    //else cls = cls + " btn_free";
    return cls;
  }
  mg_style(btn) {
    var css = {};
    css.margin = "5px 12px";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }

  imgUrl(fil) {
    var url = "";
    url = "https://story.svepos.se/foodle/";
    url = url + fil;
    return url;
  }

  mg_open(mgo) {
    var url = "";
    //url = url + "/foodle/menu/" + mgo.id;
    url = url + "/foodle/menu/grp";
    this.props.history.push(url);
  };
  acch_style(id) {
    var css = {};
    css.color = "#000";
    css.padding = "15px 5px";
    css.borderTop = "1px solid #222";
    if(id == this.state.selid) css.color = "#080";
    return css;
  }
  acc1_style() {
    var css = {};
    //css.borderBottom = "1px solid #222";
    return css;
  }
  acc2_style() {
    var css = {};
    css.borderTop = "1px solid #222";
    return css;
  }
  acc_style(id) {
    var css = {};
    css.padding = "10px 5px";
    if(id != this.state.selid) css.display = "none";
    return css;
  }
  acc_icon_style() {
    var css = {};
    css.color = "#888";
    return css;
  }
  acc_icon(id) {
    var ico = "";
    ico = "angle-down";
    if(id == this.state.selid) {
      ico = "angle-up";
    }
    return ico;
  }
  acc_set(id) {
    if(this.state.selid == id) id = "";
    this.setState({"selid": id});
    return;
  }
  faq() {
    return;
  }
  mgs_add() {
    var mgs = this.state.mgs + 1;
    if(mgs > 3) mgs = 1;
    this.setState({ mgs: mgs });
    return;
  }
  mgs_sub() {
    var mgs = this.state.mgs - 1;
    if(mgs < 1) mgs = 3;
    this.setState({ mgs: mgs });
    return;
  }

  render() {

    return (
        <div className="web-app">

          <section className="" style={ this.bg4_style() }>
            <div className="text-center font-bold font-colby f80 py-3">
              KDS SKÄRMAR
            </div>
          </section>

        </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Kds_Home);
