import React from 'react';
import {makeAutoObservable} from "mobx"

class Ado {
    constructor() {
        makeAutoObservable(this)
    }

    fid = "12341234";
    fkn = {};
    lang = "sv";
    cfg = {};
    sdo = {};
    gdo = {};

    pos = {};
    pms = {};
    ta = {};
    //@observable text = "Start 00";
    //setfid = (val) => {this.fid = val};
    //setBread = (val) => {this.bread = val};
}

//const store = new Ado()

export default Ado;
//const ADOContext = React.createContext(ado);

//export default ADOContext;