import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { configure } from "mobx"

//import logo from './logo.svg';
import './css/foodle.css';

import pg_bo from './bo/bo.js';

import Web_Home from './web/foodle.js';
import Kds_Home from './kds/kds.js';
import Shop_Home from './shop/shop.js';
import Mobs_Home from './mobs/mobs.js';
import Pitch_Home from './web/pitch.js';
import Web_Bo from './bo/bo.js';

import Store, { StoreProvider } from "./sdo";
import WebStore from "./ado";
import sbo from "./shop/sbo";

const webStore = new WebStore();
const store = new Store();

function FoodleApp() {
    window.dataLayer.push({
        event: 'pageview'
    });

    //var sdo = useContext(SDO);
    //alert(JSON.stringify("HHH"));

    //var url = window.location.href;
    //var foodUrl = url.match(/foodle/g);
    //if(!foodUrl) window.location = "/foodle";

//    fetch('/cfg')
//    .then(rsp => alert(JSON.stringify(rsp)));
//  .then(response => response.json())
//  .then(data => this.setState({ data }));
    configure({
        enforceActions: "never",
    })

    const[txt, setTxt] = useState("SS00");

    var xdo = sessionStorage.getItem("svp");
    //alert(xdo);
    webStore.cfg.brand = 'svk';
    webStore.cfg.logo = 'svk';
    //webStore.cfg.logo = 'cdi';

    if(xdo) {
        var seo = JSON.parse(xdo);
        webStore.seo = seo;
        //return routefkn(sdo);
    }
    //alert(JSON.stringify(sdo));

    webStore.cfg.mnu_show = true;
    webStore.cfg.bread = false;
    webStore.cfg.bread = true;

    var str = sessionStorage.getItem("sbo");
    var xsbo = JSON.parse(str);
    if(xsbo) {
        sbo.cdo = xsbo.cdo;
        sbo.cart = xsbo.cart;
    }

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    webStore.pos = pos;

    var pms = {};
    pms.rts = [];
    pms.pkats = [];
    pms.stat = {};
    webStore.pms = pms;

    var ta = {};
    webStore.ta = ta;

    var html =
    <StoreProvider store={store}>
    <BrowserRouter forceRefresh={false}>
        <Routes>
            <Route exact path="/admin" component={pg_bo} />

            <Route path="/mobs/*" element={ <Mobs_Home store={webStore} />} />

            <Route path="/bo" element={ <Web_Bo store={webStore} />} />
            <Route path="/foodle/*" element={ <Web_Home store={webStore} />} />
            <Route path="/kds/*" element={ <Kds_Home store={webStore} />} />
            <Route path="/shop/*" element={ <Shop_Home store={webStore} />} />
            <Route path="/pitch/*" element={ <Pitch_Home store={webStore} />} />
            <Route path="/presentation/*" element={ <Pitch_Home store={webStore} />} />
            <Route path="/*" element={<Web_Home store={webStore} />} />
        </Routes>
    </BrowserRouter>
    </StoreProvider>
    ;
    return html;
}

export default FoodleApp;
