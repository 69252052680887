import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {withRouter} from "../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Tiden from "../lib/ui/tiden";
import * as srs from "../lib/srs";
import sbo from "../shop/sbo";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Mobs_Home extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.logo = "foodle-logo.png";
    this.state.zip = "";
    this.state.adress = "";
    this.state.loco = {};

    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mgo = {};

    //this.state.lat = "57.5079875";
    //this.state.lon = "11.9407502";
    this.state.lat = "57.506280";
    this.state.lon = "12.059960";

    sbo.cdo.enhet = "DANDERYD";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  bg_full_style() {
    var css = {};
    css.height = window.innerHeight;
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    css.background = "#f43b24";
    css.color = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }
  navGo(url) {
    //alert(JSON.stringify(url));
    this.props.navigate(url);
  };
  imgUrl(fil) {
    var url = "";
    url = "https://story.svepos.se/foodle/";
    url = url + fil;
    return url;
  }

  render() {

    return (
        <div className="web-app">

          <section className="wnd-fill flex-col" style={ this.bg_style() }>
            <div className="f80 py-3">
              <div className="text-center font-bold font-colby py-3">
              MOBIL ORDER
              </div>
              <div className="text-center py-3">
                <img src={this.imgUrl(this.state.logo)} className="foodle-start" alt="Foodle" />
              </div>
              <div className="text-center py-3">
                <div className="txt_18">{ sbo.cdo.enhet }</div>
              </div>
            </div>

            <div className="flex-col flex-center px-3 flex">
              <div className="mobs-btn" onClick={()=> this.navGo("order")}>
                TRYCK FÖR ATT HANDLA
              </div>
            </div>
          </section>

        </div>
  );
}
}

export default observer(withRouter(Mobs_Home));
