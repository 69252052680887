import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faClock } from '@fortawesome/pro-regular-svg-icons'

import sbo from './sbo.js'
import * as utils from "../assets/utils";
import Box_Sel from "../lib/ui/box_sel";
import {format, getHours, getMinutes, subDays} from "date-fns";
import {withRouter} from "../lib/react/withRouter";
import axios from "axios";
import * as srs from "../lib/srs";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Delivery extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.loco = {};
      this.state.zip = "";
      this.state.adress = sbo.cdo.street;
      this.state.levsel = "";
      this.state.dlsel = "";
      this.state.zipinfo = "";

      this.state.today = new Date();
      var hh = getHours(this.state.today);
      if( hh < 5) {
          const dt = subDays(new Date(), 1);
          this.state.dts = format(dt, "yyyyMMdd");
      }
      else this.state.dts = format(this.state.today, "yyyyMMdd");
      this.state.levdt = this.state.dts;
      this.state.levdts = [
          {dts: this.state.dts, text: "ENDAST IDAG"}
      ];

      //if(sbo.cdo.zip && (sbo.cdo.zip.toString().substring(0,3) == "182")) sbo.cdo.zipok = 1;
      //else sbo.cdo.zipok = 0;

      this.state.hemlevtxt = "Hemleverans";
      if(sbo.cdo.zipok == 1) {
          this.state.hemlevtxt = "Hemleverans";
          sbo.cart.levtyp = "deliver";
      }
      else {
          this.state.hemlevtxt = "Ingen hemleverans";
          sbo.cart.levtyp = "pickup";
      }
      if(sbo.cart.levtyp) this.state.levsel = sbo.cart.levtyp;
      if(sbo.cart.slot) {
          var slot = "";
          slot = sbo.cart.slot.substring(-4);
          this.state.dlsel = slot;
      }

      this.state.selitems = [
          {fkn: 'home', text: 'HEM'},
          {fkn: 'setup', text: 'INSTÄLLNINGAR'},
          {fkn: 'upd', text: 'UPPDATERA'},
          {fkn: 'sys', text: 'SYSTEM'},
          {fkn: 'logout', text: 'LOGGA UT'}
      ];

      this.state.dlt = [
          //{dts: this.state.dts, text: this.state.dts}
      ];
      if(!sbo.cdo) sbo.cdo = {};
      if(!sbo.cdo.loco) sbo.cdo.loco = {};

      var loco = {};
      if(sbo.cdo && sbo.cdo.loco) loco = sbo.cdo.loco;
      var adr = "";
      if(loco.street && (loco.street.length > 0)) adr = adr + loco.street;
      if(loco.snr && (loco.snr.length > 0)) adr = adr + " " + loco.snr;
      this.state.adress = adr;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.sz_adress();
      this.ecom_slots();
  }
    ecom_slots() {
        var self = this;

        sbo.cdo.slot = "";
        this.setState({ txt:"Laddar slottar", dlt: [], dlsel: "" });

        var prm = {};
        prm.sid = sbo.cdo.unit.sid;
        prm.req = "pos.pos_ecom_slot.slot_dt";
        prm.dts = this.state.dts;

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                var dlt = ret.rca;
                self.setState({ dlt: dlt });
                //self.syncTb("mnaTable");
                //self.mg_open(mgo);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    sz_adress() {
        var self = this;

        const input = document.getElementById("autocomplete");
        const options = {
            componentRestrictions: { country: "se" },
            //fields: ["address_components", "geometry", "icon", "name"],
            strictBounds: false,
            //radius: 150000,
            types: ['address']
            //types: ['geocode']
            //types: ["establishment"],
        };
        const autocomplete = new window.google.maps.places.Autocomplete(input, options);
        window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
            var place = autocomplete.getPlace();
            sbo.cdo.lat = place.geometry.location.lat();
            sbo.cdo.lon = place.geometry.location.lng();
            //self.get_adress();
            var loco = {};
            loco.street = "";
            loco.snr = "";
            loco.city = "";
            loco.zip = "";
            loco.country = "";
            for(var post of place.address_components) {
                if(post.types.indexOf("street_number") != -1) loco.snr = post.short_name;
                if(post.types.indexOf("route") != -1) loco.street = post.short_name;
                if(post.types.indexOf("postal_code") != -1) loco.zip = post.short_name;
                if(post.types.indexOf("postal_town") != -1) loco.city = post.short_name;
                if(post.types.indexOf("country") != -1) loco.country = post.long_name;
            }
            var adr = "";
            adr = adr + loco.street;
            if(loco.snr && (loco.snr.length > 0)) adr = adr + " " + loco.snr;
            sbo.cdo.loco = loco;
            sessionStorage.setItem("sbo", JSON.stringify(sbo));

            self.setState({ adress: adr }, function() {
                self.get_adress();
            });
        });
    }
    get_adress() {
        var self = this;
        var apikey = "AIzaSyD9Ai4X2zbmpvgl_I8VAaSjkj9KFpe0aI8";
        //var apikey = "AIzaSyDWLiK2vil0dsYeFBaGv43OCOU0T-Hncb8";
        var latlng = sbo.cdo.lat + "," + sbo.cdo.lon;
        var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlng + "&key=" + apikey;
        axios.get(url)
            .then(function (response) {
                //alert(JSON.stringify(rsp));
                var rsp = response.data;
                //alert(JSON.stringify(rsp));
                if(rsp.results) {
                    var ado = rsp.results[0];
                    //var loco = {};
                    var loco = sbo.cdo.loco;
                    //loco.adress = ado.formatted_address;
                    loco.city = "";
                    loco.zip = "";
                    for(var post of ado.address_components) {
                        //if(post.types.indexOf("route") != -1) loco.street = post.short_name;
                        //if(post.types.indexOf("street_number") != -1) loco.streetnr = post.short_name;
                        if(post.types.indexOf("postal_code") != -1) loco.zip = post.short_name;
                        if(post.types.indexOf("postal_town") != -1) loco.city = post.short_name;
                    }
                    var adr = "";
                    adr = adr + loco.street;
                    if(loco.snr && (loco.snr.length > 0)) adr = adr + " " + loco.snr;
                    if(loco.zip && (loco.zip.length > 0)) {
                        adr = adr + ", " + loco.zip;
                        if(loco.city && (loco.city.length > 0)) adr = adr + " " + loco.city;
                    }
                    else if(loco.city && (loco.city.length > 0)) adr = adr + ", " + loco.city;
                    if(loco.country && (loco.country.length > 0)) adr = adr + ", " + loco.country;
                    loco.adress = adr;
                    //alert(JSON.stringify(loco));

                    sbo.cdo.zip = loco.zip;
                    sbo.cdo.street = loco.street;
                    sbo.cdo.adress = loco.adress;
                    sbo.cdo.loco = loco;
                    sessionStorage.setItem("sbo", JSON.stringify(sbo));
                    //self.setState({ loco: loco, adress: loco.adress });
                    self.setState({ loco: loco });
                    self.zip_open();
                }
            });
    }
    zip_open() {
        var self = this;
        var zip = sbo.cdo.zip;

        var nkp = ["61100", "61102", "61103", "61104", "61105", "61107", "61108", "61110", "61111", "61120", "61122", "61123", "61124", "61125", "61126", "61127", "61129"
            ,"61130", "61131", "61132", "61133", "61134", "61135", "61136", "61137", "61138", "61139", "61144", "61145", "61146", "61147", "61150", "61155", "61156", "61157", "61160", "61161", "61162", "61163", "61164", "61165", "61166", "61167", "61168"
            , "61179", "61181", "61182", "61183", "61184", "61185", "61186", "61187", "61188", "61189", "61300" ];
        var dan = ["18200", "18202", "18203", "18204", "18205", "18206", "18207", "18211", "18212", "18213", "18214", "18215", "18216", "18217", "18220", "18230", "18231", "18232", "18233", "18234", "18235", "18236", "18237", "18238", "18239", "18245", "18246", "18247", "18248", "18249"
            , "18250", "18252", "18253", "18254", "18255", "18256", "18257", "18260", "18261", "18262", "18263", "18264", "18265", "18266", "18267", "18268", "18269", "18273", "18274", "18275", "18276", "18277", "18278", "18279", "18282", "18285", "18287", "18288", "18291" ];
        var taby = ["18300", "18301", "18302", "18303", "18304", "18311", "18312", "18313", "18314", "18315", "18316", "18320", "18321", "18322", "18323", "18325", "18326", "18330", "18331", "18332", "18333", "18334", "18335", "18336", "18337", "18338", "18339"
            , "18341", "18347", "18348", "18349", "18350", "18351", "18352", "18353", "18354", "18355", "18356", "18357", "18358", "18359", "18360", "18361", "18362", "18363", "18364", "18365", "18366", "18367", "18368", "18369", "18370"
            , "18371", "18372", "18374", "18375", "18376", "18377", "18378", "18379", "18380", "18383", "18700", "18705", "18720", "18726", "18728", "18729", "18730", "18731", "18732", "18733", "18734", "18735", "18736", "18737", "18738", "18740"
            , "18741", "18742", "18743", "18744", "18745", "18746", "18747", "18748", "18749", "18750", "18751", "18752", "18753", "18754", "18760", "18761", "18762", "18763", "18764", "18765", "18766", "18767", "18768", "18769"];
        var nac = [ "10523", "13100", "13102", "13104", "13105", "13106", "13107", "13108", "13120", "13121", "13122", "13124", "13125", "13126", "13127", "13128", "13130", "13131", "13132", "13133", "13134", "13135", "13136", "13137", "13138", "13139"
            , "13140", "13141", "13142", "13144", "13145", "13146", "13147", "13148", "13149", "13150", "13151", "13152", "13153", "13154", "13155", "13156", "13157", "13160", "13161", "13165", "13171", "13172", "13173", "13174", "13175", "13181", "13183", "13185", "13192"
            , "13300", "13380", "13381", "13383" ];
        var hbs = [ "12030", "12031", "12032", "12061", "12062", "12063", "12064", "12065", "12066", "12067", "12068", "12069", "12070", "12071", "12078", "12079" ];
        var hbh = [ "12002", "12006", "12007", "12008", "12009", "12047", "12048", "12105", "12106", "12107", "12114", "12115", "12116", "12117", "12118", "12120", "12121", "12122", "12123", "12125", "12126", "12127", "12128", "12129"
            , "12130", "12131", "12132", "12133", "12135", "12136", "12137", "12138", "12139", "12140", "12143", "12144", "12145", "12146", "12147", "12148", "12149", "12150", "12151", "12152", "12153", "12154", "12155"
            , "12162", "12163", "12177", "12231", "12232", "12234", "12262" ];
        var hud = [ "14100", "14104", "14118", "14120", "14121", "14122", "14123", "14124", "14125", "14126", "14127", "14128", "14130", "14131", "14132", "14133", "14135", "14143"
            , "14144", "14147", "14148", "14152", "14153", "14154", "14155", "14156", "14157", "14159", "14161", "14162", "14163", "14168", "14169"
            , "14181", "14183", "14184", "14185", "14187", "14188", "14189", "14650" ];
        var hud2 = [ "14134", "14138", "14139", "14140", "14141", "14142", "14145", "14146", "14149", "14150", "14151", "14158", "14160", "14173", "14192" ];
        var tull = [ "14600", "14620", "14621", "14622", "14630", "14631", "14632", "14634", "14635", "14636", "14637", "14638", "14640", "14645", "14646", "14648", "14649", "14652", "14653", "14654", "14680" ];

        if(!sbo.cdo.zip) {
            sbo.cdo.zipok = 0;
            sbo.cdo.unit = {};
            sbo.cdo.unit.id = "BAS";
            sbo.cdo.unit.sid = "S0003202";
            sbo.cdo.unit.adress = "Svärdvägen 23, 182 33 Danderyd";
            sbo.cdo.unit.city = "Danderyd";
            var info = "";
            self.setState({ zipinfo: info });
        }
        if(sbo.cdo.zip) {
            zip = sbo.cdo.zip;
            zip = zip.replaceAll(" ", "");
            //alert(zip);
            if(dan.includes(zip) == true) {
                //alert("Danderyd");
                sbo.cdo.zipok = 1;
                sbo.cdo.unit.id = "DAN";
                sbo.cdo.unit.sid = "S0003202";
                sbo.cdo.unit.adress = "Svärdvägen 23, 182 33 Danderyd";
                sbo.cdo.unit.city = "Danderyd";
                var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka på Våra Kök.";
                self.setState({ zipinfo: info }, function () {
                    self.ecom_slots();
                });
            }
            else if(taby.includes(zip) == true) {
                //alert("Danderyd");
                sbo.cdo.zipok = 1;
                sbo.cdo.unit.id = "DAN";
                sbo.cdo.unit.sid = "S0003202";
                sbo.cdo.unit.adress = "Svärdvägen 23, 182 33 Danderyd";
                sbo.cdo.unit.city = "Danderyd";
                var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka på Våra Kök.";
                self.setState({ zipinfo: info }, function () {
                    self.ecom_slots();
                });
            }
            /*
            else if(nkp.includes(zip) == true) {
                //alert("Nykoping");
                sbo.cdo.zipok = 1;
                sbo.cdo.unit.id = "NKP";
                sbo.cdo.unit.sid = "S0003201";
                sbo.cdo.unit.adress = "Sankt Annegatan 2, 611 34 Nyköping";
                sbo.cdo.unit.city = "Nyköping";
                var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka på Våra Kök.";
                self.setState({ zipinfo: info }, function () {
                    self.ecom_slots();
                });
            }
            else if(nac.includes(zip) == true) {
                //alert("Danderyd");
                sbo.cdo.zipok = 1;
                sbo.cdo.unit.id = "SIC";
                sbo.cdo.unit.sid = "S0003203";
                sbo.cdo.unit.adress = "Fannys väg 5, 131 54 Nacka";
                sbo.cdo.unit.city = "Sickla";
                var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka på Våra Kök.";
                self.setState({ zipinfo: info }, function () {
                    self.ecom_slots();
                });
            }
            else if(hbs.includes(zip) == true) {
                //alert("Danderyd");
                sbo.cdo.zipok = 1;
                sbo.cdo.unit.id = "SIC";
                sbo.cdo.unit.sid = "S0003203";
                sbo.cdo.unit.adress = "Fannys väg 5, 131 54 Nacka";
                sbo.cdo.unit.city = "Sickla";
                var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka på Våra Kök.";
                self.setState({ zipinfo: info }, function () {
                    self.ecom_slots();
                });
            }
            else if(hbh.includes(zip) == true) {
                //alert("Danderyd");
                sbo.cdo.zipok = 1;
                sbo.cdo.unit.id = "SIC";
                sbo.cdo.unit.sid = "S0003203";
                sbo.cdo.unit.adress = "Fannys väg 5, 131 54 Nacka";
                sbo.cdo.unit.city = "Sickla";
                var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka på Våra Kök.";
                self.setState({ zipinfo: info }, function () {
                    self.ecom_slots();
                });
            }
             */
            /*
            else if(hud.includes(zip) == true) {
                //alert("Danderyd");
                sbo.cdo.zipok = 1;
                sbo.cdo.unit.id = "HUD";
                sbo.cdo.unit.sid = "S0003204";
                sbo.cdo.unit.adress = "Novavägen 17, 141 44 Huddinge";
                sbo.cdo.unit.city = "Huddinge";
                var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka på Våra Kök.";
                self.setState({ zipinfo: info }, function () {
                    self.ecom_slots();
                });
            }
            else if(hud2.includes(zip) == true) {
                //alert("Danderyd");
                sbo.cdo.zipok = 1;
                sbo.cdo.unit.id = "HUD";
                sbo.cdo.unit.sid = "S0003204";
                sbo.cdo.unit.adress = "Novavägen 17, 141 44 Huddinge";
                sbo.cdo.unit.city = "Huddinge";
                var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka på Våra Kök.";
                self.setState({ zipinfo: info }, function () {
                    self.ecom_slots();
                });
            }
            else if(tull.includes(zip) == true) {
                //alert("Danderyd");
                sbo.cdo.zipok = 1;
                sbo.cdo.unit.id = "HUD";
                sbo.cdo.unit.sid = "S0003204";
                sbo.cdo.unit.adress = "Novavägen 17, 141 44 Huddinge";
                sbo.cdo.unit.city = "Huddinge";
                var info = "Fantastiskt, vi kan leverera till din adress. Börja handla genom att klicka på Våra Kök.";
                self.setState({ zipinfo: info }, function () {
                    self.ecom_slots();
                });
            }
        */
            else {
                sbo.cdo.zipok = 0;
                sbo.cdo.unit = {};
                sbo.cdo.unit.id = "BAS";
                sbo.cdo.unit.sid = "S0003202";
                sbo.cdo.unit.adress = "Svärdvägen 23, 182 33 Danderyd";
                sbo.cdo.unit.city = "Danderyd";
                //var info = "Sorry!! Vi kan inte Leverans till din adress. Men ni kan alltid handla och hämta maten hos oss.";
                var info = "Tyvärr kan vi inte leverera till din adress. Men ni kan alltid handla och hämta maten hos oss. Välkommen!";
                self.setState({ zipinfo: info }, function () {
                    self.ecom_slots();
                });
            }

        }
    }
    setAdress(e) {
        //alert(e.currentTarget.value);
        this.setState({ adress: e.currentTarget.value});
    };
    zipinfo_style() {
        var css = {};
        //css.display = "none";
        if(this.state.zipinfo.length < 1) css.display = "none";
        if(sbo.cdo.zipok) css.background = "#080";
        else css.background = "#800";
        return css;
    }
    hemlev_text() {
        var text = "Hemleverans";
        if(!sbo.cdo.zipok) text = "Ingen Hemleverans";
        return text;
    }
    hemlev2_text() {
        var text = "";
        if(!sbo.cdo.zipok) text = "till vald adress";
        return text;
    }

    lev_style(typ) {
        var css = {};
        if(typ == this.state.levsel) {
            css.border = "1px solid #000";
            css.background = "#080";
            css.color = "#fff";
        }
        if((typ == "deliver") && (sbo.cdo.zipok != 1)) {
            css.border = "1px solid #000";
            css.background = "#f5f5f5";
            css.color = "#800";
        }
        return css;
    }
    lev_ico_style(typ) {
        var css = {};
        if(typ == this.state.levsel) css.color = "#fff";
        return css;
    }
    dl_style(dlo) {
        var css = {};
        if(dlo.slottid == this.state.dlsel) {
            css.border = "1px solid #000";
            css.background = "#080";
            css.color = "#fff";
        }
        if(dlo.status == "F") {
            css.border = "1px solid #000";
            css.background = "#800";
            css.color = "#fff";
        }
        if(dlo.status == "C") {
            css.border = "1px solid #000";
            css.background = "#800";
            css.color = "#fff";
        }
        return css;
    }
    dl_ico_style(dlo) {
        var css = {};
        if(dlo.ftid == this.state.dlsel) css.color = "#fff";
        return css;
    }
    unit_style() {
        var css = {};
        if(this.state.levsel !== "pickup") {
            css.display = "none";
        }
        return css;
    }
    bg_style() {
        var css = {};
        css.height = window.innerHeight * 0.75;
        css.background = "#fee260";
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

    imgUrl(fil) {
      var url = "";
      url = "https://story.svepos.se/foodle/";
      url = url + fil;
      return url;
    }

    lev_sel(typ) {
        //alert(e.currentTarget.value);
        if(typ == "deliver") {
            if(sbo.cdo.zipok == 1) {
                this.setState({ levsel: typ });
            }
            return;
        }
        this.setState({ levsel: typ });
    };
    lev_dt(e) {
        //alert(JSON.stringify(e.dts));
        this.setState({ levdt: e.dts });
    };
    dl_sel(dlo) {
        //alert(e.currentTarget.value);
        if(dlo.status == "F") return;
        if(dlo.status == "C") return;
        sbo.cdo.slot = this.state.levdt + dlo.slottid;
        this.setState({ dlsel: dlo.slottid });
    };
    kassa_open() {
        var levtyp = this.state.levsel;
        var slot = this.state.dlsel;
        if(levtyp.length < 1) {
            alert("Välj leveranstyp");
            return;
        }
        if(slot.length < 1) {
            alert("Välj slot");
            return;
        }
        if(levtyp == "deliver") {
            if(sbo.cdo.adress.length < 1) {
                alert("Ange adress");
                return;
            }
            if(sbo.cdo.loco.snr.length < 1) {
                alert("Ange nr på gatan");
                return;
            }
            if(!sbo.cdo.zipok) levtyp = "pickup";
        }
        if(sbo.cdo.unit.sid != "S0003202") {
            sbo.cart.belopp = 0;
            if(!sbo.cart.rows) sbo.cart.rows = [];

            //sbo.cart.rows = sbo.cart.rows.splice(key - 1, 1);
            var belopp = 0;
            var rows = [];
            for(var crow of sbo.cart.rows) {
                if(crow.gid != "009") {
                    belopp = belopp + (crow.belopp * 1);
                    rows.push(crow);
                }
                else {
                    alert( crow.text + " finns ej på vald adress och tas bort!" );
                }
            }
            sbo.cart.rows = rows;
            sbo.cart.belopp = belopp.toString();
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
        }
        if(sbo.cart.rows.length < 1) {
            alert("Inget kvar att beställa");
            return;
        }

        sbo.cart.levtyp = levtyp;
        sbo.cart.slot = slot;
        sessionStorage.setItem("sbo", JSON.stringify(sbo));

        if(true) {
            var url = "/shop/kassa";
            this.props.navigate(url);
            return;
        }
    }
    back() {
        if(true) {
            var url = "/shop/kitchens";
            this.props.navigate(url);
            return;
        }
    }

    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "home") {
        }
    }
    slotinfo(dlo) {
        var txt = "LEDIG";
        if(dlo.slottid == this.state.dlsel) {
            txt = "VALD";
        }
        if(dlo.status == "F") {
            txt = "FULLBELAGD";
        }
        if(dlo.status == "C") {
            txt = "STÄNGD";
        }
        return txt;
    }
  render() {

    return (
    <div className="web-app">

        <div className="wp flex-row  px-5 py-3">
            <div className="flex">
            <div className="txt_18 cursor-pointer" onClick={() => this.back() }><FontAwesomeIcon icon="arrow-left" size="1x" /> Tillbaka</div>
            </div>
            <div className="txt_22">LEVERANSUPPGIFTER</div>
            <div className="flex"></div>
        </div>

        <div className="" style={ this.bg_style() }>
            <div className="dlt-body">
                <div className="flex-col py-5">

                    <div className="flex-row">
                        <div className="txt_18">Din Adress</div>
                    </div>
                    <div className="flex-row">
                        <input className="foodle-input flex" id="autocomplete" value={ this.state.adress } onChange={ (e) => this.setAdress(e) } />
                    </div>
                    <div className="flex-row places-infox" >
                        <div className="">{ sbo.cdo.loco.zip }</div>
                        <div className="px-1"></div>
                        <div className="">{ sbo.cdo.loco.city }</div>
                    </div>
                    <div className="flex-row zipinfo_deliver" style={ this.zipinfo_style() }>
                        <div className="txt_14">{ this.state.zipinfo }</div>
                    </div>

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>

                <div className="flex-row">
                    <div className="txt_18">LEVERANSSÄTT</div>
                </div>
                <div className="darkgrey dlt-grid">
                    <div className="foodle-box flex-row" style={ this.lev_style("deliver") } onClick={() => this.lev_sel("deliver") }>
                        <div className="txt_18"><FontAwesomeIcon icon="house" size="1x" /> { this.hemlev_text() }</div>
                        <div className="flex"></div>
                        <div className="txt_18" style={ this.lev_ico_style("deliver") }><FontAwesomeIcon icon="circle-check" size="1x" /></div>
                    </div>
                    <div className="foodle-box flex-row" style={ this.lev_style("pickup") } onClick={() => this.lev_sel("pickup") }>
                        <div className="txt_18"><FontAwesomeIcon icon="gift" size="1x" /> Avhämtning</div>
                        <div className="flex"></div>
                        <div className="txt_18" style={ this.lev_ico_style("pickup") }><FontAwesomeIcon icon="circle-check" size="1x" /></div>
                    </div>

                    <div className="foodle-box flex-row" style={ this.lev_style("datum") } onClick={() => this.lev_dt() }>
                        <div className="txt_18"><FontAwesomeIcon icon="calendar" size="1x" /> { utils.toDate( this.state.levdt ) }</div>
                        <div className="flex"></div>
                        <div className="txt_18" style={ this.lev_ico_style("datum") }><FontAwesomeIcon icon="circle-check" size="1x" /></div>
                    </div>

                </div>

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>

                    <div className="flex-col" style={ this.unit_style() }>
                        <div className="lev-box flex-col">
                            <div className="fbold">{ sbo.cdo.unit.city }</div>
                            <div className="">{ sbo.cdo.unit.adress }</div>
                        </div>
                        <div className="flex-row py-3">
                            <div className="flex"></div>
                        </div>
                    </div>

                    <div className="flex-row">
                    <div className="txt_18">VÄLJ LEVERANSTID</div>
                </div>
                <div className="dlt-grid darkgrey">
                { this.state.dlt.map((dlo, key) =>
                    <div key={key} className="foodle-box flex-row flex" style={ this.dl_style(dlo) } onClick={() => this.dl_sel(dlo) } >
                        <div className="txt_18"><FontAwesomeIcon icon={faClock} size="1x" /> { utils.toTid(dlo.slottid) } - { utils.toTid(dlo.slotslut) }</div>
                        <div className="flex"></div>
                        <div>{ this.slotinfo(dlo) }</div>
                    </div>
                )}
                </div>
                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    <div className="flex-row">
                        <div className="mpay-btn" onClick={() => this.kassa_open() }>GÅ TILL KASSAN</div>
                    </div>

                </div>
            </div>

        </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Foodle_Delivery));
