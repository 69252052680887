import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../../lib/srs";
import * as utils from "../../assets/utils";

import Order_PrdSel from "./prd_sel";
import sbo from "../../shop/sbo";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Order_Prd extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.vaa = [];

    this.state.gnr = "001";
    this.state.ynq = [
      {id: '0', text: 'NEJ'},
      {id: '1', text: 'JA'}
    ];

    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mgo = {};

    this.state.msg = {};
    this.state.wnds = {};

    this.state.pro = {};
    this.state.pro.plu = "";
    this.state.pro.text = "";
    this.state.pro.price = "";
    this.state.pro.desc = "";
    this.state.pro.belopp = "0";

    this.state.pro.ino = {};
    this.state.pro.ixo = {};
    this.state.pro.txo = {};
    this.state.pro.sxo = {};
    this.state.pro.dxo = {};

    this.state.mdo = {};

    this.state.varsel = 0;
    this.state.gid = "";
    this.state.pbsel = "";
    this.state.pbo = {};
    if(props.wnds.pbo) {
      this.state.gid = props.wnds.gid;
      this.state.pbo = props.wnds.pbo;
      this.state.pro.plu = this.state.pbo.plu.trim();
      this.state.pro.text = this.state.pbo.text.trim();
      this.state.pro.price = this.state.pbo.price.trim();
      this.state.pro.desc = this.state.pbo.desc.trim();
      this.state.pro.belopp = this.state.pbo.price.trim();

      this.state.pbsel = this.state.pbo.plu.trim();
      if(this.state.pbo.pbo.plu02.p2storhet == "20") {
        this.state.varsel = 1;
      }
    }

    this.state.ixabox = 0;
    this.state.txabox = 0;
    this.state.sxabox = 0;
    this.state.dxabox = 0;
    this.state.ina = this.state.pbo.pbo.iga;

  }

  componentDidUpdate() {
  }
  componentWillUnmount() {
  }

  componentDidMount() {
    if(this.state.pbo.pbo.plu02.p2storhet == "20") {
      this.plu_variants();
    }
    this.mnu_md();
  }

  dlg_style() {
    var css = {};
    //css.height = window.innerHeight;
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    //css.background = "#f43b24";
    //css.color = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  ico_style() {
    var css = {};
    css.color = "#ddd";
    return css;
  }
  var_style() {
    var css = {};
    //if(this.state.pbo.pbo.plu02.p2storhet != "20") {
    if(this.state.varsel != 1) {
      css.display = "none";
    }
    return css;
  }
  nrof_style(row) {
    var css = {};
    if(!row.m0nrof || (row.m0nrof < 1)) {
      css.display = "none";
    }
    return css;
  }

  varico_style(row) {
    var css = {};
    css.background = "#fff";
    css.color = "#ddd";
    if(this.state.pbsel == row.plu00.p0katnr) {
      css.color = "#fee260";
    }
    return css;
  }
  var_row_style(row) {
    var css = {};
    //css.color = "#ddd";
    if(this.state.pbsel == row.plu00.p0katnr) {
      css.color = "#000";
    }
    return css;
  }
  ina_style(row) {
    var css = {};
    css.background = "#fee260";
    if(row.p0fast == "1") {
      css.background = "#080";
      css.color = "#fff";
      css.display = "none";
    }
    else {
      if(this.state.pro.ino[row.p0lopnr]) {
        css.background = "#ddd";
      }
    }
    return css;
  }
  ina1_style(row) {
    var css = {};
    css.display = "none";
    if(row.p0fast == "1") {
      css.background = "#080";
      css.color = "#fff";
      css.display = "block";
    }
    return css;
  }
  ina3_style(row) {
    var css = {};
    css.display = "none";
    if(row.p0typ == "INFO") {
      css.background = "#008";
      css.color = "#fff";
      css.display = "block";
    }
    return css;
  }
  ixa_toggle() {
    var toggle = this.state.ixabox;
    toggle = !toggle;
    this.setState({ ixabox: toggle });
  }
  txa_toggle() {
    var toggle = this.state.txabox;
    toggle = !toggle;
    this.setState({ txabox: toggle });
  }
  sxa_toggle() {
    var toggle = this.state.sxabox;
    toggle = !toggle;
    this.setState({ sxabox: toggle });
  }
  dxa_toggle() {
    var toggle = this.state.dxabox;
    toggle = !toggle;
    this.setState({ dxabox: toggle });
  }
  ixa_box() {
    var css = {};
    css.height = "50px";
    css.overflow = "hidden";
    if(!this.state.mdo || !this.state.mdo["E"] || (this.state.mdo["E"].length < 1)) {
      css.height = "0px";
    }
    if(this.state.ixabox) {
      css.height = "";
      css.overflow = "none";
    }
    return css;
  }
  txa_box() {
    var css = {};
    css.height = "50px";
    css.overflow = "hidden";
    if(!this.state.mdo || !this.state.mdo["T"] || (this.state.mdo["T"].length < 1)) {
      css.height = "0px";
    }
    if(this.state.txabox) {
      css.height = "";
      css.overflow = "none";
    }
    return css;
  }
  sxa_box() {
    var css = {};
    css.height = "50px";
    css.overflow = "hidden";
    if(!this.state.mdo || !this.state.mdo["S"] || (this.state.mdo["S"].length < 1)) {
      css.height = "0px";
    }
    if(this.state.sxabox) {
      css.height = "";
      css.overflow = "none";
    }
    return css;
  }
  dxa_box() {
    var css = {};
    css.height = "50px";
    css.overflow = "hidden";
    if(!this.state.mdo || !this.state.mdo["D"] || (this.state.mdo["D"].length < 1)) {
      css.height = "0px";
    }
    if(this.state.dxabox) {
      css.height = "";
      css.overflow = "none";
    }
    return css;
  }
  ixa_style(row) {
    var css = {};
    css.color = "#ddd";
    css.fontSize = "18px";
    if(this.state.pro.ixo[row.m0lopnr]) {
      css.color = "#fee260";
    }
    return css;
  }
  ixa_row_style(row) {
    var css = {};
    //css.color = "#ddd";
    if(this.state.pro.ixo[row.m0lopnr]) {
      css.color = "#000";
    }
    return css;
  }
  txa_style(row) {
    var css = {};
    css.color = "#ddd";
    css.fontSize = "18px";
    if(this.state.pro.txo[row.m0lopnr]) {
      css.color = "#fee260";
    }
    return css;
  }
  txa_row_style(row) {
    var css = {};
    //css.color = "#ddd";
    if(this.state.pro.txo[row.m0lopnr]) {
      css.color = "#000";
    }
    return css;
  }
  sxa_style(row) {
    var css = {};
    css.color = "#ddd";
    css.fontSize = "18px";
    if(this.state.pro.sxo[row.m0lopnr]) {
      css.color = "#fee260";
    }
    return css;
  }
  sxa_row_style(row) {
    var css = {};
    //css.color = "#ddd";
    if(this.state.pro.sxo[row.m0lopnr]) {
      css.color = "#000";
    }
    return css;
  }
  dxa_style(row) {
    var css = {};
    css.color = "#ddd";
    css.fontSize = "18px";
    if(this.state.pro.dxo[row.m0lopnr]) {
      css.color = "#fee260";
    }
    return css;
  }
  dxa_row_style(row) {
    var css = {};
    //css.color = "#ddd";
    if(this.state.pro.dxo[row.m0lopnr]) {
      css.color = "#000";
    }
    return css;
  }

  var_icon(row) {
    var ico = "";
    ico = "circle";
    if(this.state.pbsel == row.plu00.p0katnr) {
      ico = "circle-check";
    }
    return ico;
  }
  ixa_icon(row) {
    var ico = "";
    ico = "circle";
    if(this.state.pro.ixo[row.m0lopnr]) {
      ico = "circle-check";
    }
    return ico;
  }
  txa_icon(row) {
    var ico = "";
    ico = "circle";
    if(this.state.pro.txo[row.m0lopnr]) {
      ico = "circle-check";
    }
    return ico;
  }
  sxa_icon(row) {
    var ico = "";
    ico = "circle";
    if(this.state.pro.sxo[row.m0lopnr]) {
      ico = "circle-check";
    }
    return ico;
  }
  dxa_icon(row) {
    var ico = "";
    ico = "circle";
    if(this.state.pro.dxo[row.m0lopnr]) {
      ico = "circle-check";
    }
    return ico;
  }
  var_set(row) {
    var plusel = row.plu00.p0katnr
    this.setState({ pbsel: plusel });
  }
  ino_set(row) {
    var pro = this.state.pro;
    if(row.p0fast == "1") {
      return;
    }
    if(pro.ino[row.p0lopnr]) {
      delete( pro.ino[row.p0lopnr] );
    }
    else pro.ino[row.p0lopnr] = row;
    this.setState({ pro: pro });
  }
  ixo_set(row) {
    var pro = this.state.pro;
    var belopp = pro.belopp * 1;
    if(pro.ixo[row.m0lopnr]) {
      delete( pro.ixo[row.m0lopnr] );
      belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
      row.m0nrof = 0;
    }
    else {
      pro.ixo[row.m0lopnr] = row;
      row.m0nrof = 1;
      belopp = belopp + (row.m0pris * 1);
    }
    pro.belopp = belopp.toString();
    this.setState({ pro: pro });
  }
  txo_set(row) {
    var pro = this.state.pro;
    var belopp = pro.belopp * 1;
    //alert(JSON.stringify(row));
    if(pro.txo[row.m0lopnr]) {
      delete( pro.txo[row.m0lopnr] );
      if(this.state.gid == "008") {
        belopp = belopp - ((row.m0pris * 1) * ((row.m0nrof * 1) - 1));
      }
      else belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
      row.m0nrof = 0;
    }
    else {
      var keys = Object.keys(pro.txo);
      pro.txo[row.m0lopnr] = row;
      row.m0nrof = 1;

      if(this.state.gid == "008") {
        if(keys.length > 0) belopp = belopp + (row.m0pris * 1);
      }
      else belopp = belopp + (row.m0pris * 1);

    }
    pro.belopp = belopp.toString();
    this.setState({ pro: pro });
  }
  sxo_set(row) {
    var pro = this.state.pro;
    var belopp = pro.belopp * 1;
    if(pro.sxo[row.m0lopnr]) {
      delete( pro.sxo[row.m0lopnr] );
      belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
      row.m0nrof = 0;
    }
    else {
      pro.sxo[row.m0lopnr] = row;
      row.m0nrof = 1;
      belopp = belopp + (row.m0pris * 1);
    }
    pro.belopp = belopp.toString();
    this.setState({ pro: pro });
  }
  dxo_set(row) {
    var pro = this.state.pro;
    var belopp = pro.belopp * 1;
    if(pro.dxo[row.m0lopnr]) {
      delete( pro.dxo[row.m0lopnr] );
      belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
      row.m0nrof = 0;
    }
    else {
      pro.dxo[row.m0lopnr] = row;
      row.m0nrof = 1;
      belopp = belopp + (row.m0pris * 1);
    }
    pro.belopp = belopp.toString();
    this.setState({ pro: pro });
  }
  txa_price(row) {
    var pris = "0";
    var pro = this.state.pro;

    if(this.state.gid == "008") {
      var keys = Object.keys(pro.txo);
      if(keys.length < 1) {
        pris = "0";
      }
      else pris = row.m0pris;
    }
    else pris = row.m0pris;
    pris = utils.toPris(pris);
    return pris;
  }
  tbo_add(row, typ) {
    var pro = this.state.pro;
    var belopp = pro.belopp * 1;
    if(!row.m0nrof) row.m0nrof = 0;
    row.m0nrof = row.m0nrof + 1;
    //alert(row.m0nrof);
    belopp = belopp + (row.m0pris * 1);
    pro.belopp = belopp.toString();
    this.setState({ pro: pro });
  }
  tbo_sub(row, typ) {
    var pro = this.state.pro;
    var belopp = pro.belopp * 1;

    if(!row.m0nrof) row.m0nrof = 0;
    else row.m0nrof = row.m0nrof - 1;
    //alert(row.m0nrof);

    if(row.m0nrof < 1) {
      if(typ == "E") delete( pro.ixo[row.m0lopnr] );
      if(typ == "T") delete( pro.txo[row.m0lopnr] );
      if(typ == "S") delete( pro.sxo[row.m0lopnr] );
      if(typ == "D") delete( pro.dxo[row.m0lopnr] );
      row.m0nrof = 0;
    }
    if(this.state.gid == "008") {
      var keys = Object.keys(pro.txo);
      if((keys.length < 1) && (row.m0nrof < 1)) {
        if(typ !== "T") belopp = belopp - (row.m0pris * 1);
      }
      else belopp = belopp - (row.m0pris * 1);
    }
    else belopp = belopp - (row.m0pris * 1);
    //belopp = belopp - (row.m0pris * 1);
    pro.belopp = belopp.toString();
    this.setState({ pro: pro });
  }

  wnd_close = () => {
    if(this.props.wnds.cb) {
      var rsp = {};
      rsp.ok = "999";
      this.props.wnds.cb(this.props.ctx, rsp);
    }
    else this.props.ctx.setState({ wnds: {} });
  };

  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }
  imgUrl(fil) {
    var url = "";
    url = "https://story.svepos.se/foodle/";
    url = url + fil;
    return url;
  }

  mnu_md() {
    var self = this;

    var mnuid = this.state.gid;

    var prm = {};
    prm.sid = "S0000091";
    prm.req = "pos.pos_mnudata.md_list";
    prm.mnuid = mnuid;
    //alert(JSON.stringify(prm));

    srs.sio_req(prm)
        .then(function(ret){
          //alert(JSON.stringify(ret.rco));

          self.setState({ mdo: ret.rco });
        })
        .catch(function(e){
          var txt = JSON.stringify(e);
        });
  }
  plu_variants() {
    var self = this;

    var prm = {};
    prm.sid = "S0000091";
    prm.req = "pos.pos_plu.plu_variants";
    prm.avd = "01";
    prm.pbo = this.state.pbo.pbo;

    //net.sio_req(prm)
    srs.sio_req(prm)
        .then(function(ret){
          //alert(JSON.stringify(ret));
          if(ret.ok == "000") {
            self.setState({ vaa: ret.rca }, function() {
              self.plu_variant_init();
            })
          }
        })
        .catch(function(e){
          var txt = JSON.stringify(e);
        });
  }

  plu_variant_init() {
    var self = this;
    var row = this.state.vaa[0];
    var plusel = row.plu00.p0katnr
    //this.setState({ pbsel: plusel });

    //var pbo = row;
    var pbo = this.state.pbo;
    pbo.pbo = row;

    //var pro = {};
    var pro = this.state.pro;
    pro.plu = row.plu00.p0katnr.trim();
    pro.text = row.plu00.p0namn.trim();
    pro.price = row.plu00.p0pris.trim();
    //pro.desc = row.plu00.p0namn.trim();
    pro.belopp = row.plu00.p0pris.trim();
    self.setState({ pbo: pbo, pro: pro, pbsel: plusel });
  }
  plu_variant_set(row) {
    var self = this;
    var plusel = row.plu00.p0katnr
    //this.setState({ pbsel: plusel });

    //var pbo = row;
    var pbo = this.state.pbo;
    pbo.pbo = row;

    //var pro = {};
    var pro = this.state.pro;
    var belopp = pro.belopp;
    //alert(JSON.stringify(belopp));
    belopp = belopp - (pro.price * 1);
    belopp = belopp + (row.plu00.p0pris * 1);
    //alert(JSON.stringify(belopp));
    pro.plu = row.plu00.p0katnr.trim();
    pro.text = row.plu00.p0namn.trim();
    pro.price = row.plu00.p0pris.trim();
    //pro.desc = row.plu00.p0namn.trim();
    pro.belopp = belopp.toString().trim();
    self.setState({ pbo: pbo, pro: pro, pbsel: plusel });
  }
  get_md(typ) {
    var a = [];
    //if(typ == "E") a = this.state.mdo[typ];
    a = this.state.mdo[typ];
    if(!a) a = [];
    return a;
  }
  get_cart(typ) {
    var a = [];
    if(typ == "E") a = Object.values(this.state.pro.ixo);
    if(typ == "T") a = Object.values(this.state.pro.txo);
    if(typ == "S") a = Object.values(this.state.pro.sxo);
    if(typ == "D") a = Object.values(this.state.pro.dxo);
    //a = this.state.mdo[typ];
    if(!a) a = [];
    return a;
  }

  prd_sel(typ) {
    //sbo.cdo.gid = mgo.gid;
    //var url = "";
    //url = url + "/shop/menu/grp";
    //this.props.navigate(url);
    var wnds = {};
    wnds.prdsel = true;
    wnds.gid = this.state.gid;
    wnds.typ = typ;
    wnds.pro = this.state.pro;
    wnds.mdo = this.state.mdo;
    this.setState({ wnds: wnds });
  };

  cart_add() {
    var self = this;

    var pro = this.state.pro;

    //var rows = [];
    //rows.push( { plu: "0302", text: "Test Kebab", antal: "1", belopp: "2500", taxproc: "2000", } );
    //rows.push( { plu: "0322", text: "Test Pasta", antal: "1", belopp: "3200", taxproc: "2000", } );

    if(!sbo.cart.belopp) sbo.cart.belopp = 0;
    if(!sbo.cart.rows) sbo.cart.rows = [];
    sbo.cart.rows.push(pro);
    var belopp = sbo.cart.belopp * 1;
    belopp = belopp + (pro.belopp * 1);
    sbo.cart.belopp = belopp.toString();
    sessionStorage.setItem("sbo", JSON.stringify(sbo));

    if(this.props.wnds.cb) {
      var rsp = {};
      rsp.ok = "000";
      this.props.wnds.cb(this.props.ctx, rsp);
    }
    else this.props.ctx.setState({ wnds: {} });
  }

  render() {

    return (
        <div className="mx_base">
          <div className="m2_backdrop" />
          <div className="md_full flex-col" style={ this.dlg_style() } >
            <div className="mx_content flex">
              <div className="mx-close-btn" onClick={() => this.wnd_close() }><FontAwesomeIcon icon="xmark" size="lg" /></div>

              <div className="flex-col py-3 px-3">
                <div className="flex-row">
                  <div className="txt_22 font-bold">{ utils.toLang(this.state.pro.text) }</div>
                </div>
                <div className="flex-row">
                  <div className="text-left txt_14 darkgrey">{ utils.toLang(this.state.pro.desc) }</div>
                </div>
              </div>
              <div className="divider_orange"></div>

              <div className="mx-body darkgrey">

                <div className="mpg" style={ this.var_style() }>
                  <div className="flex-row">
                    <div className="txt_b16 text-black">Välj variant</div>
                  </div>
                  <div className="flex-col pxrow">
                    { this.state.vaa.map((pbo, key) =>
                        <div key={key} className="flex-row" onClick={() => this.plu_variant_set(pbo) }  style={ this.var_row_style(pbo) }>
                          <FontAwesomeIcon icon={ this.var_icon(pbo) } size="lg" style={ this.varico_style(pbo) } />
                          <div className="txt_16 px-3">{ utils.toLang(pbo.plu00.p0namn) }</div>
                          <div className="flex"></div>
                          <div className="txt_16">{ utils.toPris(pbo.plu00.p0pris) } kr</div>
                        </div>
                    )}
                  </div>
                </div>

                <div className="mpg">
                  <div className="flex-row">
                    <div className="txt_b16 text-black">Valbara ingredienser</div>
                  </div>
                  <div className="flex-row pxrow mpg-grid">
                    { this.state.ina.map((tbo, key) =>
                        <div key={key} className="ina-btn" style={ this.ina_style(tbo) } onClick={() => this.ino_set(tbo) }>{ utils.toLang(tbo.p0text ) }</div>
                    )}
                  </div>
                </div>
                <div className="mpg hide">
                  <div className="flex-row">
                    <div className="txt_b16">Ingrediens information</div>
                  </div>
                  <div className="flex-row px-5">
                    { this.state.ina.map((tbo, key) =>
                        <div key={key} className="mp-btn" style={ this.ina3_style(tbo) } onClick={() => this.ino_set(tbo) }>{ utils.toLang(tbo.p0text ) }</div>
                    )}
                  </div>
                </div>

                { this.get_md("E").length ?
                    <div className="mpg">
                      <div className="flex-row" onClick={() => this.prd_sel("E") }>
                        <div className="txt_b16 text-black">Ingredienser</div>
                        <div className="flex"></div>
                        <div className="txt_b14 text-black">Lägg till</div>
                      </div>
                      <div className="flex-col pxrow text-left">
                        { this.get_cart("E").map((tbo, key) =>
                            <div key={key} className="flex-row" style={ this.ixa_row_style(tbo) }>
                              <FontAwesomeIcon icon={ this.ixa_icon(tbo) } size="lg" style={ this.ixa_style(tbo) } onClick={() => this.ixo_set(tbo) } />
                              <div className="txt_16 px-3" onClick={() => this.prd_sel("E") }>{ utils.toLang(tbo.m0text) }</div>
                              <div className="flex" onClick={() => this.prd_sel("E") }></div>
                              <div className="flex-row" style={ this.nrof_style(tbo) }>
                                <FontAwesomeIcon icon="circle-minus" style={ this.ixa_style(tbo) } onClick={() => this.tbo_sub(tbo, "E") } />
                                <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                                <FontAwesomeIcon icon="circle-plus" style={ this.ixa_style(tbo) } onClick={() => this.tbo_add(tbo, "E") } />
                              </div>
                              <div className="txt_16 text-right" onClick={() => this.prd_sel("E") }>{ utils.toPris(tbo.m0pris) } kr</div>
                            </div>
                        )}
                      </div>
                    </div>
                    :  null }

                { this.get_md("T").length ?
                    <div className="mpg">
                      <div className="flex-row" onClick={() => this.prd_sel("T") }>
                        <div className="txt_b16 text-black">Tillbehör</div>
                        <div className="flex"></div>
                        <div className="txt_b14 text-black">Lägg till</div>
                      </div>
                      { this.state.gid == "008" ?
                      <div className="flex-row">
                        <div className="txt_b14 text-black"><span>(1 gratis)</span></div>
                      </div>
                      : null}
                      <div className="flex-col pxrow text-left">
                        { this.get_cart("T").map((tbo, key) =>
                            <div key={key} className="flex-row" style={ this.txa_row_style(tbo) }>
                              <FontAwesomeIcon icon={ this.txa_icon(tbo) } size="lg" style={ this.txa_style(tbo) } onClick={() => this.txo_set(tbo) } />
                              <div className="txt_16 px-3" onClick={() => this.prd_sel("T") }>{ utils.toLang(tbo.m0text) }</div>
                              <div className="flex" onClick={() => this.prd_sel("T") }></div>
                              <div className="flex-row px-2" style={ this.nrof_style(tbo) }>
                                <FontAwesomeIcon icon="circle-minus"  style={ this.txa_style(tbo) } onClick={() => this.tbo_sub(tbo, "T") } />
                                <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                                <FontAwesomeIcon icon="circle-plus"  style={ this.txa_style(tbo) } onClick={() => this.tbo_add(tbo, "T") } />
                              </div>
                              <div className="txt_16 text-right" onClick={() => this.prd_sel("T") }>{ this.txa_price(tbo) } kr</div>
                            </div>
                        )}
                      </div>
                    </div>
                    :  null }

                { this.get_md("S").length ?
                    <div className="mpg">
                      <div className="flex-row" onClick={() => this.prd_sel("S") }>
                        <div className="txt_b16 text-black">Sideorder</div>
                        <div className="flex"></div>
                        <div className="txt_b14 text-black">Lägg till</div>
                      </div>
                      <div className="flex-col pxrow text-left">
                        { this.get_cart("S").map((tbo, key) =>
                            <div key={key} className="flex-row" style={ this.sxa_row_style(tbo) }>
                              <FontAwesomeIcon icon={ this.sxa_icon(tbo) } size="lg" style={ this.sxa_style(tbo) } onClick={() => this.sxo_set(tbo) } />
                              <div className="txt_16 px-3" onClick={() => this.prd_sel("S") }>{ utils.toLang(tbo.m0text) }</div>
                              <div className="flex" onClick={() => this.prd_sel("S") }></div>
                              <div className="flex-row px-2" style={ this.nrof_style(tbo) }>
                                <FontAwesomeIcon icon="circle-minus" style={ this.sxa_style(tbo) } onClick={() => this.tbo_sub(tbo, "S") } />
                                <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                                <FontAwesomeIcon icon="circle-plus" style={ this.sxa_style(tbo) } onClick={() => this.tbo_add(tbo, "S") } />
                              </div>
                              <div className="txt_16 text-right" onClick={() => this.prd_sel("S") }>{ utils.toPris(tbo.m0pris) } kr</div>
                            </div>
                        )}
                      </div>
                    </div>
                    :  null }

                { this.get_md("D").length ?
                    <div className="mpg">
                      <div className="flex-row" onClick={() => this.prd_sel("D") }>
                        <div className="txt_b16 text-black">Dryck</div>
                        <div className="flex"></div>
                        <div className="txt_b14 text-black">Lägg till</div>
                      </div>
                      <div className="flex-col pxrow text-left">
                        { this.get_cart("D").map((tbo, key) =>
                            <div key={key} className="flex-row" style={ this.dxa_row_style(tbo) }>
                              <FontAwesomeIcon icon={ this.dxa_icon(tbo) } size="lg" style={ this.dxa_style(tbo) } onClick={() => this.dxo_set(tbo) } />
                              <div className="txt_16 px-3" onClick={() => this.prd_sel("D") }>{ utils.toLang(tbo.m0text) }</div>
                              <div className="flex" onClick={() => this.prd_sel("D") }></div>
                              <div className="flex-row px-2" style={ this.nrof_style(tbo) }>
                                <FontAwesomeIcon icon="circle-minus" style={ this.dxa_style(tbo) } onClick={() => this.tbo_sub(tbo, "D") } />
                                <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                                <FontAwesomeIcon icon="circle-plus" style={ this.dxa_style(tbo) } onClick={() => this.tbo_add(tbo, "D") } />
                              </div>
                              <div className="txt_16 text-right" onClick={() => this.prd_sel("D") }>{ utils.toPris(tbo.m0pris) } kr</div>
                            </div>
                        )}
                      </div>
                    </div>
                    :  null }

              </div>
            </div>
            <div className="mx_foot flex-col px-5 py-2">
              <div className="flex-row py-2">
                <div className="txt_b18">Summa</div>
                <div className="flex"></div>
                <div className="txt_b18">{ utils.toPris( this.state.pro.belopp ) } kr</div>
              </div>
              <div className="flex-row">
                <div className="mfull-btn" onClick={() => this.wnd_close() }>AVBRYT</div>
                <div className="mfull-btn" onClick={() => this.cart_add() }>LÄGG TILL</div>
              </div>
            </div>
          </div>
          { this.state.wnds.prdsel ? <Order_PrdSel wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
  </div>
  );
}
}

export default observer(Order_Prd);
