import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../lib/ui/tiden";


function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Cookies extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.pos_init();
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  pg_style() {
    var css = {};
    css.background = "#ff92d4";
    css.color = "#f43b24";
    css.font = "Colby";
    css.padding = "10px auto";
    return css;
  }
  ctx_style() {
    var css = {};
    css.width = "450px";
    return css;
  }

  render() {

    return (
    <div className="web-app">
      <section>
        <div className="page">

          <div className="text-center font-bold font-colby text-tomato f80 py-3">
            Cookies
          </div>
          <div className="body-text py-2">
            <div className="txt-stycke">
              En cookie är en
                      liten textfil som webbplatser du besöker begär att spara på din dator. Den används för att göra
                      ditt surfande smidigt. Cookies innehåller ingen personlig information utan informationen i
                      textfilerna består av bokstäver och siffror. Även om varje cookie får ett unikt samordningsnummer
                      så kan vi inte se någon personlig information om dig såsom ditt namn eller IP-adress baserat på de
                      cookies som vi samlar in. Det rör sig med andra ord om pseudonymiserade uppgifter och vi använder
                      inte informationen på ett sätt som gör att vi kombinerar din personliga information med
                      cookie-informationen.
            </div>
            <div className="txt-head">
              Det finns också olika typer av cookies:
            </div>
            <div className="txt-stycke">
            Sessionscookies som sparas tillfälligt på din dator när du besöker vår webbplats och tas bort så
            fort du stänger ner din webbläsare
            Permanenta cookies
            som finns kvar på din dator till dess att du tar bort dem
            Förstapartscookies
            som placeras på din dator av oss i samband med att du surfar in på vår webbplats
            Tredjepartscookies
            som ett annat företag placerat på din dator (det är tredjepartscookies som gör att du t.ex. ser
            annonser på produkter som du tidigare har klickat eller gjort sökningar på)
          </div>
          <div className="txt-stycke">
            Cookies kan även
            komma att användas för att analysera vilka sökningar du gjort och sidor du har besökt på hooks.se.
            Detta sammantaget visar ett generellt sök- och beteendemönster. Analyserna kan sedan ligga till
            grund för vår marknadsföring vid besök på vår och på andra webbplatser. Sparade cookies används
            exempelvis i syfte att ge dig anpassad och relevant information och en så personlig
            shoppingupplevelse som möjligt. För detta ändamål använder vi en kommersiell 3:e partsaktör för
            att anpassa och göra innehållet i vår kommunikation mer relevant och intressant för dig.
          </div>
          <div className="txt-stycke">
            Cookies kan dessutom användas för att göra ditt besök hos oss bättre. Vill du veta mer om cookies
            rekommenderar vi dig att läsa på Post- och telestyrelsens webbplats, pts.se.
          </div>
          <div className="txt-stycke">
            Du kan alltid ändra dina webbläsarinställningar eller enhetsinställningar för användningen och
            omfattningen av cookies. Klicka dig in på inställningarna för din webbläsare eller enhet för att
            lära dig mer om hur du ändrar dina cookieinställningar. I dina inställningar kan du blockera alla
            cookies men du kan också välja att endast acceptera förstapartscookies eller välja att radera
            cookies när du stänger ner din webbläsare. Kom ihåg ändrade inställningar kan innebära att vår
            webbutik och andra tjänster inte fungerar korrekt.
          </div>
            <br />
          <div className="txt-head">
            Kontaktinformation
          </div>
          <div className="txt-stycke">
            Tveka inte att kontakta oss om du har några frågor om denna integritetspolicy, behandlingen av
            dina personuppgifter eller utöva någon av dina rättigheter. Vår kontaktinformation hittar du
            nedan:
          </div>
            <div className="txt-head">
              Foodle AB
            </div>
            <div className="txt-stycke">
            Klara Södra kyrkogata 1<br />
            111 52 Stockholm<br />
            Ev. Tel:<br />
            E-post: info@foodle.se<br />
            Organisationsnummer: 559292-6348<br />
          </div>

          </div>

        </div>
      </section>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_Cookies);
