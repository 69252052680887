import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import { withRouter } from "../lib/react/withRouter";

import Tiden from "../lib/ui/tiden";
import sbo from './sbo.js'
import * as srs from "../lib/srs";
import {format, getHours, subDays} from "date-fns";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Kitchens extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};

    this.state.today = new Date();
    var hh = getHours(this.state.today);

      this.state.mga = [];
      /*
      this.state.mga = [
          {gid: '001', id: 'burgers', text: 'BURGERS', img: 'tasty-burgers_frilagd.png', txtimg: 'txtburger.jpeg', class: 'mg-burger', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
          {gid: '002', id: 'pizza', text: 'PIZZA', img: 'bella-pizza_frilagd.png', txtimg: 'txtpizza.png', class: 'mg-pizza', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
          {gid: '003', id: 'pasta', text: 'PASTA', img: 'la-pasta_frilagd.png', txtimg: 'txtpasta.png', class: 'mg-pasta', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' },
          {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sassy-sushi_frilagd.png', txtimg: 'txtsushi.png', class: 'mg-sushi', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
          {gid: '005', id: 'taco', text: 'TACO', img: 'taco-loco_frilagd.png', txtimg: 'txttaco.png', class: 'mg-taco', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
          {gid: '006', id: 'kebab', text: 'KEBAB', img: 'pasha-kebab_frilagd.png', txtimg: 'txtkebab.png', class: 'mg-kebab', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' },
          {gid: '007', id: 'wok', text: 'WOK', img: 'wicked-wok_frilagd.png', txtimg: 'txtwok.png', class: 'mg-wok', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
          {gid: '008', id: 'sallad', text: 'SALLAD', img: 'lean-grean_frilagd.png', txtimg: 'txtleangreen.png', class: 'mg-sallad', desc: 'Njut av våra fräscha sallader.' },
          {gid: '009', id: 'husman', text: 'HUSMAN', img: 'hemlagad-husman_frilagd.png', txtimg: 'txthusman.png', class: 'mg-husman', desc: 'Husmanskost' },
          {gid: '019', id: 'sides', text: 'SIDES/DRYCK', img: '', txtimg: 'txtsides.png', class: 'mg-sides', desc: 'Sides och dryck' },
          {gid: '041', id: 'kiosk', text: 'KIOSK', img: '', txtimg: 'txtkiosk.png', class: 'mg-kiosk', desc: 'Kioskvaror' }
      ];
      this.state.mga = [
          {gid: '001', id: 'burgers', text: 'BURGERS', img: 'burgers.png', txtimg: 'txtburger.jpeg', class: 'mg-burger', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
          {gid: '002', id: 'pizza', text: 'PIZZA', img: 'pizza.png', txtimg: 'txtpizza.png', class: 'mg-pizza', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
          {gid: '003', id: 'pasta', text: 'PASTA', img: 'pasta.png', txtimg: 'txtpasta.png', class: 'mg-pasta', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' },
          {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sushi.png', txtimg: 'txtsushi.png', class: 'mg-sushi', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
          {gid: '005', id: 'taco', text: 'TACO', img: 'taco.png', txtimg: 'txttaco.png', class: 'mg-taco', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
          {gid: '006', id: 'kebab', text: 'KEBAB', img: 'kebab.png', txtimg: 'txtkebab.png', class: 'mg-kebab', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' },
          {gid: '007', id: 'wok', text: 'WOK', img: 'wok.png', txtimg: 'txtwok.png', class: 'mg-wok', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
          {gid: '008', id: 'sallad', text: 'SALLAD', img: 'leangreen.png', txtimg: 'txtleangreen.png', class: 'mg-sallad', desc: 'Njut av våra fräscha sallader.' },
          //{gid: '009', id: 'husman', text: 'HUSMAN', img: 'kebab.png', txtimg: 'txthusman.png', class: 'mg-husman', desc: 'Husmanskost' },
          {gid: '019', id: 'sides', text: 'SIDES/DRYCK', img: 'wok.png', txtimg: 'txtsides.png', class: 'mg-sides', desc: 'Sides och dryck' },
          //{gid: '041', id: 'kiosk', text: 'KIOSK', img: 'wok.png', txtimg: 'txtkiosk.png', class: 'mg-kiosk', desc: 'Kioskvaror' }
      ];
      if(( hh > 8 ) && ( hh < 14 )) {
          this.state.mga = [
              {gid: '001', id: 'burgers', text: 'BURGERS', img: 'burgers.png', txtimg: 'txtburger.jpeg', class: 'mg-burger', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
              {gid: '002', id: 'pizza', text: 'PIZZA', img: 'pizza.png', txtimg: 'txtpizza.png', class: 'mg-pizza', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
              {gid: '003', id: 'pasta', text: 'PASTA', img: 'pasta.png', txtimg: 'txtpasta.png', class: 'mg-pasta', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' },
              {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sushi.png', txtimg: 'txtsushi.png', class: 'mg-sushi', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
              {gid: '005', id: 'taco', text: 'TACO', img: 'taco.png', txtimg: 'txttaco.png', class: 'mg-taco', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
              {gid: '006', id: 'kebab', text: 'KEBAB', img: 'kebab.png', txtimg: 'txtkebab.png', class: 'mg-kebab', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' },
              {gid: '007', id: 'wok', text: 'WOK', img: 'wok.png', txtimg: 'txtwok.png', class: 'mg-wok', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
              {gid: '008', id: 'sallad', text: 'SALLAD', img: 'leangreen.png', txtimg: 'txtleangreen.png', class: 'mg-sallad', desc: 'Njut av våra fräscha sallader.' },
              {gid: '009', id: 'husman', text: 'HUSMAN', img: 'kebab.png', txtimg: 'txthusman.png', class: 'mg-husman', desc: 'Husmanskost' },
              {gid: '019', id: 'sides', text: 'SIDES/DRYCK', img: 'wok.png', txtimg: 'txtsides.png', class: 'mg-sides', desc: 'Sides och dryck' },
              //{gid: '041', id: 'kiosk', text: 'KIOSK', img: 'wok.png', txtimg: 'txtkiosk.png', class: 'mg-kiosk', desc: 'Kioskvaror' }
          ];
      }
      if(sbo.cdo && sbo.cdo.unit && (sbo.cdo.unit.id == "NKP")) {
          this.state.mga = [
              {gid: '001', id: 'burgers', text: 'BURGERS', img: 'burgers.png', txtimg: 'txtburger.jpeg', class: 'mg-burger', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
              {gid: '002', id: 'pizza', text: 'PIZZA', img: 'pizza.png', txtimg: 'txtpizza.png', class: 'mg-pizza', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
              {gid: '003', id: 'pasta', text: 'PASTA', img: 'pasta.png', txtimg: 'txtpasta.png', class: 'mg-pasta', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' },
              {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sushi.png', txtimg: 'txtsushi.png', class: 'mg-sushi', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
              {gid: '005', id: 'taco', text: 'TACO', img: 'taco.png', txtimg: 'txttaco.png', class: 'mg-taco', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
              {gid: '006', id: 'kebab', text: 'KEBAB', img: 'kebab.png', txtimg: 'txtkebab.png', class: 'mg-kebab', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' },
              {gid: '007', id: 'wok', text: 'WOK', img: 'wok.png', txtimg: 'txtwok.png', class: 'mg-wok', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
              {gid: '008', id: 'sallad', text: 'SALLAD', img: 'leangreen.png', txtimg: 'txtleangreen.png', class: 'mg-sallad', desc: 'Njut av våra fräscha sallader.' },
              {gid: '019', id: 'sides', text: 'SIDES/DRYCK', img: 'wok.png', txtimg: 'txtsides.png', class: 'mg-sides', desc: 'Sides och dryck' },
          ];
      }
      */
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;

    this.dsi_mg();
  }

    mg_cls(btn) {
        var cls = "flex col";
        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //css.margin = "5px 12px";
        if(window.innerWidth < 500) {
            css.width = window.innerWidth - 10;
        }
        else {
            css.margin = "5px 12px";
        }
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
      if(fil.length < 1) return "";

      var url = "";
        url = "https://story.svepos.se/foodle/";
      url = url + fil;
      return url;
    }

    dsi_mg() {
        var self = this;

        this.setState({ txt:"Laddar grupp", mga: [] });

        if(!sbo.cdo.unit || !sbo.cdo.unit.sid) {
            var url = "/";
            this.props.navigate(url);
            return;
        }

        var prm = {};
        prm.sid = sbo.cdo.unit.sid;
        prm.req = "dsi.dsi_ecom.ecom_menus";
        //prm.mnuid = gid;

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                var mga = ret.rca;
                self.setState({ mga: mga });
                //self.syncTb("mnaTable");
                //self.mg_open(mgo);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    mg_open(mgo) {
        sbo.cdo.gid = mgo.gid;
        var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        url = url + "/shop/menu/grp";
        this.props.navigate(url);
    };

  render() {

    return (
    <div className="web-app">

        <div className="mgv">
            <div className="flex-row py-1">
                <div className="txt_14 flex">
                    Vi har rutiner för att hantera allergibeställningar och minska risken för kontaminering av allergener. Det kan förekomma spår av allergener då vi hanterar många råvaror i våra kök. Vi kan inte garantera att vår mat är helt fri från allergener trots att produkterna inte innehåller något av våra allergener.
                    Kontakta vår restaurangpersonal så hjälper de dig med din allergibeställning. Välkommen!
                </div>
            </div>
            <div className="mgv-grid">
                { this.state.mga.map((mgo, key) =>
                    <div className={ this.mg_cls(mgo) } key={key}
                         onClick={() => this.mg_open(mgo) }
                         style={ this.mg_style(mgo) }
                    >
                        <div className="flex-col">
                            <div className={mgo.class}>
                            { mgo.img ?
                                <img src={this.imgUrl(mgo.img)} className="mg-img" alt="Foodle" />
                                :  <div></div> }
                            </div>
                            <img src={this.imgUrl(mgo.txtimg)} className="foodle-txt px-5" alt="Foodle" />
                        </div>
                        <div className="flex-row px-5 py-3" >
                            <div>{ mgo.desc }</div>
                        </div>
                        <div className="flex-row px-5 py-3" >
                            <div className="flex"></div>
                            <div className="mg-btn" onClick={() => this.mg_open(mgo) }>TILL KÖK</div>
                            <div className="flex"></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(withRouter(Foodle_Kitchens));
